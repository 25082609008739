import React, { useState, useEffect } from "react";
import axios from "axios";
import allContests from "../assets/images/allContests.jpg";
import noCoursesImage from "../assets/images/Search.png"; 
import { baseurl } from "../helper/Baseurl";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa"; 
import { useTranslation } from "../context/TranslationContext"; 
import { FaUser, FaMapMarkerAlt, FaCalendarAlt, FaClipboardList} from 'react-icons/fa';

const MyContests = () => {
  const [loading, setLoading] = useState(true);
  const [contests, setContests] = useState([]);

  const { translations , language} = useTranslation(); 
  const isArabic = language === "ar";


const fetchImageUrl = async (fileName) => {
  try {
    if (!fileName) {
      return allContests;
    }
    const imageUrl = `${baseurl}uploads/file/download/${fileName}`;
    console.log("Fetched image URL:", imageUrl);
    return imageUrl;
  } catch (error) {
    console.error("Error fetching image:", error);
    return allContests;
  }
};

const fetchData = async () => {
  try {
    const response = await axios.get(baseurl + "my-events", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });

    const data = await Promise.all(
      response.data.COMPETITION.map(async (contest) => {
        const imageUrl = await fetchImageUrl(contest.event.image);
        return {
          id: contest.id,
          title: contest.event.title,
          description: contest.event.description,
          image: imageUrl,
          result: contest.results || "لم يتم تحديد النتيجة بعد",
          organizer: contest.event.organizer || "غير معروف",
          address: contest.event.address || "غير محدد",
          eventStartDate: contest.event.eventStartDate || "2025-01-28 ",
          eventEndDate: contest.event.eventEndDate || "2025-01-28 ",
          contactMobile: contest.event.contactMobile || "غير محدد",
          contactWhatsApp: contest.event.contactWhatsApp || "غير محدد",
          subscriptionStatus: contest.subscriptionStatus || "PENDING", 
          participationType:
            participationTypeMapping[contest.event.participationType] ||
            "غير محدد", 
        };
      })
    );

    setContests(data);
    setLoading(false);
  } catch (error) {
    console.error("Error fetching contests:", error);
    setLoading(false);
  }
};

useEffect(() => {
  fetchData();
}, []);

const statusMapping = {
  PENDING: { text: translations.pending, color: "bg-yellow-500" },
  ACCEPTED: { text: translations.accepted, color: "bg-green-500" },
  WITHDRAWN: { text: translations.withdrawn, color: "bg-gray-500" },
  REJECTED: { text: translations.rejected, color: "bg-red-500" },
  CERTIFIED: { text: translations.certified, color: "bg-blue-500" },
  ARCHIVED: { text: translations.archived, color: "bg-gray-400" },
};

 const participationTypeMapping = {
   WRITING: "كتابة",
   SHARING: "مشاركة",
   ATTENDING: "حضور",
 };

 const [currentPage, setCurrentPage] = useState(1);
 const coursesPerPage = 6;
 const indexOfLastCourse = currentPage * coursesPerPage;
 const indexOfFirstCourse = indexOfLastCourse - coursesPerPage;
 const currentCourses = contests.slice(indexOfFirstCourse, indexOfLastCourse);
 const totalPages = Math.ceil(contests.length / coursesPerPage);
   const handlePageChange = (pageNumber) => {
     setCurrentPage(pageNumber);
   };
  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="w-10 h-10 border-4 border-gray-200 border-t-blue-500 rounded-full animate-spin"></div>
      </div>
    );
  }

  if (contests.length === 0) {
    return (
      <div className="flex flex-col items-center justify-center h-screen text-center p-4 mt-[-10%]">
        <img
          src={noCoursesImage}
          alt="No courses available"
          className="w-60 h-60 object-cover"
        />
       <p className="text-lg text-gray-700 mt-0">
          {translations.noContestsMessage}
        </p>
      </div>
    );
  }

return (
  <div className="p-4">
     <div className="flex flex-wrap -mx-4">
  {currentCourses.map((contest) => (
    <div key={contest.id} className="w-full md:w-1/2 lg:w-1/3 px-4 mb-8">
      <div className="bg-white shadow-xl rounded-xl p-4 flex flex-col h-full border border-gray-200 hover:shadow-2xl transition-shadow duration-300 ease-in-out">
        
      <div className="flex items-center justify-center">
      <img
        src={contest.image}
        alt={contest.title}
        className="h-32 w-32 object-cover rounded-full mb-4"
      />
    </div>

    <div className=" items-center">
        <h3 className="text-lg font-semibold text-gray-800 mb-4 text-center">
          {contest.title}
        </h3>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 text-center">
          <p className="text-sm text-gray-600 flex items-center justify-center">
            <FaUser className="ml-3 text-gray-500" />
            {contest.organizer}
          </p>
          <p className="text-sm text-gray-600 flex items-center justify-center">
            <FaMapMarkerAlt className="ml-3 text-gray-500" />
            {contest.address}
          </p>
          <p className="text-sm text-gray-600 flex items-center justify-center">
            <FaCalendarAlt className="ml-3 text-gray-500" />
            {contest.eventStartDate}
          </p>
          <p className="text-sm text-gray-600 flex items-center justify-center">
            <FaCalendarAlt className="ml-3 text-gray-500" />
            {contest.eventEndDate}
          </p>
          <p className="text-sm text-gray-600 flex items-center justify-center">
            <FaClipboardList className="ml-3 text-gray-500" />
            {contest.participationType}
          </p>
        </div>
      </div>

      

<div className="flex gap-4 mt-4">
  <p className="text-xs text-gray-500 w-1/3">
    <strong>{translations.phone} :</strong> {contest.contactMobile}
  </p>
  <p className="text-xs text-gray-500 w-1/3">
    <strong>{translations.whatsapp}:</strong> {contest.contactWhatsApp}
  </p>
  <p
    className={`text-xs font-bold w-1/3 text-white py-1 px-3 rounded-full text-center ${
      statusMapping[contest.subscriptionStatus].color
    }`}
  >
    {statusMapping[contest.subscriptionStatus].text}
  </p>
</div>


      </div>
    </div>
  ))}
</div>

      <div className="mt-4">
        <ul className="flex justify-center space-x-2 items-center">
          <li>
            <button
              className="px-3 py-1 rounded-full text-custom-orange"
              onClick={() => currentPage > 1 && handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              {isArabic ?    <FaArrowRight /> :    <FaArrowLeft />}
            </button>
          </li>
          {Array.from({ length: totalPages }, (_, index) => (
            <li key={index}>
              <button
                className={`px-3 py-1 rounded-full ${
                  currentPage === index + 1
                    ? "bg-custom-orange text-white"
                    : "text-gray-700"
                }`}
                onClick={() => handlePageChange(index + 1)}
              >
                {index + 1}
              </button>
            </li>
          ))}
          <li>
            <button
              className="px-3 py-1 rounded-full text-custom-orange"
              onClick={() =>
                currentPage < totalPages && handlePageChange(currentPage + 1)
              }
              disabled={currentPage === totalPages}
            >
              {isArabic ?      <FaArrowLeft />  :    <FaArrowRight />}
            </button>
          </li>
        </ul>
      </div>
    </div>
);

};

export default MyContests;
