const NavbarLoginlang = {
  ar: {
    settings: "الإعدادات",
    profile: "الملف الشخصي",
    order_history: "قائمة الطلبات",
    borrows_history: "طلبات الاستعارة",
    logout: "تسجيل خروج",
    quranComplex: "مجمع القرآن الكريم",
    Language: "اللغـــة",
    quranCenters: "مراكز التحفيظ",
    store: "المتجر",
    activities: "النشاطات التفاعلية",
    myactivity: " نشاطاتي التفاعلية",
    library: "المكتبة",
    digitalLibrary: "المكتبة الرقمية",
    localLibrary: "المكتبة المحلية",
    recordedLectures: "المحاضرات المسجلة",
    live: "بث مباشر",
    myFavorites: "مفضلتي",
    notifications: "الإشعارات"
  },
  en: {
    settings: "Settings",
    profile: "Profile",
    order_history: "Order History",
    borrows_history: "Borrow History",
    logout: "Logout",
    quranComplex: "Holy Quran Complex",
    Language: "Language",
    quranCenters: "Quran Centers",
    store: "Store",
    activities: "Interactive Activities",
    myactivity: "My Interactive Activities ",
    library: "Library",
    digitalLibrary: "Digital Library",
    localLibrary: "Local Library",
    recordedLectures: "Recorded Lectures",
    live: "Live",
    myFavorites: "My Favorites",
    notifications: "Notifications"
  },
};

export default NavbarLoginlang;
