const centersTranslations = {
    ar: {
    pageTitle: "المراكز الموجودة في المنصة",
    pageSubtitle: "اطلع على المراكز وشارك الآن ..",
    searchPlaceholder: "اكتب العنوان أو اسم مركز التحفيظ للبحث ..",
    loading: "جاري التحميل...",
    noData: "لا توجد بيانات متاحة",
    studentsCount: "عدد الطلاب",
    teachersCount: "عدد المعلمين",
    phoneNumber: "رقم الهاتف",
    workingHours: "ساعات العمل",
    active: "نشط",
    inactive: "غير نشط",
 },
 en:{
    pageTitle: "Centers on the Platform",
    pageSubtitle: "Browse the centers and participate now..",
    searchPlaceholder: "Enter the address or center name to search..",
    loading: "Loading...",
    noData: "No data available",
    studentsCount: "Number of Students",
    teachersCount: "Number of Teachers",
    phoneNumber: "Phone Number",
    workingHours: "Working Hours",
    active: "Active",
    inactive: "Inactive",
 }

  };
  
  export default centersTranslations;
  