import React, { useState } from 'react';

import Allbooks from "../component/Allbooks";
import AllCourses from "../component/AllCourses";
import AllBorrowsbooks from "../component/AllBorrowsbooks";

import { useTranslation } from "../context/TranslationContext"; 

export default function Shop() {
  const [selectedSection, setSelectedSection] = useState("allbooks");

  const showbooks = () => setSelectedSection("allbooks");
  const Borrowsbooks = () => setSelectedSection("allBorrowsbooks");
  const showallCourses = () => setSelectedSection("allCourses");
  const showMyContests = () => setSelectedSection("allContests");
  const showMyTrainingCourses = () => setSelectedSection("TrainingCourses");
  const showSeminars = () => setSelectedSection("allSeminars");
  const showConferences = () => setSelectedSection("allConferences");
    const { translations , language} = useTranslation(); 
    const isArabic = language === "ar";

    const [selectedOption, setSelectedOption] = useState(selectedSection);

    const handleSelectChange = (event) => {
      const value = event.target.value;
      setSelectedOption(value);
      switch (value) {
        case "allbooks":
          showbooks();
          break;
          case "allBorrowsbooks":
            Borrowsbooks();
          break;
        case "allCourses":
          showallCourses();
          break;
        case "allContests":
          showMyContests();
          break;
        case "TrainingCourses":
          showMyTrainingCourses();
          break;
        case "allConferences":
          showConferences();
          break;
        case "allSeminars":
          showSeminars();
          break;
        default:
          break;
      }
    };
  
    
  return (
    <>
      <div className={`flex ${isArabic ? 'flex-col md:flex-row' : 'flex-col-reverse md:flex-row-reverse '} pt-24 w-full`}>
      

        <div
          className="flex-1"
          style={{
            fontFamily: "Tajwal, sans-serif",
            direction: isArabic ? "rtl" : "ltr",
            textAlign: isArabic ? "right" : "left",
          }}
        >
          <h2 className="text-xl font-bold mb-1">{translations.shop}</h2>
          <h4 className="text-l font-bold text-gray-500 mb-4">
            {translations.shopDescription}
          </h4>
    <div className="mt-4">
      <div className="sm:hidden">
        <select
          className="w-full px-4 py-2 rounded-lg border border-gray-300 bg-white text-gray-900 focus:outline-none focus:ring-2 focus:ring-custom-orange"
          value={selectedOption}
          onChange={handleSelectChange}
        >
          <option value="allbooks">{translations.availableBooks}</option>
          <option value="allBorrowsbooks">{translations.availableBorrowsbooks}</option>
          <option value="allCourses">{translations.availableCourses}</option>
    
        </select>
      </div>

      {/* أزرار للأجهزة الكبيرة */}
      <div className="hidden sm:flex flex-wrap justify-center md:justify-start gap-2">
        <button
          className={`flex items-center px-4 py-2 rounded-lg text-l font-bold text-gray-900 bg-blue hover:bg-custom-orange hover:text-white focus:outline-none ${
            selectedSection === "allbooks" ? "bg-custom-orange text-white" : ""
          }`}
          onClick={showbooks}
        >
          {translations.availableBooks}
        </button>
        <button
          className={`flex items-center px-4 py-2 rounded-lg text-l font-bold text-gray-900 bg-blue hover:bg-custom-orange hover:text-white focus:outline-none ${
            selectedSection === "allBorrowsbooks" ? "bg-custom-orange text-white" : ""
          }`}
          onClick={Borrowsbooks}
        >
          {translations.availableBorrowsbooks}
        </button>
        <button
          className={`flex items-center px-4 py-2 rounded-lg text-l font-bold text-gray-900 bg-blue hover:bg-custom-orange hover:text-white focus:outline-none ${
            selectedSection === "allCourses" ? "bg-custom-orange text-white" : ""
          }`}
          onClick={showallCourses}
        >
          {translations.availableCourses}
        </button>
       
      </div>
    </div>

          <hr className="my-2 border-gray-200 mb-10" />

          {selectedSection === "allbooks" && <Allbooks />}
          {selectedSection === "allBorrowsbooks" && <AllBorrowsbooks />}
          {selectedSection === "allCourses" && <AllCourses />}

        </div>

      
      </div>
    </>
  );
}
