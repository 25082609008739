import React, { useState, useEffect } from "react";
import axios from "axios";
import { baseurl } from "../helper/Baseurl";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Modal from 'react-modal'; 
import { useNavigate } from 'react-router-dom';
import noCoursesImage from "../assets/images/Search.png"; 
import { FaSpinner } from 'react-icons/fa'; 
import { useTranslation } from "../context/TranslationContext"; 
import { FaArrowLeft, FaArrowRight } from "react-icons/fa"; 

Modal.setAppElement('#root'); 

function BorrowsHistory() {
  const { translations } = useTranslation(); 
  const [orders, setOrders] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const [loading, setLoading] = useState(true);
   const [language, setLanguage] = useState("ar");
    const isArabic = language === "ar";
    const [currentPage, setCurrentPage] = useState(1);
    const ordersPerPage = 6;
    const indexOfLastOrder = currentPage * ordersPerPage;
    const indexOfFirstOrder = indexOfLastOrder - ordersPerPage;
    const currentOrders = orders.slice(indexOfFirstOrder, indexOfLastOrder);
    const totalPages = Math.ceil(orders.length / ordersPerPage);
    

    const [profileData, setProfileData] = useState({
      firstName: "",
      lastName: "",
      email: "",
      mobileNo: "",
      learnerType: "",
      id: "",
    });

   useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await axios.get(baseurl + "my-profile", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        if (response.status === 200) {
          setProfileData(response.data);
        }
      } catch (error) {
        console.error("Error fetching profile data:", error);
      }
    };
    fetchProfile();
  }, []);


  useEffect(() => {
    if (profileData.id) {
      axios
        .get(`${baseurl}book-borrow-requests/user/${profileData.id}`, {
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          const sortedOrders = response.data.sort((a, b) => {
            return new Date(b.createdDate) - new Date(a.createdDate); 
          });
  
          setOrders(sortedOrders);  
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching borrow requests:", error);
          setLoading(false);
        });
    }
  }, [profileData.id]);
  
  
    const handlePageChange = (pageNumber) => {
      setCurrentPage(pageNumber);
    };
    useEffect(() => {
      const savedLanguage = sessionStorage.getItem('language');
      if (savedLanguage) {
        setLanguage(savedLanguage);
      }
    }, []);

  const navigate = useNavigate();



  const formatDate = (dateString) => {
    const options = { day: '2-digit', month: '2-digit', year: '2-digit' };
    return new Date(dateString).toLocaleDateString('en-GB', options);
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'PENDING':
        return 'bg-yellow-500';
      case 'APPROVED':
        return 'bg-green-500';
      default:
        return 'bg-gray-500';
    }
  };

  const handleCancelOrder = (orderId) => {
    axios
      .delete(`${baseurl}cancel-book-borrow/${orderId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        toast.success(translations.cancelSuccessMessage);
        setTimeout(() => {
          navigate('/BorrowsHistory');
        }, 3000);
      })
      .catch((error) => {
        toast.warning(translations.cancelErrorMessage);
      });
  };

  const openModal = (orderId) => {
    setSelectedOrderId(orderId);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedOrderId(null);
  };

  const confirmCancelOrder = () => {
    if (selectedOrderId) {
      handleCancelOrder(selectedOrderId);
    }
    closeModal();
  };
  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <FaSpinner className="text-4xl animate-spin" />
      </div>
    );
  }
  return (
    <>
      <div className={`flex ${isArabic ? 'flex-col md:flex-row' : 'flex-col-reverse md:flex-row-reverse '} pt-24 w-full`}>

    
      <div
  className="flex-1"
  style={{
    fontFamily: "Tajwal, sans-serif",
    direction: isArabic ? "rtl" : "ltr",
    textAlign: isArabic ? "right" : "left",
  }}
>
  <h1 className="text-xl font-bold mb-4 font-tajwal">{translations.title}</h1>
  {orders.length === 0 ? (
    <div className="flex flex-col items-center justify-center h-screen text-center p-4 mt-[-5%]">
      <img
        src={noCoursesImage}
        alt="No orders available"
        className="w-60 h-60 object-cover"
      />
      <p className="text-lg text-gray-700">{translations.noOrdersMessage}</p>
    </div>
  ) : (
    currentOrders.map((order) => (
      <div key={order.id} className="border rounded-md p-4 mb-4">
        <div className="flex justify-between items-center mb-2">
          <h2 className="text-lg font-bold">{translations.borrowRequestStatus}</h2>
          <span
            className={`text-white px-2 py-1 rounded ${getStatusColor(order.bookBorrowRequestStatus)}`}
          >
            {order.bookBorrowRequestStatus}
          </span>
        </div>
        <div className="flex justify-between items-center mb-2">
          <span className="text-gray-600">
            {translations.bookName} {order.book.title}
          </span>
        </div>
        <div className="flex flex-col space-y-2">
          <div className="flex space-x-4 ">
            <p className="font-semibold">
              {translations.borrowDate} {formatDate(order.requestDate)}
            </p>
            <p className="font-semibold">
              {translations.collectDate} {formatDate(order.collectDate)}
            </p>
            <p className="font-semibold">
              {translations.returnDate} {formatDate(order.returnDate)}
            </p>
          </div>
        </div>
        {order.bookBorrowRequestStatus === "PENDING" && (
          <button
            onClick={() => openModal(order.id)}
            className="bg-custom-orange text-white px-2 py-2 text-sm rounded mt-4"
          >
            {translations.cancelRequest}
          </button>
        )}
      </div>
    ))
  )}

  {/* Pagination Controls */}
  <div className="mt-4">
    <ul className="flex justify-center space-x-2 items-center">
      <li>
        <button
          className="px-3 py-1 rounded-full text-custom-orange"
          onClick={() => currentPage > 1 && handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          {!isArabic ? <FaArrowLeft /> : <FaArrowRight />}
        </button>
      </li>
      {Array.from({ length: totalPages }, (_, index) => (
        <li key={index}>
          <button
            className={`px-3 py-1 rounded-full ${
              currentPage === index + 1 ? "bg-custom-orange text-white" : "text-gray-700"
            }`}
            onClick={() => handlePageChange(index + 1)}
          >
            {index + 1}
          </button>
        </li>
      ))}
      <li>
        <button
          className="px-3 py-1 rounded-full text-custom-orange"
          onClick={() => currentPage < totalPages && handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          {!isArabic ? <FaArrowRight /> : <FaArrowLeft />}
        </button>
      </li>
    </ul>
  </div>
</div>
        
      </div>
    



      <Modal
  isOpen={isModalOpen}
  onRequestClose={closeModal}
  className="modal"
  style={{
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.6)",
      display: "flex", 
      justifyContent: "center",
      alignItems: "center", 
      position: "fixed", 
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 9999, 
    },
    content: {
      backgroundColor: "white", 
      width: "300px", 
      height: "150px", 
      padding: "15px",
      borderRadius: "8px", 
      margin: "auto",
      textAlign: "right",
      direction: "rtl",
      fontFamily: "Tajwal, sans-serif",
      position: "relative", 
      zIndex: 10000, // وضع قيمة أعلى لـ z-index بالنسبة للمحتوى
    },
  }}
>
  <div className="p-4 text-center">
    <h2 className="font-bold mb-2"
    >
    {translations.modalTitle1}
    </h2>
    <div className="flex justify-center space-x-4">
      <button
        onClick={confirmCancelOrder}
        className="bg-red-600 text-white px-4 py-2 rounded ml-2"
      >
            {translations.yes}

      </button>
      <button
        onClick={closeModal}
        className="bg-gray-300 text-black px-4 py-2 rounded"
      >
            {translations.no}

      </button>
    </div>
  </div>
</Modal>


      <ToastContainer />
    </>
  );
}

export default BorrowsHistory;
