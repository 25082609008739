import React from 'react';
import NavbarLogin from './component/NavbarLogin'; 
import Footer from './component/Footer'; 
import AppRoutes from './AppRoutes'; 
import './App.css';
import { TranslationProvider } from './context/TranslationContext';
import { BrowserRouter, useLocation, matchPath } from "react-router-dom";
const App = () => (
  <BrowserRouter>
    <MainApp />
  </BrowserRouter>
);

const MainApp = () => {
  const location = useLocation();

  const excludedPages = ["/Login", "/", "/LoginRegister"];
  const excludedPages1 = ["/Login", "/LiveClass", "/LoginRegister"];
  const isExcluded =
    excludedPages.includes(location.pathname) ||
    !!matchPath("/ReadBooks/:bookId", location.pathname)||
    !!matchPath("/Showcourse/:courseId", location.pathname);
    const isExcluded1 =
    excludedPages1.includes(location.pathname) ;
    
 

  return (
    <div className="App">
    <TranslationProvider>
      {!isExcluded && <header><NavbarLogin /></header>}
      {!isExcluded ? (
        <main className="container mx-auto p-4">
          <AppRoutes />
        </main>
      ) : (
        <AppRoutes />
      )}
    {!isExcluded1 && <footer><Footer /></footer> } 
    </TranslationProvider>
    </div>
  );
};

export default App;
