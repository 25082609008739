import React, { useEffect, useState } from "react";
import { FaRegHeart } from "react-icons/fa";
import { useParams } from "react-router-dom";
import axios from "axios";
import { baseurl } from "../helper/Baseurl";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import user from "../assets/images/user.png";
import { useTranslation } from "../context/TranslationContext"; 
import { FaShoppingCart } from "react-icons/fa";
import Live from '../assets/images/Live.png'


const CoursesDetails = () => {
  const [courseData, setCourseData] = useState(null);
  const [relatedVideos, setRelatedVideos] = useState([]);
  const [quantity, setQuantity] = useState(1);
  const [likedcourses, setLikedcourse] = useState({});
  const { courseId } = useParams();
  const { translations , language} = useTranslation(); 
  const isArabic = language === "ar";

  const handleJoinNow = () => {
    navigate("/ShowLive"); 
  };    
    const handleLikeClick = async (id) => {
      try {
        const response = await axios.post(
          `${baseurl}toggle-favorite`,
          {
            type: "COURSE",
            id: id,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.data.isFavorite !== undefined) {
          setLikedcourse((prev) => ({
            ...prev,
            [id]: response.data.isFavorite,
          }));
        }
      } catch (error) {
        console.error("Error toggling favorite:", error);
      }
    };
  const navigate = useNavigate();
  const showpicbooks = (fileName) => {
    try {
      const imageUrl = `${baseurl}uploads/file/download/${fileName}`;
      return imageUrl;
    } catch (error) {
      console.error("Error fetching image:", error);
      return null;
    }
  };

  useEffect(() => {
    const fetchCourse = async () => {
      try {
        const response = await axios.get(`${baseurl}course/${courseId}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        const courseData = response.data;
        courseData.coverImageUrl = showpicbooks(courseData.coverImageUrl);
        setCourseData(courseData);
      } catch (error) {
        console.error("Error fetching course data:", error);
      }
    };

    const fetchRelatedVideos = async () => {
      try {
        const response = await axios.get(`${baseurl}all-courses?search=%20`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        const relatedVideosData = response.data.map((video) => {
          video.coverImageUrl = showpicbooks(video.coverImageUrl);
          return video;
        });
        setRelatedVideos(relatedVideosData);
      } catch (error) {
        console.error("Error fetching related videos data:", error);
      }
    };

    fetchCourse();
    fetchRelatedVideos();
  }, [courseId]);


    const handleAddToCart = async () => {
      try {
        const response = await axios.post(
          `${baseurl}add-to-cart`,
          {
            type: "COURSE",
            id: courseData.id,
            quantity: quantity,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        if (response.status === 201) {
          window.dispatchEvent(new Event('cartUpdated'));
          toast.success("تم إضافة الدورة إلى سلة التسوق بنجاح");
        } else {
          toast.error("حدث خطأ أثناء إضافة الدورة إلى سلة التسوق");
        }
      } catch (error) {
        console.error("Error adding course to cart:", error);
        toast.error("حدث خطأ أثناء إضافة الدورة إلى سلة التسوق");
      }
    };


   useEffect(() => {
     if (courseData) {
       setLikedcourse((prev) => ({
         ...prev,
         [courseId]: courseData.isFavorite,
       }));
     }
   }, [courseData, courseId]);

  if (!courseData) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="w-10 h-10 border-4 border-gray-200 border-t-blue-500 rounded-full animate-spin"></div>
      </div>
    );
  }

  const originalPrice = courseData.price;
  const studentPrice = courseData.studentsPrice;
  const discountPercentage =
  ((originalPrice - studentPrice) / originalPrice) * 100;

  return (
    <>
         <div className={`flex flex-col pt-16 w-full ${isArabic ? "rtl" : "ltr"}`}>
        <div
          className={`grid grid-cols-1 md:grid-cols-3 gap-3 p-4 mt-14`}
          dir={isArabic ? "rtl" : "ltr"}
        >
          {/* القسم الأيسر */}
          <div className="col-span-1  flex-col  bg-slate-100 rounded-md shadow-xl p-4">
          <div className="flex flex-col items-center w-full py-4">

            <img
              src={courseData.coverImageUrl}
              alt="Course"
              className={`h-72 rounded-lg mb-4 w-full md:w-auto`}
            />

            <div className={`px-4 w-full place-items-start ${isArabic ? "text-right" : "text-left"}`}>
              <h1 className="text-2xl font-bold font-tajwal mb-4 text-center">
                {courseData.title}
              </h1>
              <p className="font-tajwal text-gray-700 text-center">{courseData.description}</p>
            </div>

            <div className="flex justify-between items-center mt-4 w-full">
              <div className="flex items-center space-x-2 rtl:space-x-reverse">
                <div className="bg-white border border-gray-300 text-custom-orange rounded p-2 flex items-center justify-center">
                  <span className="text-yellow-500 text-sm">⭐</span>
                  <span className="ml-1 text-xs sm:text-sm">
                    {parseFloat(courseData.overallRating).toFixed(1)}
                  </span>
                </div>
                <button
                  onClick={handleAddToCart}
                  className="bg-white border border-gray-300 text-custom-orange rounded p-2 flex items-center justify-center"
                >
                  <FaShoppingCart size={19} />
                </button>
                <button
                  onClick={() => handleLikeClick(courseId)}
                  className={`${
                    likedcourses[courseId] ? "text-red-500" : "text-custom-orange"
                  } bg-white border border-gray-300 rounded p-2 flex items-center justify-center`}
                >
                  <FaRegHeart size={19} />
                </button>
              </div>

              <div className="flex flex-col items-center mt-4">
                <span className="text-sm text-red_aa font-bold font-tajwal">
                  {studentPrice} {translations.price}
                </span>
                <span className="text-sm text-gray-500 font-tajwal line-through">
                  {originalPrice} {translations.old_price}
                </span>
                <span className="ml-2 bg-red_aa text-white px-2 py-1 text-xs rounded font-tajwal">
                  {translations.discount} {discountPercentage.toFixed(0)}%
                </span>
              </div>
            </div>
            </div>

          </div>
  
          {/* القسم الأيمن */}
          <div className="col-span-2">
            <h2
              className={`text-xl font-bold mb-4 font-tajwal text-custom-orange ${
                isArabic ? "text-right" : "text-left"
              }`}
            >
              {translations.product_details}
            </h2>
            <table className="w-full text-right mb-4">
              <tbody>
                {[
                  [translations.course_title, courseData.title],
                  [
                    translations.instructor,
                    `${courseData.comments[0]?.learner.firstName} ${courseData.comments[0]?.learner.lastName}`,
                  ],
                  [
                    translations.hours_count,
                    `${(courseData.comments[0]?.course.durationInSeconds / 3600).toFixed(
                      2
                    )} ساعة`,
                  ],
                  [
                    translations.videos_count,
                    `${courseData.videos.length} ${translations.videos}`,
                  ],
                ].map(([label, value], index) => (
                  <tr
                    key={index}
                    className={`border-t ${index === 3 ? "border-b" : ""} bg-white`}
                  >
                    <td className="p-4 font-tajwal font-bold">{label}</td>
                    <td className="p-4 font-tajwal">{value}</td>
                  </tr>
                ))}
              </tbody>
              </table>

              {/* Live Webinar Advertisement */}
              <div className="relative w-full bg-white text-white rounded-lg p-4 flex flex-col md:flex-row items-center gap-4 shadow-lg shadow-blues1">
                {/* Live Badge */}
                <div className="absolute top-[-10px] left-4 bg-red-500 text-white text-xs font-bold px-3 py-1 rounded-full animate-pulse">
                  LIVE
                </div>

                <div className="flex-1">
                  {/* Image */}
                  <img
                    src={Live}
                    alt="Webinar"
                    className="w-24- h-24 mx-auto rounded-lg mb-4"
                  />

                  <h3 className="text-lg font-bold mb-2 font-tajwal text-custom-orange">  
                  {translations.liveSeminar}

                  </h3>
                  <p className="text-sm font-tajwal text-custom-orange">
                  {translations.liveSeminarDescription}
                  </p>
                  <button className="bg-custom-orange font-tajwal text-white rounded-lg px-6 py-2 text-sm font-bold hover:bg-gray-400 mt-5 " onClick={handleJoinNow}>
                  {translations.joinNow}
                </button>
                </div>
              
              </div>


         
          </div>
        </div>

        <div className="p-4 bg-white"  dir={isArabic ? "rtl" : "ltr"}>
              <div className="flex items-center justify-between mb-4">
                <span className="text-gray-700 font-semibold font-tajwal">
                  {translations.Buttons.reviews} ({courseData.comments.length})
                </span>
                <div className="flex items-center">
                  <span className="text-yellow-500">⭐</span>
                  <span className="ml-1 text-gray-700 font-tajwal">
                  {courseData.comments.length > 0
                            ? (
                                courseData.comments.reduce(
                                  (acc, comment) => acc + comment.rating,
                                  0
                                ) / courseData.comments.length
                              ).toFixed(1)
                            : "0"}
                  </span>
                </div>
              </div>
  
              <div className="max-h-70 overflow-y-auto">
              {courseData.comments.length > 0 ? (
                      courseData.comments.map((comment) => (
                    <div
                      key={comment.id}
                      className="flex items-start mb-4 p-4 border-b"
                    >
                      <img
                        src={user}
                        alt="User"
                        className={`w-12 h-12 rounded-full ${
                          isArabic ? "ml-4" : "mr-4"
                        }`}
                      />
                      <div>
                        <div
                          className={`flex items-center mb-2 ${
                            isArabic ? "" : "flex-row-reverse"
                          }`}
                        >
                          <span className="font-tajwal">
                            {comment.learner.firstName} {comment.learner.lastName}
                          </span>
                          {/* <div
                            className={`flex items-center ${
                              isArabic ? "mr-2" : "ml-2"
                            }`}
                          >
                            <span className="text-yellow-500">⭐</span>
                            <span className="ml-1">{comment.rating}</span>
                          </div> */}
                        </div>
                        <p
                          className={`text-gray-700  font-tajwal text-${
                            isArabic ? "right" : "left"
                          }`}
                        >
                          {comment.details}
                        </p>
                      </div>
                    </div>
                  ))
                ) : (
                  <p className="text-gray-700 font-tajwal">{translations.Reviews.noReviews}</p>
                )}
              </div>
            </div>
      </div>
      
      <ToastContainer position="bottom-left" />
    </>
  );
};

export default CoursesDetails;
