import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import { baseurl } from "../helper/Baseurl";
import { MdArrowForwardIos, MdArrowBackIosNew } from "react-icons/md";
import noCoursesImage from "../assets/images/Search.png";

const ContinueWatchingSection = () => {
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(true); 
  const [currentPage, setCurrentPage] = useState(1); 
  const [totalPages, setTotalPages] = useState(1); 
  const navigate = useNavigate();
  const scrollContainerRef = useRef(null);


  const generateImageUrl = (coverImageUrl) => {
    const baseImageUrl = `${baseurl}uploads/file/download/`;
    return coverImageUrl
      ? `${baseImageUrl}${coverImageUrl}`
      : "default-image-path"; 
  };

  const fetchCourses = async () => {
    setLoading(true); 
    try {
      const response = await axios.get(baseurl + "my-courses", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      const validCourses = response.data.filter(
        (course) => course && course.coverImageUrl && course.progressPercentage < 100
      );

      setTotalPages(Math.ceil(validCourses.length / 6)); 
      const startIndex = (currentPage - 1) * 6; 
      const endIndex = startIndex + 6; 

      const coursesForCurrentPage = validCourses.slice(startIndex, endIndex).map((course) => ({
        ...course,
        imageUrl: generateImageUrl(course.coverImageUrl),
      }));

      setCourses(coursesForCurrentPage);
    } catch (error) {
      console.error("Error fetching courses data", error);
    } finally {
      setLoading(false); 
    }
  };

  useEffect(() => {
    fetchCourses();
  }, [currentPage]); 

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    let scrollInterval;

    if (scrollContainer) {
      scrollInterval = setInterval(() => {
        if (scrollContainer.scrollWidth !== scrollContainer.clientWidth) {
          scrollContainer.scrollLeft += 1;
          if (scrollContainer.scrollLeft + scrollContainer.clientWidth >= scrollContainer.scrollWidth) {
            scrollContainer.scrollLeft = 0;
          }
        }
      }, 20); 
    }

    return () => clearInterval(scrollInterval); 
  }, [courses]);

  const openShowcourse = (courseId) => {
    navigate(`/Showcourse/${courseId}`);
  };

  const language = sessionStorage.getItem("language") || "ar";
  const isRTL = language === "ar";

  const direction = language === "en" ? "ltr" : "rtl";

  if (loading) {
    return (
      <div className="flex items-center justify-center w-full h-64">
        <div className="w-12 h-12 border-4 border-t-4 border-gray-300 border-solid rounded-full animate-spin"></div>
      </div>
    );
  }

  return (
    <div className="p-4" style={{ direction: direction }}>
      <div
  ref={scrollContainerRef}
  className="relative overflow-hidden"
>
  {courses.length === 0 ? (
    <div className="flex flex-col items-center justify-center w-full text-center p-4 mt-0">
      <img
        src={noCoursesImage}
        alt={language === "en" ? "No courses available" : "لا يوجد دورات قمت بشراءها"}
        className="w-48 h-48 object-cover"
      />
      <p className="text-lg text-gray-700" 
         style={{
          fontFamily: "Tajwal, sans-serif",
          textAlign: language === "en" ? "left" : "right",
        }}>
        {language === "en" ? "No courses purchased yet. Buy now!" : "لا يوجد دورات قمت بشراءها .. قم بالشراء الآن"}
      </p>
    </div>
  ) : (
    <div className="flex space-x-4 flex-wrap justify-center" style={{ scrollBehavior: "smooth" }}>
  {courses.map((course, index) => (
    <div
      key={index}
      className="bg-white shadow-lg rounded-lg p-3 w-56 flex-shrink-0 text-right transform transition-transform duration-300 hover:translate-x-4 mb-4"
      style={{ height: "100%" }}
      onClick={() => openShowcourse(course.id)}
    >
      <img
        src={course.imageUrl}
        alt={course.title}
        className="h-24 w-96 object-cover rounded"
        style={{ fontFamily: "Tajwal, sans-serif" }}
      />
      <h3
        className="text-md font-bold mt-2"
        style={{ fontFamily: "Tajwal, sans-serif" }}
      >
        {course.title}
      </h3>
      <p
        className="text-gray-600 text-xs"
        style={{
          fontFamily: "Tajwal, sans-serif",
          textAlign: language === "en" ? "left" : "justify",
          lineHeight: "1.5",
          marginBottom: "8px",
          wordWrap: "break-word",
          whiteSpace: "normal",
          overflow: "hidden",            
          display: "-webkit-box",         
          WebkitBoxOrient: "vertical",    
          WebkitLineClamp: 4,             
        }}
      >
        {course.description}
      </p>
      <div className="mt-2 relative">
        <div
          className="absolute right-0 text-xs text-gray-700"
          style={{ fontFamily: "Tajwal, sans-serif" }}
        >
          {course.progressPercentage || 0}%
        </div>
        <div
          className="absolute left-0 text-xs text-gray-700"
          style={{ fontFamily: "Tajwal, sans-serif" }}
        >
          {language === "en" ? "Course Progress" : "تقدم الدورة"}
        </div>
      </div>
      <div className="w-full bg-gray-200 rounded-full h-2 mt-6">
        <div
          className="bg-custom-orange h-2 rounded-full"
          style={{ width: `${course.progressPercentage || 0}%` }}
        ></div>
      </div>
    </div>
  ))}
</div>

  )}

  {/* Pagination controls */}
  <div className="flex justify-between mt-4">
    <button
      className="bg-gray-200 text-custom-orange px-4 py-4 rounded-full"
      onClick={() => setCurrentPage(currentPage - 1)}
      disabled={currentPage === 1}
    >
             {isRTL ?          <MdArrowForwardIos /> :    <MdArrowBackIosNew />}
    </button>
    <span className="text-lg" style={{ fontFamily: "Tajwal, sans-serif" }}>
      {language === "ar" ? `الصفحة ${currentPage} من ${totalPages}` : `Page ${currentPage} of ${totalPages}`}
    </span>
    <button
      className="bg-gray-200 text-custom-orange px-4 py-4 rounded-full"
      onClick={() => setCurrentPage(currentPage + 1)}
      disabled={currentPage === totalPages}
    >
                 {isRTL ?         <MdArrowBackIosNew />    :    <MdArrowForwardIos />}
    </button>
  </div>
</div>

    </div>
  );
};

export default ContinueWatchingSection;
