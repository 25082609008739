import React, { useState } from 'react';

import ShowLivePublic from "./ShowLivePublic";
import ShowLivePrivate from "./ShowLivePrivate";
import { useTranslation } from "../../context/TranslationContext"; 
export default function Live() {
  const [selectedSection, setSelectedSection] = useState("showlivepublic");
  const ShowLivePublic1 = () => setSelectedSection("showlivepublic");
  const ShowLivePrivate1 = () => setSelectedSection("showliveprivate");
  const { translations , language} = useTranslation(); 
  const isArabic = language === "ar";


    const [selectedOption, setSelectedOption] = useState(selectedSection);
    const handleSelectChange = (event) => {
      const value = event.target.value;
      setSelectedOption(value);
      switch (value) {
        case "showlivepublic":
          ShowLivePublic1();
          break;
          case "showliveprivate":
            ShowLivePrivate1();
          break;
        default:
          break;
      }
    };
  
    
  return (
    <>
      <div className={`flex ${isArabic ? 'flex-col md:flex-row' : 'flex-col-reverse md:flex-row-reverse '} pt-24 w-full`}>
        <div
          className="flex-1"
          style={{
            fontFamily: "Tajwal, sans-serif",
            direction: isArabic ? "rtl" : "ltr",
            textAlign: isArabic ? "right" : "left",
          }}
        >
          <h2 className="text-xl font-bold mb-1">{translations.liveLecturesTitle}</h2>
          <h4 className="text-l font-bold text-gray-500 mb-4">
          {translations.liveLecturesSubtitle}
          </h4>

          <div className="mt-4">
            <div className="sm:hidden">
              <select
                className="w-full px-4 py-2 rounded-lg border border-gray-300 bg-white text-gray-900 focus:outline-none focus:ring-2 focus:ring-custom-orange"
                value={selectedOption}
                onChange={handleSelectChange}
              >
                <option value="showlivepublic">{translations.ShowLivePublic}</option>
                <option value="showliveprivate">{translations.ShowLivePrivate}</option>
          
              </select>
            </div>

            {/* أزرار للأجهزة الكبيرة */}
            <div className="hidden sm:flex flex-wrap justify-center md:justify-start gap-2">
              <button
                className={`flex items-center px-4 py-2 rounded-lg text-l font-bold text-gray-900 bg-blue hover:bg-custom-orange hover:text-white focus:outline-none ${
                  selectedSection === "showlivepublic" ? "bg-custom-orange text-white" : ""
                }`}
                onClick={ShowLivePublic1}

              >
                {translations.ShowLivePublic}
              </button>
              <button
                className={`flex items-center px-4 py-2 rounded-lg text-l font-bold text-gray-900 bg-blue hover:bg-custom-orange hover:text-white focus:outline-none ${
                  selectedSection === "showliveprivate" ? "bg-custom-orange text-white" : ""
                }`}
                onClick={ShowLivePrivate1}

              >
                {translations.ShowLivePrivate}
              </button>
        
            </div>
          </div>

          <hr className="my-2 border-gray-200 mb-10" />

          {selectedSection === "showlivepublic" && <ShowLivePublic />}
          {selectedSection === "showliveprivate" && <ShowLivePrivate />}

        </div>

      
      </div>
    </>
  );
}
