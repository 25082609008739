import React, { useState, useEffect } from "react";
import axios from "axios";
import { baseurl } from "../helper/Baseurl";
import { FaSpinner } from 'react-icons/fa'; 
import noCoursesImage from "../assets/images/Search.png"; 
import { FaArrowLeft, FaArrowRight } from "react-icons/fa"; 

function OrderHistory() {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);

  const [language, setLanguage] = useState("ar");
  const isArabic = language === "ar";
 const [currentPage, setCurrentPage] = useState(1);
    const ordersPerPage = 6;
    const indexOfLastOrder = currentPage * ordersPerPage;
    const indexOfFirstOrder = indexOfLastOrder - ordersPerPage;
    const currentOrders = orders.slice(indexOfFirstOrder, indexOfLastOrder);
    const totalPages = Math.ceil(orders.length / ordersPerPage);
    
    const handlePageChange = (pageNumber) => {
      setCurrentPage(pageNumber);
    };
    useEffect(() => {
      const savedLanguage = sessionStorage.getItem('language');
      if (savedLanguage) {
        setLanguage(savedLanguage);
      }
    }, []);

  useEffect(() => {
    const savedLanguage = sessionStorage.getItem('language');
    if (savedLanguage) {
      setLanguage(savedLanguage);
    }
  }, []);
  
  useEffect(() => {
    axios
      .get(baseurl + "my-orders", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        const sortedOrders = response.data[0].sort((a, b) => {
          return new Date(b.createdDate) - new Date(a.createdDate); // ترتيب حسب التاريخ تنازلي
        });
        setOrders(sortedOrders);  // تعيين الطلبات المرتبة
        setLoading(false); 
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  }, []);
  

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <FaSpinner className="text-4xl animate-spin" />
      </div>
    );
  }

  const getStatusClass = (status) => {
    switch (status) {
      case 'PAYED':
        return 'bg-green-500'; 
      case 'CANCELLED':
        return 'bg-blue-500'; 
      case 'FAILED':
        return 'bg-red-500'; 
      case 'PENDING':
        return 'bg-yellow-500';
      default:
        return 'bg-gray-300';
    }
  };

  const getTranslation = (key) => {
    const translations = {
      ar: {
        title: "قائمة طلباتي",
        noOrders: "لا يوجد طلبات في الوقت الحالي ..",
        orderNumber: "طلب رقم",
        totalOrder: "اجمالي الطلب",
        paymentType: "نوع الدفع",
        discount: "الخصم",
        dinar: "دينار",
      },
      en: {
        title: "My Orders",
        noOrders: "No orders available at the moment..",
        orderNumber: "Order No",
        totalOrder: "Total Order",
        paymentType: "Payment Type",
        discount: "Discount",
        dinar: "Dinar",
      }
    };

    return translations[language][key] || key;
  };

  return (
    <>
      <div className={`flex ${isArabic ? 'flex-col md:flex-row' : 'flex-col-reverse md:flex-row-reverse '} pt-24 w-full`}>
      

        <div className=" flex-1"
          style={{
            fontFamily: "Tajwal, sans-serif",
            direction: isArabic ? "rtl" : "ltr",
            textAlign: isArabic ? "right" : "left",
          }}>
          <h1 className="text-3xl font-bold mb-4  font-tajwal">
            {getTranslation('title')}
          </h1>
          {orders.length === 0 ? (
            <div className="flex flex-col items-center justify-center h-screen text-center p-4 mt-[-5%]">
              <img
                src={noCoursesImage}
                alt="No courses available"
                className="w-60 h-60 object-cover"
              />
              <p className="text-lg text-gray-700 mt-4">
                {getTranslation('noOrders')}
              </p>
            </div>
          ) : (
            currentOrders.map((order, index) => (
              <div key={index} className="border rounded-md p-4 mb-4">
                <div className="flex justify-between items-center mb-2">
                  <h2 className="text-lg font-bold">{getTranslation('orderNumber')} {order.orderNo}</h2>
                  <span className="text-gray-600">
                    {new Date(order.createdDate).toLocaleDateString()}
                  </span>
                </div>
                <div className="flex justify-between items-center mb-2">
                  <span
                    className={`text-white px-2 py-1 rounded ${getStatusClass(
                      order.orderStatus
                    )}`}
                  >
                    {order.orderStatus}
                  </span>
                  <span className="text-gray-600">
                  {getTranslation('totalOrder')}: {order.total} {getTranslation('dinar')}
                  </span>
                </div>
                <div className="space-y-2">
                  <p className="font-semibold">
                    {getTranslation('paymentType')}: {order.paymentType}
                  </p>
                  <p className="font-semibold">  {getTranslation('discount')}: {order.discount} {getTranslation('dinar')}
                  </p>
                </div>
              </div>
            ))
          )}
          <div className="mt-4">
              <ul className="flex justify-center space-x-2 items-center">
                <li>
                  <button
                    className="px-3 py-1 rounded-full text-custom-orange"
                    onClick={() => currentPage > 1 && handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                  >
                    {!isArabic ? <FaArrowLeft /> : <FaArrowRight />}
                  </button>
                </li>
                {Array.from({ length: totalPages }, (_, index) => (
                  <li key={index}>
                    <button
                      className={`px-3 py-1 rounded-full ${
                        currentPage === index + 1 ? "bg-custom-orange text-white" : "text-gray-700"
                      }`}
                      onClick={() => handlePageChange(index + 1)}
                    >
                      {index + 1}
                    </button>
                  </li>
                ))}
                <li>
                  <button
                    className="px-3 py-1 rounded-full text-custom-orange"
                    onClick={() => currentPage < totalPages && handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                  >
                    {!isArabic ? <FaArrowRight /> : <FaArrowLeft />}
                  </button>
                </li>
              </ul>
            </div>
        </div>
     
      </div>
    </>
  );
}

export default OrderHistory;
