import React, { useEffect, useState } from "react";

import { useLocation, useNavigate } from "react-router-dom";
import { baseurl } from "../helper/Baseurl";
import axios from "axios";
import { useTranslation } from "../context/TranslationContext"; 
function Paytheorder() {
  const navigate = useNavigate();
  const [userData, setUserData] = useState(null);
  const [orderData, setOrderData] = useState(null); 
  const location = useLocation();
  const { orderId } = location.state || {}; 

  const [isLoading, setIsLoading] = useState(false); 
    const { translations , language} = useTranslation(); 
    const isArabic = language === "ar";
  
  const Paynow = () => {
    if (orderId) {
      navigate('/Paynow', { state: { orderId } }); 
    } else {
      console.error('Order ID is not available.');
    }

  };

  
  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const response = await axios.get(`${baseurl}my-profile`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        setUserData(response.data);
      } catch (error) {
        console.error("Error fetching user profile:", error);
      }
    };
    fetchUserProfile();
  }, []);


  useEffect(() => {
    const fetchOrderDetails = async () => {
      try {
        if (orderId) {
          const response = await axios.get(`${baseurl}order/${orderId}`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          });
          setOrderData(response.data); 
        }
      } catch (error) {
        console.error("Error fetching order details:", error);
      }
    };
    fetchOrderDetails();
  }, [orderId]);


  return (
    <>
         <div
        className={`flex ${
          isArabic
            ? "flex-col md:flex-row"
            : "flex-col-reverse md:flex-row-reverse "
        } pt-16 w-full`}
        style={{ fontFamily: "Tajwal, sans-serif" }}
      >

      
        <div
          className="container mx-auto px-4 sm:px-6 lg:px-8 mt-10 rtl"
          style={{ direction: language === "ar" ? "rtl" : "ltr" }}
        >
    
          <div className=" border-b pb-4 mb-4">
            <h2 className="text-lg sm:text-xl font-bold"> {translations.pageTitle2}</h2>
          </div>

      
          {orderData ? (
            <div>
              <div
                key={orderData.id}
                className="border rounded-md p-4 mb-4 mt-5"
              >
                <div className="flex justify-between  mb-2">
                  <h2 className="text-lg font-bold">
                  {translations.orderNo} {orderData.orderNo}
                  </h2>
                  <span className="text-gray-600">
                    {new Date(orderData.createdDate).toLocaleDateString()}
                  </span>
                </div>
                <div className="flex justify-between  mb-2">
                  <span className="bg-yellow-500 text-white px-2 py-1 rounded">
                    {orderData.orderStatus}
                  </span>
                  <span className="text-gray-600">
                  {translations.totalAmount} {orderData.total} {translations.denar} 
                  </span>
                </div>
                <div className={`space-y-2 ${isArabic ? "text-right" : "text-left"}`} style={{ direction: isArabic ? "rtl" : "ltr" }}>
                  <p className="font-semibold">
                    {translations.paymentType} {orderData.paymentType}
                  </p>
                  <p className="font-semibold">
                    {translations.discount} {orderData.discount} {translations.denar}
                  </p>
                </div>

              </div>
            </div>
          ) : (
            <p>
            {translations.loadingOrderDetails}
          </p>
          )}

          {/* زر الدفع */}
          <div className="mt-6 text-center">
          <button
      onClick={Paynow}
      className="bg-custom-green text-white py-2 sm:py-3 px-6 sm:px-8 rounded-full font-semibold text-base sm:text-lg w-full md:w-auto"
    >
      {isLoading ? (
        <span>
          {translations.payingNow}
        </span>
      ) : (
        <span>
          {translations.payNow}
        </span>
      )}
    </button>
          </div>
        </div>

     
      </div>
    

     
    </>
  );
}

export default Paytheorder;
