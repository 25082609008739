const ReadBookslang = {
  ar: {
    Navbar: {
      backButton: "العودة إلى الصفحة الرئيسية",
      readingProgress: "تقدم القراءة",
      progressPercentage: "{percentage}%"
    },
    PDF: {
      notFound: "لم يتم العثور على ملف PDF."
    },
    Buttons: {
      overview: "نظرة عامة",
      reviews: "مراجعات",
      notes: "ملاحظات",
      enterPageNumber: "ادخل رقم الصفحة",
      markAsRead: "ضع علامة على أنها تمت قراءة",
      addButton: "إضافة"
    },
    Overview: {
      title: "العنوان",
      description: "الوصف",
      keywords: "الكلمات المفتاحية",
      aboutAuthors: "حول المؤلفين",
      author: "المؤلف",
      publisher: "الناشر"
    },
    Reviews: {
      header: "المراجعات ({count})",
      averageRating: "{average} متوسط التقييم",
      noReviews: "لا توجد مراجعات متاحة، قم بإضافة تعليقك!",
      searchPlaceholder: "ابحث عن مراجعة",
      sortBy: "فرز حسب",
      latest: "الأحدث",
      highestRated: "الأعلى تقييمًا"
    },
    Comments: {
      username: "اسم المستخدم",
      rating: "التقييم",
      comment: "التعليق"
    },
    Notes: {
      title: "صوت واكتب ملاحظاتك :",
      save: "حفظ الملاحظه",
      placeholder:"اكتب ملاحظاتك هنا..."
    },
    error:"  قم بالضغط علي المحتوي ليتم عرض فيديو المطلوب ",
    course_content: "  محتوى الدورة",
    add_to_cart: "أضف إلى سلة التسوق",
    instructor: "يعطي بواسطة",
    instructorbook: "كتب بواسطة ",
    hours_count: "عدد الساعات",
    videos_count: "عدد الفيديوات",
    videos: "الفيديو",
    discount: "خصم",
    price: "دينار",
    old_price: "دينار",
    buy_now: "اشتري الآن",
    related_videos_section: "الفيديوهات ذات الصلة",
    course_title: "   عنوان الدورة التدريبية",
    publisher: "دار النشر", 
    publish_date: "تاريخ النشر",
    product_details: "تفاصيل المنتج", 
    book_title: "عنوان الكتاب",
    author: "المؤلف", 
    isbn: "ISBN",
    publisher_name: "الناشر", 
    related_books: "الكتب ذات الصلة", 
    book_details: "تفاصيل الكتاب", 
    no_related_books: "لا توجد كتب ذات صلة متاحة.",
    add_to_cart_success: "تم إضافة الكتاب إلى سلة التسوق بنجاح", 
    add_to_cart_error: "حدث خطأ أثناء إضافة الكتاب إلى السلة. حاول مرة أخرى",
    liveSeminar: "ندوة مباشرة الآن",
    liveSeminarDescription: "انضم الآن إلى ندوتنا الخاصة حول موضوع مميز يُعرض الآن مباشرة. لا تفوت الفرصة للتعلم والمشاركة.",
    joinNow: "انضم الآن"
  },
  en: {
    Navbar: {
      backButton: "Back to Home Page",
      readingProgress: "Reading Progress",
      progressPercentage: "{percentage}%"
    },
    PDF: {
      notFound: "PDF file not found."
    },
    Buttons: {
      overview: "Overview",
      reviews: "Reviews",
      notes: "Notes",
      enterPageNumber: "Enter Page Number",
      markAsRead: "Mark as Read",
      addButton: "Add"
    },
    Overview: {
      title: "Title",
      description: "Description",
      keywords: "Keywords",
      aboutAuthors: "About the Authors",
      author: "Author",
      publisher: "Publisher"
    },
    Reviews: {
      header: "Reviews ({count})",
      averageRating: "{average} Average Rating",
      noReviews: "No reviews available, add your comment!",
      searchPlaceholder: "Search for a review",
      sortBy: "Sort by",
      latest: "Latest",
      highestRated: "Highest Rated"
    },
    Comments: {
      username: "Username",
      rating: "Rating",
      comment: "Comment"
    },
    Notes: {
      title: "Voice and write your notes:",
      save: "Save the note",
      placeholder: "Write your notes here..."
    },
    error: "Click on the content to display the required video",
    course_content: "Course Content",
    add_to_cart: "Add to Cart",
    instructor: "Instructor",
    hours_count: "Hours Count",
    videos_count: "Videos Count",
    discount: "Discount",
    price: "Dinar",
    old_price: "Dinar",
    buy_now: "Buy Now",
    related_videos_section: "Related Videos",
    course_title: "Course Title",
    instructorbook: "  Written by  ",
    publisher: "Publisher", 
    publish_date: "Publish Date" ,
    product_details: "Product Details", 
    book_title: "Book Title", 
    author: "Author", 
    isbn: "ISBN", 
    publisher_name: "Publisher",  
    related_books: "Related Books",
    book_details: "Book Details",
    no_related_books: "No related books available."  ,
    add_to_cart_success: "Book successfully added to cart", 
    add_to_cart_error: "Error adding book to cart. Please try again",
    liveSeminar: "Live Seminar Now",
    liveSeminarDescription: "Join our exclusive seminar now on a special topic being presented live. Don't miss the opportunity to learn and engage.",
    joinNow: "Join Now"
  }
};

export default ReadBookslang;
