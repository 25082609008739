const ContestsDetailslang = {
  ar: {
    organizer: "المنظم",
    address: "العنوان",
    applyStartDate: "تاريخ بدء التسجيل",
    applyEndDate: "تاريخ الانتهاء",
    papersReplayDate: "تاريخ مراجعة الأوراق",
    detailsTitle: "تفاصيل المسابقة",
    enrollmentEndDate: "تاريخ انتهاء التسجيل",
    contactMobile: "رقم الهاتف",
    contactEmail: "البريد الإلكتروني",
    notes: "ملاحظات",
    conditionsTitle: "شروط المسابقة",
    conditionInvalid: "تنسيق شرط غير صالح",
    courseDetails: "تفاصيل ",
    courseConditions: "شروط الدورة التدريبية",
    seminarDetails: "تفاصيل  ",
    seminarConditions: "شروط الندوة أو المؤتمر",
    seminarDetailstab:  "تفاصيل الندوه او مؤتمر",
    liveCourseOpen: "الكورس المباشر مفتوح الآن",
    liveCourseDescription: "انضم الآن واستفد من المحاضرات الحية والأسئلة المباشرة مع المدرب.",
    joinNow: "انضم الآن",
    contest: "مسابقة",
    contestDescription: "فرصة لربح جوائز رائعة! شارك في المسابقة وكن من الفائزين.",
    joinContest: "شارك الآن",
    bookSection: {
      newBookTag: "جديد",
      bookTitle: "اسم الكتاب الديني",
      bookDescription: "استمتع بقراءة هذا الكتاب الديني الذي يقدم لك إرشادات ومفاهيم عميقة حول الحياة الروحية.",
      getYourCopyNow: "احصل على نسختك الآن"
    }
  },
  en: {
    organizer: "Organizer",
    address: "Address",
    applyStartDate: "Start Date of Registration",
    applyEndDate: "End Date",
    papersReplayDate: "Date of Papers Review",
    detailsTitle: "Contest Details",
    enrollmentEndDate: "Registration End Date",
    contactMobile: "Phone Number",
    contactEmail: "Email",
    notes: "Notes",
    conditionsTitle: "Contest Conditions",
    conditionInvalid: "Invalid condition format",
    courseDetails: "Course Details",
    courseConditions: "Course Conditions",
    seminarDetails: "Seminar Details",
    seminarConditions: "Conditions",
    liveCourseOpen: "Live Course is Now Open",
    liveCourseDescription: "Join now and benefit from live lectures and direct Q&A with the instructor.",
    joinNow: "Join Now",
    contest: "Contest",
    contestDescription: "Chance to win great prizes! Join the contest and be one of the winners.",
    joinContest: "Join Now",
    seminarDetailstab: "Details",
    bookSection: {
      newBookTag: "New",
      bookTitle: "Religious Book Name",
      bookDescription: "Enjoy reading this religious book that provides deep guidance and insights into spiritual life.",
      getYourCopyNow: "Get Your Copy Now"
    }
  },
};

export default ContestsDetailslang;
