import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { baseurl } from "../helper/Baseurl";
import { useTranslation } from "../context/TranslationContext"; 
import { FaSpinner } from 'react-icons/fa'; 
import { Tab, Tabs } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import liveimg from '../assets/images/livepro1.png'
import TextField from '@mui/material/TextField';

export default function ContestsDetails() {
  const navigate = useNavigate();
  const [conditions, setConditions] = useState([]);
  const { Id } = useParams(); 
  const [contests, setContests] = useState(null);

  const [value, setValue] = useState(0); 
  const [loading, setLoading] = useState(false); 
  const { translations , language} = useTranslation(); 
  const isArabic = language === "ar";
  const [formData, setFormData] = useState({
    fullName: "",
    gender: "MALE",
    birthDate: "",
    email: "",
    mobileNo: "",
    city: "",
    nationalityCode: "",
    subscriberNotes: "",
    attachmentFile: null,
    eventId: Id,
  });

      const generateImageUrl = (coverImageUrl) => {
        const baseImageUrl = `${baseurl}uploads/file/download/`;
        return coverImageUrl
          ? `${baseImageUrl}${coverImageUrl}`
          : "";
      };

       useEffect(() => {
         const fetchcontestsDetails = async () => {
           try {
             const response = await axios.get(baseurl + `public/event/${Id}`, {
               headers: {
                 accept: "application/json",
               },
             });
             const conditionsText = response.data.conditions;
             const conditionsArray = conditionsText.split(/\r?\n\r?\n/);
             const imageUrl = generateImageUrl(response.data.coverImageUrl);

             setConditions(conditionsArray);
             setContests({
              ...response.data,
              imageUrl,
            });
         
           } catch (error) {
             console.error("Error fetching contests details", error);
           }
         };

         fetchcontestsDetails();
       }, [Id]); 
         if (!contests)
           return (
             <div className="flex justify-center items-center h-screen">
               <div className="w-10 h-10 border-4 border-gray-200 border-t-blue-500 rounded-full animate-spin"></div>
             </div>
           );

           const handleTabChange = (event, newValue) => {
            setValue(newValue);
          };
        
    
          const handleChange = (e) => {
            const { name, value, type, files } = e.target;
            setFormData((prevData) => ({
              ...prevData,
              [name]: type === "file" ? files[0] : value,
            }));
          };   
           
          const initialFormData = {
            fullName: "",
            gender: "MALE",
            birthDate: "",
            email: "",
            mobileNo: "",
            city: "",
            nationalityCode: "",
            subscriberNotes: "",
            attachmentFile: null,
            eventId: Id,
          };
        
          const handleSubmit = async (e) => {
            e.preventDefault();
            setLoading(true);
            
           
            const dataToSend = { ...formData };
            if (dataToSend.attachmentFile) {
             
              const reader = new FileReader();
              reader.readAsDataURL(dataToSend.attachmentFile);
              reader.onloadend = async () => {
                dataToSend.attachmentFile = reader.result;
                try {
                  await axios.post(baseurl + 'public/event/register', dataToSend, {
                    headers: {
                      'accept': 'application/json',
                      'Content-Type': 'application/json', 
                    },
                  });
                  toast.success(translations.registrationSuccess);
                  setFormData(initialFormData);
                } catch (error) {
                  handleErrorResponse(error);
                } finally {
                  setLoading(false);
                }
              };
            } else {
              // No file to send
              try {
                await axios.post(baseurl + 'public/event/register', dataToSend, {
                  headers: {
                    'accept': 'application/json',
                    'Content-Type': 'application/json',
                  },
                });
                toast.success(translations.registrationSuccess);
                setFormData(initialFormData); 
              } catch (error) {
                handleErrorResponse(error);
              } finally {
                setLoading(false);
              }
            }
          };
          
          const handleErrorResponse = (error) => {
            if (error.response && error.response.data && error.response.data.message) {
              if (error.response.data.message === "Learner has already registered for this event.") {
                toast.warning(translations.alreadyRegistered);
              } else {
                toast.warning(translations.registrationFailed);
              }
            } else {
              toast.warning(translations.registrationFailed);
            }
          };   
          const handleJoinNow = () => {
            navigate("/ShowLive"); 
          };
  return (
 
        <>
        <div className={`flex  ${isArabic ? 'flex-row-reverse' : 'flex-row'} pt-16 w-full mt-10 flex-col sm:flex-row-reverse`}>
         <div
         className="lg:w-1/3 bg-white p-6 shadow-lg rounded-lg "

        style={{
          fontFamily: "Tajwal, sans-serif",
          direction: isArabic ? "rtl" : "ltr",
          textAlign: isArabic ? "right" : "left",
        }}
      >
        <div className="flex flex-col items-center space-y-6 ">
          <img src={contests.imageUrl} alt="Book" className="w-40 mb-2" />
          <h1 className="text-2xl font-bold font-tajwal ">{contests.title}</h1>
          <p
            className="text-justify text-gray-700"
            style={{
              lineHeight: "1.5",
            }}
          >
            {contests.description}
          </p>

  
      <div className="flex items-center space-x-6 rtl:space-x-reverse">
        <div className="text-sm text-red_aa font-tajwal ml-10">
          {translations.applyEndDate}: {contests.applyEndDate || "01/01/2023"}
        </div>
        <div className="text-sm text-gray-500 font-tajwal">
          {translations.papersReplayDate}: {contests.papersReplayDate || "01/01/2023"}
        </div>
      </div>
        </div>
         </div>

        <div className="lg:w-2/3 bg-white p-6 shadow-lg rounded-lg mr-5 mt-5">
          {value === 0 && (

            <>
    <Tabs
      value={value}
      onChange={handleTabChange}
      textColor="primary"
      indicatorColor="primary"
      aria-label="tabs example"
      className={`mb-4 flex ${isArabic ? "flex-row-reverse" : ""}`}
      dir={isArabic ? "rtl" : "ltr"}
    >

        <Tab
          label= {translations.detailsTitle}
          style={{
            fontFamily: "Tajwal, sans-serif",
            fontSize: "16px",
            fontWeight: "bold", 
          }}
        />
        <Tab
          label={translations.conditionsTitle}
          style={{
            fontFamily: "Tajwal, sans-serif",
            fontSize: "16px", 
            fontWeight: "bold", 
          }}
        />
      </Tabs>

      <div >
          
          <table className="w-full " style={{ direction: language === "ar" ? "rtl" : "ltr" }}>
            <tbody className="space-y-2">
              <tr className="border-t border-b">
                <td className="p-4 font-tajwal font-bold">  {translations.address}</td>
                <td className="p-4 font-tajwal">{contests.address}</td>
              </tr>
              <tr className="border-t border-b">
                <td className="p-4 font-tajwal font-bold">    {translations.organizer}</td>
                <td className="p-4 font-tajwal"> {contests.organizer}</td>
              </tr>
              <tr className="border-t border-b">
                <td className="p-4 font-tajwal font-bold">    {translations.applyStartDate}</td>
                <td className="p-4 font-tajwal">{contests.applyStartDate || "01/01/2023"}</td>
              </tr>
              <tr className="border-b">
                <td className="p-4 font-tajwal font-bold">
                {translations.enrollmentEndDate}
                </td>
                <td className="p-4 font-tajwal">{contests.enrollmentEndDate || "01/01/2023"}</td>
              </tr>
              <tr className="border-b">
                <td className="p-4 font-tajwal font-bold"> {translations.contactMobile}</td>
                <td className="p-4 font-tajwal">{contests.contactMobile}</td>
              </tr>
              <tr className="border-b">
                <td className="p-4 font-tajwal font-bold">
                {translations.contactEmail}
                </td>
                <td className="p-4 font-tajwal">{contests.contactEmail}</td>
              </tr>
              <tr className="border-b">
                <td className="p-4 font-tajwal font-bold">{translations.notes}</td>
                <td className="p-4 font-tajwal">{contests.notes}</td>
              </tr>
            </tbody>
          </table>
        </div>
            </>
          )}
        {value === 1 && (
            <>
          <Tabs
            value={value}
            onChange={handleTabChange}
            textColor="primary"
            indicatorColor="primary"
            aria-label="tabs example"
            className={`mb-4 flex ${isArabic ? "flex-row-reverse" : ""}`}
            dir={isArabic ? "rtl" : "ltr"}
          >

            <Tab
          label= {translations.detailsTitle}
          style={{
            fontFamily: "Tajwal, sans-serif",
            fontSize: "16px",
            fontWeight: "bold", 
          }}
        />
        <Tab
          label={translations.conditionsTitle}
          style={{
            fontFamily: "Tajwal, sans-serif",
            fontSize: "16px", 
            fontWeight: "bold", 
          }}
        />
            </Tabs>

            <div >
          
      
          <div className="flex flex-col space-y-4 max-h-96 overflow-y-auto">
            {conditions.map((condition, index) => (
              <div key={index} className="bg-white p-4 border border-gray-200 rounded-md shadow-sm">
                <p className="font-semibold font-tajwal"     dir="rtl">{`${index + 1}. ${condition}`}</p>
              </div>
            ))}
          </div>
        </div>
            </>
            )}
        </div>
        </div>

        <div className={`flex  ${isArabic ? 'flex-row-reverse' : 'flex-row'} pt-16 w-full flex-col sm:flex-row-reverse`}>

          <div className=" p-6 bg-white rounded-xl shadow-lg lg:w-3/4">

             <h2 className="text-xl font-bold mb-6 text-center" style={{ fontFamily: "Tajwal, sans-serif" }}>
                {translations.modalTitle}
              </h2>
              <form onSubmit={handleSubmit} className="space-y-4">
                <div className="flex flex-wrap -mx-2 justify-end items-end">
                  <div className="w-full sm:w-1/2 px-2 mb-4">
                    <label htmlFor="fullName" className="block text-sm font-medium mb-2" style={{ fontFamily: "Tajwal, sans-serif" }}>
                      {translations.fullName}
                    </label>
                    <input
                      type="text"
                      id="fullName"
                      name="fullName"
                      value={formData.fullName}
                      onChange={handleChange}
                      required
                      className="block w-full p-1.5 border border-gray-300 rounded"
                    />
                  </div>
                  <div className="w-full sm:w-1/2 px-2 mb-4">
                    <label htmlFor="nationalityCode" className="block text-sm font-medium mb-2" style={{ fontFamily: "Tajwal, sans-serif" }}>
                      {translations.nationalityCode}
                    </label>
                    <input
                      type="text"
                      id="nationalityCode"
                      name="nationalityCode"
                      value={formData.nationalityCode}
                      onChange={handleChange}
                      required
                      className="block w-full p-1.5 border border-gray-300 rounded"
                    />
                  </div>
                  <div className="w-full sm:w-1/2 px-2 mb-4">
                    <label htmlFor="gender" className="block text-sm font-medium mb-2" style={{ fontFamily: "Tajwal, sans-serif" }}>
                      {translations.gender}
                    </label>
                    <select
                      id="gender"
                      name="gender"
                      value={formData.gender}
                      onChange={handleChange}
                      required
                      className="block w-full p-2 border border-gray-300 rounded"
                      style={{ fontFamily: "Tajwal, sans-serif" }}
                    >
                  {language === 'ar' ? (
                  <>
                    <option value="MALE">ذكر</option>
                    <option value="FEMALE">أنثى</option>
                  </>
                ) : (
                  <>
                    <option value="MALE">Male</option>
                    <option value="FEMALE">Female</option>
                  </>
                )}
                    </select>
                  </div>
                  <div className="w-full sm:w-1/2 px-2 mb-4">
                    <label htmlFor="birthDate" className="block text-sm font-medium mb-2" style={{ fontFamily: "Tajwal, sans-serif" }}>
                      {translations.birthDate}
                    </label>
                    <TextField
                      type="date"
                      size="small"
                      id="birthDate"
                      name="birthDate"
                      value={formData.birthDate}
                      onChange={handleChange}
                      style={{ fontFamily: "Tajwal, sans-serif" }}
                      required
                      className="block w-full p-1.5 border border-gray-300 rounded"
                    />

                  </div>
            
                  <div className="w-full sm:w-1/2 px-2 mb-4">
                    <label htmlFor="email" className="block text-sm font-medium mb-2" style={{ fontFamily: "Tajwal, sans-serif" }}>
                      {translations.email}
                    </label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      required
                      className="block w-full p-1.5 border border-gray-300 rounded"
                    />
                  </div>
            
                  <div className="w-full sm:w-1/2 px-2 mb-4">
                    <label htmlFor="mobileNo" className="block text-sm font-medium mb-2" style={{ fontFamily: "Tajwal, sans-serif" }}>
                      {translations.mobileNo}
                    </label>
                    <input
                      type="text"
                      id="mobileNo"
                      name="mobileNo"
                      value={formData.mobileNo}
                      onChange={handleChange}
                      required
                      className="block w-full p-1.5 border border-gray-300 rounded"
                    />
                  </div>
            
                  <div className="w-full sm:w-1/2 px-2 mb-4">
                    <label htmlFor="attachmentFile" className="block text-sm font-medium mb-2" style={{ fontFamily: "Tajwal, sans-serif" }}>
                      {translations.attachmentFile}
                    </label>
                    <input
                      type="file"
                      id="attachmentFile"
                      name="attachmentFile"
                      onChange={handleChange}
                      className="block w-full p-1 border border-gray-300 rounded cursor-pointer file:cursor-pointer file:bg-custom-orange file:text-white file:px-2 file:py-1 file:border-0 file:mr-2 file:rounded file:text-sm"
                    />
                  </div>
                  <div className="w-full sm:w-1/2 px-2 mb-4">
                    <label htmlFor="city" className="block text-sm font-medium mb-2" style={{ fontFamily: "Tajwal, sans-serif" }}>
                      {translations.city}
                    </label>
                    <input
                      type="text"
                      id="city"
                      name="city"
                      value={formData.city}
                      onChange={handleChange}
                      required
                      className="block w-full p-1.5 border border-gray-300 rounded"
                    />
                  </div>
                  <div className="w-full px-2 mb-4">
                    <label htmlFor="subscriberNotes" className="block text-sm font-medium mb-2" style={{ fontFamily: "Tajwal, sans-serif" }}>
                      {translations.subscriberNotes}
                    </label>
                    <textarea
                      id="subscriberNotes"
                      name="subscriberNotes"
                      value={formData.subscriberNotes}
                      onChange={handleChange}
                      className="block w-full p-2 border border-gray-300 rounded"
                    />
                  </div>
                </div>
            
                <div className="flex justify-center sm:justify-between sm:space-x-4">
                   <button
                          type="submit"
                          className="bg-custom-orange text-white py-2 px-4 rounded w-full sm:w-auto flex items-center justify-center"
                          disabled={loading}
                          style={{ fontFamily: "Tajwal, sans-serif" }}
                        >
                          {loading ? <FaSpinner className="animate-spin text-lg" /> : `${translations.registerButton}`}
                        </button>
         
                </div>
            
              </form>
              
          </div>

          <div className="lg:w-1/4 mt-5 bg-white p-6 shadow-lg rounded-lg mr-5 flex flex-col items-center justify-center text-center relative">
            <div className="w-full mb-4">
              <img
                className="w-full h-70 rounded-lg"
                src={liveimg}
                title="Live Course"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>
            
            <div className="absolute top-2 right-2 bg-red-500 text-white text-xs px-2 py-1 rounded animate-pulse">
              Live
            </div>

            <h3 className="text-lg font-bold mb-2" style={{ fontFamily: "Tajwal, sans-serif" }}>
            {translations.liveCourseOpen}
            </h3>
            <p className="text-sm text-gray-600 mb-4" style={{ fontFamily: "Tajwal, sans-serif" }}>
            {translations.liveCourseDescription}
            </p>

            <button
              className="bg-custom-orange text-white px-4 py-2 rounded-lg shadow hover:bg-green-600 transition"
              style={{ fontFamily: "Tajwal, sans-serif" }}
              onClick={handleJoinNow}
            >
           {translations.joinNow}
             </button>
          </div>


    <ToastContainer />

          </div>
        </>
 
  );
}

