import React , {useState, useEffect } from "react";
import Signup from "../assets/images/Signup.png";
import logo from "../assets/images/logocopy.svg";
import { baseurl } from '../helper/Baseurl';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import { FaSpinner } from 'react-icons/fa';
import { useTranslation } from "../context/TranslationContext"; 
import { Paper } from '@mui/material';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem'; 
const LoginRegister = () => {
    const { translations,  language, changeLanguage} = useTranslation(); 
    const isRtl = language === "ar";

  const navigate = useNavigate();
  const [values, setValues] = useState({
    firstName: '',
    lastName: '',
    birthYear: '',
    email: '',
    mobileNo: '',
    learnerType: '',
    studentId:'',
    password:'',
    otp:''
  });

  const [loading, setLoading] = useState(false); 

  const [showOTP, setShowOTP] = useState(false);
  const handleVerifyClick = async (event) => {
    event.preventDefault(); 
    const isValid = validate1(); 
    if (!isValid) {
      return;
    }
  
    try {
      const response = await axios.post(baseurl + 'public/activation/request-otp', {
        email: values.email 
      }, {
        headers: {
          'Content-Type': 'application/json', 
        },
      });
  
      if (response.status === 201) {
        setShowOTP(true);
        toast.success(translations.doneotp);
      }
    } catch (error) {
      toast.error(translations.genericError);
    }
  };
  

  const [otpError, setOtpError] = useState(false);


  const handleSubmit = async (e) => {
    e.preventDefault();
    const isValid = validate();
    if (!isValid) {handleSubmit()
      return;
    }
  
    setLoading(true);
  
    const year = values.birthYear.substring(0, 4);
    const dataToSend = { ...values, birthYear: year };
  
    try {
      const response = await axios.post(baseurl + 'public/learner/register', dataToSend);
      if (response.status === 201) {
        toast.success(translations.registrationSuccess);
  
        setValues({
          lastName: '',
          firstName: '',
          learnerType: '',
          birthYear: '',
          mobileNo: '',
          studentId: '',
          password: '',
          email: '',
          otp: ''
        });
  
        setTimeout(() => {
          navigate('/Login');
        }, 3000);
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        const errorMessage = error.response.data.message;
  
        if (errorMessage === 'OTP_ALREADY_USED') {
          setOtpError(true); 
          toast.error(translations.otpAlreadyUsed);

        } else if (errorMessage === 'Login name already used!') {
          toast.error(translations.userAlreadyExists)  ;
        } else if(errorMessage === 'INVALID_OTP') {
           values.otp=''
          setOtpError(true); 
          toast.error(translations.invalidOtp  );
        }else {
          toast.error(errorMessage);

        }

        
      }
    } finally {
      setLoading(false);
    }
  };
  
  
  const [errors, setErrors] = useState({
    firstName: '',
    lastName: '',
    birthYear: '',
    email: '',
    mobileNo: '',
    learnerType: '',
    studentId:'',
    password:'',
    otp:''
  });

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSubmit(event); 
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [values]);

  const validate = () => {
    let valid = true;
    let newErrors = { 
    firstName: '',
    lastName: '',
    birthYear: '',
    email: '',
    mobileNo: '',
    learnerType: '',
    studentId:'',
    password:'',
    otp:''
  };
  const idRegex = /^[0-9]+$/;
  const nameRegex = /^[a-zA-Z\u0600-\u06FF\s]+$/; 
  const mobileRegex = /^(091|092|093|094|095)\d{7}$/; 
  const otpRegex = /^\d{6}$/; 

    if (!values.email) {
      newErrors.email = translations.emailError1;
      valid = false;
    } else if (!/\S+@\S+\.\S+/.test(values.email)) {
      newErrors.email = translations.emailError;
      valid = false;
    }
    if (!values.firstName) {
      newErrors.firstName = translations.firstNameError;
      valid = false;
    } else if (!nameRegex.test(values.firstName)) {
      newErrors.firstName = translations.firstNamevalid ;
      valid = false;
    }
    if (!values.lastName) {
      newErrors.lastName = translations.lastNameError;
      valid = false;
    } else if (!nameRegex.test(values.lastName)) {
      newErrors.lastName = translations.lastNamevalid;
      valid = false;
    }

    if (!values.birthYear) {
      newErrors.birthYear = translations.birthYearError;
      valid = false;
    } 

    if (!values.learnerType) {
      newErrors.learnerType = translations.learnerTypeError; 
      valid = false;
    } 
  

    if (!values.password) {
      newErrors.password = translations.passwordError  ;
      valid = false;
    } else if (values.password.length < 4) {
      newErrors.password = translations.passwordLengthError   ;
      valid = false;
    }

    if (!values.mobileNo) {
      newErrors.mobileNo = translations.mobileNoError    ;
      valid = false;
    } else if (!mobileRegex.test(values.mobileNo)) {
      newErrors.mobileNo =  translations.mobileNoError ;
      valid = false;
    }

    if (!values.otp) {
      newErrors.otp = translations.otpenter   ;
      valid = false;
    } else if (!otpRegex.test(values.otp)) {
      newErrors.otp = translations.otpError     ;
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };
  const validate1 = () => {
    let valid = true;
    let newErrors = { 
    firstName: '',
    lastName: '',
    birthYear: '',
    email: '',
    mobileNo: '',
    learnerType: '',
    studentId:'',
    password:'',
    otp:''
  };
  const idRegex = /^[0-9]+$/;
  const nameRegex = /^[a-zA-Z\u0600-\u06FF\s]+$/; 
  const mobileRegex = /^(091|092|093|094|095)\d{7}$/; 
  const otpRegex = /^\d{6}$/; 

  if (!values.email) {
    newErrors.email = translations.emailError;
    valid = false;
  } else if (!/\S+@\S+\.\S+/.test(values.email)) {
    newErrors.email = translations.emailError;
    valid = false;
  }

  // First Name validation
  if (!values.firstName) {
    newErrors.firstName = translations.firstNameError;
    valid = false;
  } else if (!nameRegex.test(values.firstName)) {
    newErrors.firstName = translations.firstNameError;
    valid = false;
  }

  // Last Name validation
  if (!values.lastName) {
    newErrors.lastName = translations.lastNameError;
    valid = false;
  } else if (!nameRegex.test(values.lastName)) {
    newErrors.lastName = translations.lastNameError;
    valid = false;
  }

  // Birth Year validation
  if (!values.birthYear) {
    newErrors.birthYear = translations.birthYearError;
    valid = false;
  }

  // Account Type validation
  if (!values.learnerType) {
    newErrors.learnerType = translations.learnerTypeError;
    valid = false;
  }

  // Password validation
  if (!values.password) {
    newErrors.password = translations.passwordError;
    valid = false;
  } else if (values.password.length < 6) {
    newErrors.password = translations.passwordLengthError;
    valid = false;
  }

  // Mobile Number validation
  if (!values.mobileNo) {
    newErrors.mobileNo = translations.mobileNoError;
    valid = false;
  } else if (!mobileRegex.test(values.mobileNo)) {
    newErrors.mobileNo = translations.mobileNoError;
    valid = false;
  }

  
    setErrors(newErrors);
    return valid;
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === 'mobileNo' && !/^\d*$/.test(value) || name === 'otp' && !/^\d*$/.test(value)  ) {
      return; 
    }

    setValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: ''
    }));
  };


  
  return (
    <div className="w-full md:h-screen flex  my-10 items-center justify-center  bg-white font-tajwal ">
      
      <div className="w-3/3  md:w-2/3  p-6 justify-center items-center">
      <Paper
        elevation={5} 
        sx={{
          borderRadius: '8px',
          backgroundColor: '#d0dee430',
          padding: 4,
        }}
      >
        <div className={`grid grid-cols-1 md:flex ${isRtl ? 'md:flex-row' : 'md:flex-row-reverse'} gap-4`}>
          <div className=" flex-col justify-center items-center w-full md:w-1/2">
        <div className={`w-full flex justify-center items-center`}>
              <img src={logo} alt="Logo" className="mb-2 w-[20%] md:w-[30%]" />
            </div>
            <div className={`w-full text-center mb-5`}>
              <h2 className="text-xl font-bold font-tajwal mb-2">
                {translations.platformWelcome}
              </h2>
              <p className="text-gray-500 font-tajwal text-sm">{translations.continueWatching1}</p>
            </div>
            <div className={`w-full flex justify-center items-center`}>
            <img src={Signup} alt="Logo" className="mb-2 w-[50%] md:w-[70%] " />
            </div>
          </div>
          <form className="w-full">
          <div className="flex flex-wrap">
        
            <div className={`w-full md:w-1/2 px-4 mb-4 ${isRtl ? 'text-right' : 'text-left'}`}>
              <label
                className={`block text-gray-700 font-tajwal text-lg mb-2 ${isRtl ? 'text-right' : 'text-left'}`}
                htmlFor="lastName"
              >
                {translations.lastName}
              </label>
              <TextField
              variant="outlined"
              id="lastName"
              size="small"
              type="text"
              name="lastName"
              value={values.lastName}
              onChange={handleChange}
              placeholder={translations.lastNameError}
              className={`shadow appearance-none font-tajwal ${isRtl ? 'text-right' : 'text-left'} border text-lg rounded-xl w-full py-2 px-3 bg-white text-gray-700 leading-tight focus:outline-none focus:shadow-outline`}
              inputProps={{
                style: {
                  direction: isRtl ? 'rtl' : 'ltr',
                  fontFamily: 'Tajwal, sans-serif', 
                },
              }}
            />


              {errors.lastName && (
                <p className="text-red-500 text-xs mx-1 mt-1 ml-10"  style={{ fontFamily: "Tajwal, sans-serif" }}>
                  {errors.lastName}
                </p>
              )}
            </div>

        
            <div className={`w-full md:w-1/2 px-4 mb-4 ${isRtl ? 'text-right' : 'text-left'}`}>
              <label
                className={`block text-gray-700 font-tajwal text-lg  mb-2 ${isRtl ? 'text-right' : 'text-left'}`}
                htmlFor="firstName"
              >
                {translations.firstName}
              </label>
            <TextField
                variant="outlined"
                size="small"
                id="firstName"
                type="text"
                name="firstName"
                value={values.firstName}
                onChange={handleChange}
                className={`shadow appearance-none font-tajwal ${isRtl ? 'text-right' : 'text-left'} border text-lg rounded w-full bg-white py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline`}
                placeholder={translations.firstNameError}
                inputProps={{
                  style: {
                    direction: isRtl ? 'rtl' : 'ltr',
                    fontFamily: 'Tajwal, sans-serif', 
                  },
                }}
              />
              {errors.firstName && (
                <p className="text-red-500 text-xs mx-1 mt-1 ml-10"  style={{ fontFamily: "Tajwal, sans-serif" }}>
                  {errors.firstName}
                </p>
              )}
            </div>

        
            <div className={`w-full md:w-1/2 px-4 mb-4 ${isRtl ? 'text-right' : 'text-left'}`}>
              <label
                className={`block text-gray-700 font-tajwal text-lg mb-2 ${isRtl ? 'text-right' : 'text-left'}`}
                htmlFor="accountType"
              >
                {translations.accountType}
              </label>
              <TextField
                variant="outlined"
                size="small"
                select
                id="accountType"
                name="learnerType"
                value={values.learnerType}
                onChange={handleChange}
                inputProps={{
                  style: {
                    direction: isRtl ? 'rtl' : 'ltr',
                    fontFamily: 'Tajwal, sans-serif',  
                  },
                }}
                className={`shadow appearance-none font-tajwal ${isRtl ? 'text-right' : 'text-left'} border text-lg rounded w-full py-2 px-3 bg-white text-gray-700 leading-tight focus:outline-none focus:shadow-outline`}
              >
                <MenuItem value="" style={{ fontFamily: 'Tajwal, sans-serif', direction: isRtl ? 'rtl' : 'ltr' }}></MenuItem>
                
                  <MenuItem value="INTERNAL_STUDENT" style={{ fontFamily: 'Tajwal, sans-serif', direction: isRtl ? 'rtl' : 'ltr' }}>
                    {translations.internalStudent}
                  </MenuItem>
                  <MenuItem value="EXTERNAL_STUDENT" style={{ fontFamily: 'Tajwal, sans-serif', direction: isRtl ? 'rtl' : 'ltr' }}>
                    {translations.externalStudent}
                  </MenuItem>
             
                

              </TextField>

            {errors.learnerType && (
              <p className="text-red-500 text-xs mx-1 mt-1 ml-10" style={{ fontFamily: "Tajwal, sans-serif" }}>
                {errors.learnerType}
              </p>
            )}

            </div>

            {/* Birth Year */}
            <div className={`w-full md:w-1/2 px-4 mb-4 ${isRtl ? 'text-right' : 'text-left'}`}>
              <label
                className={`block text-gray-700 font-tajwal text-lg  mb-2 ${isRtl ? 'text-right' : 'text-left'}`}
                htmlFor="birthYear"
              >
                {translations.birthYear}
              </label>
            <TextField
              variant="outlined"
                size="small"
                id="birthYear"
                name="birthYear"
                value={values.birthYear}
                onChange={handleChange}
                type="date"
                inputProps={{
                  style: {
                    direction: isRtl ? 'rtl' : 'ltr',
                    fontFamily: 'Tajwal, sans-serif', 
                  },
                }}
                className={`shadow appearance-none font-tajwal ${isRtl ? 'text-right' : 'text-left'} border text-lg rounded w-full py-2 px-3 bg-white text-gray-700 leading-tight focus:outline-none focus:shadow-outline`}
              />
              {errors.birthYear && (
                <p className="text-red-500 text-xs mx-1 mt-1 ml-10"   style={{ fontFamily: "Tajwal, sans-serif" }}>
                  {errors.birthYear}
                </p>
              )}
            </div>

        
            <div className={`w-full md:w-1/2 px-4 mb-4 ${isRtl ? 'text-right' : 'text-left'}`}>
              <label
                className={`block text-gray-700 font-tajwal text-lg  mb-2 ${isRtl ? 'text-right' : 'text-left'}`}
                htmlFor="mobileNo"
              >
                {translations.mobileNo}
              </label>
            <TextField
              variant="outlined"
                size="small"
                id="mobileNo"
                type="text"
                name="mobileNo"
                value={values.mobileNo}
                onChange={handleChange}
                className={`shadow appearance-none font-tajwal ${isRtl ? 'text-right' : 'text-left'} border text-lg rounded w-full py-2 px-3 bg-white text-gray-700 leading-tight focus:outline-none focus:shadow-outline`}
                placeholder={translations.placeholderPhone}
                inputProps={{
                  style: {
                    direction: isRtl ? 'rtl' : 'ltr',
                    fontFamily: 'Tajwal, sans-serif', 
                  },
                }}
              />
              {errors.mobileNo && (
                <p className="text-red-500 text-xs mx-1 mt-1 ml-10"         style={{ fontFamily: "Tajwal, sans-serif" }}>
                  {errors.mobileNo}
                </p>
              )}
            </div>

        
            <div className={`w-full md:w-1/2 px-4 mb-4 ${isRtl ? 'text-right' : 'text-left'}`}>
              <label
                className={`block text-gray-700 font-tajwal text-lg  mb-2 ${isRtl ? 'text-right' : 'text-left'}`}
                htmlFor="studentId"
              >
                {translations.studentId}
              </label>
              <TextField
              variant="outlined"
                size="small"
                id="studentId"
                type="text"
                name="studentId"
                value={values.studentId}
                onChange={handleChange}
                className={`shadow appearance-none font-tajwal ${isRtl ? 'text-right' : 'text-left'} border text-lg rounded w-full py-2 px-3 bg-white text-gray-700 leading-tight focus:outline-none focus:shadow-outline`}
                placeholder={translations.studentIdplaceholder}
                inputProps={{
                  style: {
                    direction: isRtl ? 'rtl' : 'ltr',
                    fontFamily: 'Tajwal, sans-serif', 
                  },
                }}
              />
              {errors.studentId && (
                <p className="text-red-500 text-xs mx-1 mt-1 ml-10" style={{ fontFamily: "Tajwal, sans-serif" }} >
                  {errors.studentId}
                </p>
              )}
            </div>

          
            <div className={`w-full md:w-1/2 px-4 mb-4 ${isRtl ? 'text-right' : 'text-left'}`}>
              <label
                className={`block text-gray-700 font-tajwal text-lg  mb-2 ${isRtl ? 'text-right' : 'text-left'}`}
                htmlFor="password"
              >
                {translations.password}
              </label>
            <TextField
              variant="outlined"
                size="small"
                id="password"
                type="password"
                name="password"
                value={values.password}
                onChange={handleChange}
                className={`shadow appearance-none font-tajwal ${isRtl ? 'text-right' : 'text-left'} border text-lg rounded w-full py-2 px-3 bg-white text-gray-700 leading-tight focus:outline-none focus:shadow-outline`}
                placeholder={translations.placeholderPassword}
                inputProps={{
                  style: {
                    direction: isRtl ? 'rtl' : 'ltr',
                    fontFamily: 'Tajwal, sans-serif', 
                  },
                }}
              />
              {errors.password && (
                <p className="text-red-500 text-xs mx-1 mt-1 ml-10" style={{ fontFamily: "Tajwal, sans-serif" }}>
                  {errors.password}
                </p>
              )}
            </div>

        
            <div className={`w-full md:w-1/2 px-2 mb-4 ${isRtl ? 'text-right' : 'text-left'}`}>
              <label
                className={`block text-gray-700 font-tajwal text-lg  mb-2 ${isRtl ? 'text-right' : 'text-left'}`}
                htmlFor="email"
              >
                {translations.email}
              </label>
            <TextField
              variant="outlined"
                size="small"
                id="email"
                type="email"
                name="email"
                value={values.email}
                onChange={handleChange}
                className={`shadow appearance-none font-tajwal ${isRtl ? 'text-right' : 'text-left'} border text-lg rounded w-full py-2 px-3 bg-white text-gray-700 leading-tight focus:outline-none focus:shadow-outline`}
                placeholder={translations.placeholderEmail}
                inputProps={{
                  style: {
                    direction: isRtl ? 'rtl' : 'ltr',
                    fontFamily: 'Tajwal, sans-serif', 
                  },
                }}
              />
              {errors.email && (
                <p className="text-red-500 text-xs mx-1 mt-1 ml-10" style={{ fontFamily: "Tajwal, sans-serif" }}>
                  {errors.email}
                </p>
              )}
            </div>
      
          </div>
          {!showOTP ? (
            <button
              type="button"
              disabled={loading} 
              className="bg-custom-orange w-full text-lg font-tajwal text-white  py-2 px-4 rounded-xl focus:outline-none focus:shadow-outline"
              onClick={handleVerifyClick}
            >
                {loading ? (
                      <div className="flex justify-center items-center">
                      <FaSpinner className="w-5 h-5 text-white animate-spin" /> 
                    </div>
                    ) : (
                      translations.verifyButton
                    )}
            </button>
          ) : (
          
            <div>
            <label
              className={`block text-gray-700 font-tajwal text-lg  mb-2 ${isRtl ? 'text-right' : 'text-left'}`}
              htmlFor="otp"
            >
              {translations.otpLabel}
            </label>
            <div className="flex items-center">
              <TextField
                variant="outlined"
                size="small"
                type="text"
                name="otp"
                value={values.otp}
                onChange={handleChange}
                className={`shadow appearance-none font-tajwal ${
                  isRtl ? 'text-right' : 'text-left'
                } border text-lg rounded w-full py-2 px-3 bg-white text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${isRtl ? 'text-right' : 'text-left'}`}
                placeholder={translations.placeholderotpLabel}
                inputProps={{
                  style: {
                    direction: isRtl ? 'rtl' : 'ltr',
                    fontFamily: 'Tajwal, sans-serif', 
                  },
                }}
              />

              {otpError && (
                <button
                  onClick={handleVerifyClick}
                  className="ml-2 bg-custom-green text-white  py-1 px-3 rounded text-sm"
                  style={{ fontFamily: 'Tajwal, sans-serif' }}
                >
                  {loading ? (
                    <div className="flex justify-center items-center">
                      <FaSpinner className="w-5 h-5 text-white animate-spin" />
                    </div>
                  ) : (
                    translations.resendButton
                  )}
                </button>
              )}
            </div>

            {errors.otp && (
              <p
                className={`text-red-500 text-xs mt-1 ${
                  isRtl ? 'text-right' : 'text-left'
                }`}
                style={{ fontFamily: 'Tajwal, sans-serif' }}
              >
                {errors.otp}
              </p>
            )}


          
            <button
              type="submit"
              disabled={!values.otp || loading} // إضافة الشرط هنا
              className={`mt-4 w-full text-lg font-tajwal font-bold py-2 px-4 rounded-xl focus:outline-none focus:shadow-outline ${
                !values.otp || loading
                  ? 'bg-gray-200 text-gray-700 cursor-not-allowed'
                  : 'bg-custom-orange text-white'
              }`}
              onClick={handleSubmit}
            >
              {loading ? (
                <div className="flex justify-center items-center">
                  <FaSpinner className="w-5 h-5 text-white animate-spin" />
                </div>
              ) : (
                translations.submitButton
              )}
            </button>
          </div>
          
          )}
          </form>
      </div>
      </Paper>
    </div>
        

      <ToastContainer />

    </div>
  );
};
export default LoginRegister;
