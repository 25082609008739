import React, { useState, useEffect } from "react";
import { MdArrowForwardIos } from "react-icons/md";
import { MdArrowBackIosNew } from "react-icons/md";
import pic1 from '../../src/assets/images/Banner.png'
import pic2 from '../../src/assets/images/Rectangle.png'

function Slider() {
  const [language, setLanguage] = useState("ar");
  const isArabic = language === "ar"; 

  const images = [
  pic2,
  pic1,
  

  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  const nextImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  useEffect(() => {
    const savedLanguage = sessionStorage.getItem('language');
    if (savedLanguage) {
      setLanguage(savedLanguage);
    }
  }, []);

  return (
    <div dir={isArabic ? "rtl" : "ltr"} className="text-center mt-32">
      <p className="text-lg font-semibold text-custom-orange"    style={{
            fontFamily: "Tajwal, sans-serif",
            textAlign: isArabic ? "right" : "left",
          }}>
        {isArabic
          ? "مرحبا بك في مجمع القران الكريم .. 👋🏼"
          : "Welcome to the Holy Quran Collection..👋🏼"}
      </p>
      <div className="relative flex items-center justify-center mt-4">
        {/* الزر السابق */}
        <button 
          className="bg-custom-orange text-white text-xl px-2 py-2 rounded-full hover:bg-blues1 transition absolute left-0 z-10"
          onClick={prevImage}
        >
          <MdArrowBackIosNew />
        </button>

        {/* الصورة */}
        <div className="relative w-[1500px] h-[400px] mx-4 overflow-hidden">
          <img 
            src={images[currentIndex]} 
            alt="Quran Image" 
            className="w-full h-full object-cover rounded-lg"
          />
        </div>

        {/* الزر التالي */}
        <button 
          className="bg-custom-orange text-white text-xl px-2 py-2 rounded-full hover:bg-blues1 transition absolute right-0 z-10"
          onClick={nextImage}
        >
          <MdArrowForwardIos />
        </button>
      </div>
    </div>
  );
}

export default Slider;
