const BorrowsHistorylang = {
    ar: {
      title: "قائمة طلبات الاستعارة الخاصة بي",
      noOrdersMessage: "لا يوجد طلبات استعارة في الوقت الحالي ..",
      bookName: "اسم الكتاب:",
      borrowRequestStatus: "حالة الطلب:",
      borrowDate: "تاريخ الاستعارة:",
      collectDate: "تاريخ الاستلام:",
      returnDate: "تاريخ الإرجاع:",
      cancelRequest: "إلغاء الطلب",
      modalTitle1: "هل أنت متأكد من إلغاء طلب الاستعارة؟",
      yes: "نعم",
      no: "لا",
      cancelSuccessMessage: "تم الغاء طلب الاستعارة بنجاح",
      cancelErrorMessage: "حدث خطأ أثناء إرسال الطلب. يرجى المحاولة مرة أخرى."
    },
    en: {
      title: "My Borrow Requests List",
      noOrdersMessage: "There are no borrow requests at the moment ..",
      bookName: "Book Name:",
      borrowRequestStatus: "Request Status:",
      borrowDate: "Borrow Date:",
      collectDate: "Collect Date:",
      returnDate: "Return Date:",
      cancelRequest: "Cancel Request",
      modalTitle1: "  Are you sure you want to cancel the borrow request",
      yes: "Yes",
      no: "No",
      cancelSuccessMessage: "The borrow request has been successfully canceled",
      cancelErrorMessage: "There was an error while sending the request. Please try again."
    },
  };
  
  export default BorrowsHistorylang;
  