import React from 'react';
import { Route, Routes } from "react-router-dom"; // إزالة BrowserRouter هنا

import {
    Home,
    Login,
    LoginRegister,
    HomeAfterLogin,
    NavbarLogin,
    MyCourses,
    MyBooks,
    Shop,
    BookDetails,
    ReadBooks,
    Showcourse,
    ShoppingCart,
    CoursesDetails,
    Settings,
    OrderHistory,
    AllNotify,
    BorrowsHistory,
    Myborrow,
    OrderConfirmation,
    Seminars,
    Contests,
    SeminarsDetails,
    MyActivity,
    Allactivity,
    ContestsDetails,
    TrainingCoursesDtails,
    Paytheorder,
    Paynow,
    HelpCenter,
    MyFav,
    Centers,
    Live,
    LiveClass
} from "./page/index";

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/Login" element={<Login/>} />
      <Route path="/LoginRegister" element={<LoginRegister />} />
      <Route path="/HomeAfterLogin" element={<HomeAfterLogin />} />
      <Route path="/NavbarLogin" element={<NavbarLogin />} />
      <Route path="/MyCourses" element={<MyCourses />} />
      <Route path="/Centers" element={<Centers />} />
      <Route path="/Shop" element={<Shop />} />
      <Route path="/HelpCenter" element={<HelpCenter />} />
      <Route path="/MyFav" element={<MyFav />} />
      <Route path="/MyActivity" element={<MyActivity />} />
      <Route path="/MyBooks" element={<MyBooks />} />
      <Route path="/BookDetails/:bookId" element={<BookDetails />} />
      <Route path="/ReadBooks/:bookId" element={<ReadBooks />} />
      <Route path="/Showcourse/:courseId" element={<Showcourse />} />
      <Route path="/ShoppingCart" element={<ShoppingCart />} />
      <Route path="/CoursesDetails/:courseId" element={<CoursesDetails />} />
      <Route path="/ContestsDetails/:Id" element={<ContestsDetails />} />
      <Route path="/TrainingCoursesDtails/:Id" element={<TrainingCoursesDtails />} />
      <Route path="/Contests" element={<Contests />} />
      <Route path="/settings" element={<Settings />} />
      <Route path="/AllNotify" element={<AllNotify />} />
      <Route path="/OrderHistory" element={<OrderHistory />} />
      <Route path="/BorrowsHistory" element={<BorrowsHistory />} />
      <Route path="/Myborrow" element={<Myborrow />} />
      <Route path="/OrderConfirmation/:cartItems" element={<OrderConfirmation />} />
      <Route path="/ShiekSection" element={<Allactivity />} />
      <Route path="/Allactivity" element={<Allactivity />} />
      <Route path="/Seminars" element={<Seminars />} />
      <Route path="/Paytheorder" element={<Paytheorder />} />
      <Route path="/Paynow" element={<Paynow />} />
      <Route path="/SeminarsDetails/:Id" element={<SeminarsDetails />} />
      <Route path="/Live" element={<Live />} />
      <Route path="/LiveClass" element={<LiveClass />} />


    </Routes>
  );
};

export default AppRoutes;
