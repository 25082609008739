import React, { useEffect, useRef, useState } from "react";
import { Room, Track, RoomEvent } from "livekit-client";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { FaMicrophone, FaMicrophoneSlash, FaVideo, FaVideoSlash, FaLaptop, FaComment, FaSignOutAlt, FaHandPaper } from 'react-icons/fa';
import { useNavigate } from "react-router-dom";

const LiveClass = () => {
  const location = useLocation();
    const navigate = useNavigate();
  const { roomName, studentName, token } = location.state || {};
  const [participants, setParticipants] = useState([]);
  const [isJoined, setIsJoined] = useState(false);
  const [room, setRoom] = useState(null);
  const localVideoRef = useRef(null); // لإظهار كاميرا الطالب
  const remoteVideosRef = useRef(null);
  const [isVideoOn, setIsVideoOn] = useState(true);
  const [isAudioOn, setIsAudioOn] = useState(true);
  const [isScreenSharing, setIsScreenSharing] = useState(false);
  const screenTrack = useRef(null);

  // Send POST request to create room for the teacher and get the token
  const createRoomForTeacher = async () => {
    try {
      const response = await axios.post(
        'https://app.holyquran.ly/api/livekit/teacher/create-room',
        {
          roomName: roomName,
          teacherName: studentName, // Assuming the student is also acting as the teacher in this scenario
        }
      );
      const { token } = response.data;
      joinRoom(token);
    } catch (error) {
      console.error("Error creating room for teacher:", error);
      alert("Error creating room.");
    }
  };

  useEffect(() => {
    // Create room and join if we have the roomName and studentName
    if (roomName && studentName) {
      createRoomForTeacher();
    }
  }, [roomName, studentName]);

  const fetchParticipants = async () => {
    try {
      const response = await axios.get(
        `https://app.holyquran.ly/api/livekit/room/${encodeURIComponent(roomName)}/participants`
      );
      setParticipants(response.data);
    } catch (error) {
      console.error("Error fetching participants:", error);
    }
  };

  useEffect(() => {
    if (roomName) {
      fetchParticipants();
    }
  }, [roomName]);

  const isTeacher = (participant) => participant.metadata === "teacher";

  const joinRoom = async (newToken) => {
    if (!newToken) return;
    const newRoom = new Room();
    setRoom(newRoom);

    newRoom.on(RoomEvent.TrackSubscribed, (track, publication, participant) => {
      if (track.kind === Track.Kind.Video) {
        const videoElement = document.createElement("video");
        videoElement.autoplay = true;
        videoElement.playsInline = true;
        track.attach(videoElement);

        const participantContainer = document.getElementById(`participant-${participant.identity}`);
        if (participantContainer) {
          participantContainer.innerHTML = "";
          participantContainer.appendChild(videoElement);
        }
      }
    });

    try {
      await newRoom.connect("ws://156.38.56.79:7880", newToken);
      setIsJoined(true);
      const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
      if (localVideoRef.current) {
        localVideoRef.current.srcObject = stream;
      }
      newRoom.localParticipant.publishTrack(stream.getVideoTracks()[0]);
      newRoom.localParticipant.publishTrack(stream.getAudioTracks()[0]);
    } catch (error) {
      console.error("Error connecting to room:", error);
      alert("Error establishing connection: " + error.message);
    }
  };

  const stopMediaTracks = () => {
    if (localVideoRef.current) {
      const stream = localVideoRef.current.srcObject;
      if (stream) {
        stream.getTracks().forEach(track => track.stop());
      }
    }
  };

  const leaveRoom = async () => {
    if (room) {
      stopMediaTracks();
      await room.disconnect();
      setRoom(null);
      setIsJoined(false);
      navigate("/ShowLive");
    }
  };

  const toggleVideo = async () => {
    if (room) {
      await room.localParticipant.setCameraEnabled(!isVideoOn);
      setIsVideoOn(!isVideoOn);
    }
  };

  const toggleAudio = async () => {
    if (room) {
      await room.localParticipant.setMicrophoneEnabled(!isAudioOn);
      setIsAudioOn(!isAudioOn);
    }
  };

  const shareScreen = async () => {
    if (isScreenSharing) {
      // Stop sharing screen
      if (screenTrack.current) {
        screenTrack.current.stop();
        room.localParticipant.unpublishTrack(screenTrack.current);
        setIsScreenSharing(false);
      }
    } else {
      try {
        const screenStream = await navigator.mediaDevices.getDisplayMedia({
          video: true
        });

        // Attach the screen sharing stream to the room
        const screenTrack = screenStream.getVideoTracks()[0];
        room.localParticipant.publishTrack(screenTrack);
        setIsScreenSharing(true);
        screenTrack.current = screenTrack;
      } catch (err) {
        console.error('Failed to share screen:', err);
        alert('Unable to share screen. Please try again.');
      }
    }
  };

  useEffect(() => {
    joinRoom(token);
    return () => {
      if (room) room.disconnect();
    };
  }, [token]);

  const filteredParticipants = participants.filter(
    (participant) => participant.name !== studentName
  );

  return (
    <div className="flex flex-col mt-20 min-h-screen rounded-md bg-black text-white">
      {/* Video Grid */}
      <div
        ref={remoteVideosRef}
        className={`grid gap-2 flex-grow p-4 ${
          filteredParticipants.length > 1 ? "grid-cols-2 md:grid-cols-3" : "grid-cols-1"
        }`}
      >
        {/* Display the student's local video */}
        <div className="relative bg-gray-900 rounded-md overflow-hidden flex items-center justify-center">
          <video ref={localVideoRef} className="w-full h-full object-cover" autoPlay playsInline muted />
          <div className="absolute top-2 left-2 bg-black bg-opacity-50 px-2 py-1 text-xs rounded">
            {studentName} (الطالب)
          </div>
        </div>

        {/* Display remote participants */}
        {filteredParticipants.map((participant) => (
          <div
            key={participant.identity}
            id={`participant-${participant.identity}`}
            className={`relative bg-gray-900 rounded-md overflow-hidden flex items-center justify-center ${
              isTeacher(participant) ? "border-2 " : ""
            }`}
          >
            <div className="absolute top-2 left-2 bg-black bg-opacity-50 px-2 py-1 text-xs rounded">
              {participant.name} ({participant.metadata})
            </div>
            {isTeacher(participant) && (
              <div className="absolute bottom-2 bg-yellow-500 text-black px-2 py-1 text-xs rounded">
                الأستاذ
              </div>
            )}
          </div>
        ))}
      </div>

      <div className="flex flex-wrap items-center justify-center gap-4 p-4 rounded-b-md bg-gray-800">
  {/* Microphone Button */}
  <button
    className="bg-gray-700 hover:bg-gray-600 px-4 py-2 rounded flex items-center gap-2"
    onClick={toggleAudio}
  >
    {isAudioOn ? <FaMicrophone /> : <FaMicrophoneSlash />}
    <span className="hidden sm:block">{isAudioOn ? "Microphone (On)" : "Microphone (Off)"}</span>
  </button>

  {/* Camera Button */}
  <button
    className="bg-gray-700 hover:bg-gray-600 px-4 py-2 rounded flex items-center gap-2"
    onClick={toggleVideo}
  >
    {isVideoOn ? <FaVideo /> : <FaVideoSlash />}
    <span className="hidden sm:block">{isVideoOn ? "Camera (On)" : "Camera (Off)"}</span>
  </button>

  {/* Screen Share Button */}
  <button
    className="bg-gray-700 hover:bg-gray-600 px-4 py-2 rounded flex items-center gap-2"
    onClick={shareScreen}
  >
    <FaLaptop />
    <span className="hidden sm:block">{isScreenSharing ? "Stop Sharing" : "Share Screen"}</span>
  </button>

  {/* Chat Button */}
  <button className="bg-blue-600 hover:bg-blue-500 px-4 py-2 rounded flex items-center gap-2">
    <FaComment />
    <span className="hidden sm:block">Chat</span>
  </button>

  {/* Raise Hand Button */}
  <button
    className="bg-yellow-600 hover:bg-yellow-500 px-4 py-2 rounded flex items-center gap-2"
   
  >
    <FaHandPaper />
    <span className="hidden sm:block">Raise Hand</span>
  </button>

  {/* Leave Button */}
  <button
    className="bg-red-600 hover:bg-red-500 px-4 py-2 rounded flex items-center gap-2"
    onClick={leaveRoom}
  >
    <FaSignOutAlt />
    <span className="hidden sm:block">Leave</span>
  </button>
</div>

    </div>
  );
};

export default LiveClass;
