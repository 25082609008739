import React, { useEffect, useState } from "react";
import { FaPlayCircle, FaSpinner } from "react-icons/fa";
import axios from "axios";
import { baseurl } from "../helper/Baseurl";
import AddToCartImage from "../assets/images/AddtoCart.png";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "../context/TranslationContext"; 
import { FaTrash } from 'react-icons/fa';  

const showpicbooks = (fileName) => {
  try {
    const imageUrl = `${baseurl}uploads/file/download/${fileName}`;
    return imageUrl;
  } catch (error) {
    return null;
  }
};

const showpiccourses = (fileName) => {
  try {
    const imageUrl = `${baseurl}uploads/file/download/${fileName}`;
    return imageUrl;
  } catch (error) {
    return null;
  }
};

function ShoppingCart() {
    const { translations , language} = useTranslation(); 
    const isArabic = language === "ar";

  const [loading, setLoading] = useState(true);
  const [checkoutLoading, setCheckoutLoading] = useState(false); 
  const [cartItems, setCartItems] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const navigate = useNavigate();
   const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  useEffect(() => {
    const fetchCartItems = async () => {
      try {
        const response = await axios.get(`${baseurl}my-cart`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });

        const { books, courses, total } = response.data;
        setCartItems([...books, ...courses]);
        setTotalPrice(total);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };

    fetchCartItems();
  }, []);

  const clearCart = async () => {
    try {
      const response = await axios.delete(`${baseurl}clear-my-cart`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      if (response.status === 200) {
        toast.success("تم مسح جميع عناصر العربة بنجاح");
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        toast.warning("فشل في مسح عناصر العربة");
      }
    } catch (error) {
      toast.warning("حدث خطأ أثناء مسح عناصر العربة:", error);
    }
  };

  const removeFromCart = async (id, type) => {
    try {
      const response = await axios.delete(`${baseurl}remove-from-cart`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
        data: {
          type: type,
          id: id,
          quantity: 1,
        },
      });

      if (response.status === 200) {
        toast.success("تم إزالة العنصر من السلة بنجاح");
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        toast.warning("فشل في إزالة العنصر من السلة");
      }
    } catch (error) {
      toast.error("حدث خطأ أثناء إزالة العنصر من السلة");
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <FaSpinner className="text-4xl animate-spin" />
      </div>
    );
  }

  const handleCheckout = () => {
    if (cartItems.length === 0) return; // Prevent checkout if cart is empty

    setCheckoutLoading(true); // Show loading spinner on checkout button
    setTimeout(() => {
      navigate(`/OrderConfirmation/${cartItems.length}`, {
        state: { totalPrice },
      });
    }, 2000); // Simulate the delay before navigation
  };
 


  const renderItem = (item, type) => (
<div key={item.id} className="bg-white shadow-md rounded-lg p-4 my-1 flex" style={{ direction: "rtl" }}>
  {type === "course" && (
    <div className="flex flex-row items-center w-full">
     <div className="flex-shrink-0 ml-2 relative">
  <img
    src={showpiccourses(item.coverImageUrl)}
    alt={item.title}
    className="h-30 w-24 rounded"
    style={{ fontFamily: "Tajwal, sans-serif" }}
  />
  <FaPlayCircle className="text-gray-300 text-3xl absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" />
</div>

      <div className="flex flex-col w-full h-full">
      <div className="flex-grow ml-4 ">
        <h3 className="text-md sm:text-lg md:text-xl font-bold mb-2" style={{ fontFamily: "Tajwal, sans-serif" }}>
          {item.title}
        </h3>
        <p
          className="text-gray-600 text-xs sm:text-sm md:text-base mb-2"
          style={{
            fontFamily: "Tajwal, sans-serif",
            textAlign: "justify",
            lineHeight: "1.5",
          }}
        >
          {item.description}
        </p>
      </div>
      <div className="flex flex-row items-start justify-between mt-auto">
    <p className="text-lg sm:text-lg md:text-lg font-bold text-custom-orange mb-2" style={{ fontFamily: "Tajwal, sans-serif" }}>
    {translations.price}  {item.price}  
    </p>
    <button
      className="text-red-500 text-sm sm:text-base md:text-lg"
      style={{ fontFamily: "Tajwal, sans-serif" }}
      onClick={() => removeFromCart(item.id, "COURSE")}
    >
      <FaTrash className="text-red-500 text-lg sm:text-xl md:text-2xl" />
    </button>
  </div>
      </div>
     
    </div>
  )}

  {type === "book" && (
    <div className="flex flex-row items-center w-full">
      <div className=" flex-shrink-0 ml-2">
        <img
          src={showpicbooks(item.coverImageUrl)}
          alt={item.title}
          className="h-30 w-24 rounded"
          style={{ fontFamily: "Tajwal, sans-serif" }}
        />
      </div>
      <div className="flex flex-col w-full h-full">
  <div className="flex-grow-0 ml-4 mt-5">
    <h3 className="text-md sm:text-lg md:text-xl font-bold mb-2" style={{ fontFamily: "Tajwal, sans-serif" }}>
      {item.title}
    </h3>
    <div className="flex text-gray-700 mt-1 text-sm sm:text-base md:text-lg mb-2">
      <div className="flex items-center font-tajwal">
        <span>{translations.author}: {item.author}</span>
      </div>
    </div>
    <div className="flex text-gray-700 mt-1 text-sm sm:text-base md:text-lg mb-2">
      <div className="flex items-center font-tajwal">
        <span>{translations.publisher}: {item.publisher}</span>
      </div>
    </div>
  </div>
  <div className="flex flex-row items-start justify-between mt-auto">
    <p className="text-lg sm:text-lg md:text-lg font-bold text-custom-orange mb-2" style={{ fontFamily: "Tajwal, sans-serif" }}>
    {translations.price}  {item.price}  
    </p>
    <button
      className="text-red-500 text-sm sm:text-base md:text-lg"
      style={{ fontFamily: "Tajwal, sans-serif" }}
      onClick={() => removeFromCart(item.id, "BOOK")}
    >
      <FaTrash className="text-red-500 text-lg sm:text-xl md:text-2xl" />
    </button>
  </div>
</div>

    </div>
  )}
</div>

  );

  return (
    <>
      <div
        className={`flex ${
          isArabic
            ? "flex-col md:flex-row"
            : "flex-col-reverse md:flex-row-reverse "
        } pt-16 w-full`}
      >

        <div className="border-t"></div>
        <div className="flex flex-col lg:flex-row justify-between container mx-auto mt-10 rtl">
          <div className="lg:w-1/3 rounded-lg p-6 mt-6 lg:mt-0">
            <h2
              className="text-2xl font-bold mb-4 text-right"
              style={{ fontFamily: "Tajwal, sans-serif" }}
            >
              {translations.total}
            </h2>
            <div className="flex justify-between mb-4">
              <p
                className="font-bold text-lg"
                style={{ fontFamily: "Tajwal, sans-serif" }}
              >
                {totalPrice.toFixed(2)} {translations.price}
              </p>
              <p
                className="font-bold text-lg"
                style={{ fontFamily: "Tajwal, sans-serif" }}
              >
                {translations.total}
              </p>
            </div>
            <div className="border-t border-gray-300 mb-4"></div>
            <button
              className={`w-full bg-custom-orange text-white py-3 px-4 rounded-lg transition duration-300 ${
                cartItems.length === 0 || checkoutLoading
                  ? "opacity-50 cursor-not-allowed"
                  : ""
              }`}
              onClick={handleCheckout}
              disabled={cartItems.length === 0 || checkoutLoading}
              style={{ fontFamily: "Tajwal, sans-serif" }}
            >
              {checkoutLoading ? (
                <div className="flex justify-center items-center">
                  <FaSpinner className="animate-spin mr-2" />
                  {translations.proceedCheckout}
                </div>
              ) : (
                translations.checkout

              )}
            </button>
          </div>
          <div className="lg:w-2/3 p-6" >
            <h1 className="text-3xl font-bold mb-4 text-right font-tajwal">
            {translations.cart}
            </h1>
            <h6
              className="font-tajwal text-red-500 underline cursor-pointer"
              onClick={clearCart}
            >
               ({translations.clearCart})
            </h6>
            {/*
            {cartItems.map((item) =>
              renderItem(item, item.type === "COURSE" ? "course" : "book")
            )}
           
           */}

            {cartItems.length === 0 ? (
              <div className="flex flex-col items-center justify-center">
                <img src={AddToCartImage} alt="Empty Cart" className="h-72" />
                <p
                  className="text-lg font-bold mt-4"
                  style={{ fontFamily: "Tajwal, sans-serif" }}
                >
                  {translations.emptyCart}
                </p>
              </div>
            ) : (
              <div>
                {cartItems.map((item) =>
                  renderItem(
                    item,
                    item.hasOwnProperty("author") ? "book" : "course"
                  )
                )}
              </div>
            )}
          </div>
        </div>
    

      </div>
      
      <ToastContainer />
    </>
  );
}

export default ShoppingCart;
