import React, { useState, useEffect } from "react";
import ContinueReadingSection from "../component/ContinueReadingSection";
import NewCoursesSection from "../component/NewCoursesSection";
import Slider from "../component/Slider";

function HomeAfterLogin() {
  const [language, setLanguage] = useState("ar");

  useEffect(() => {
    const savedLanguage = sessionStorage.getItem('language');
    if (savedLanguage) {
      setLanguage(savedLanguage);
    }
  }, []);

  return (
    <>
       <Slider/>
       <ContinueReadingSection/>
       <NewCoursesSection/>
    
    </>
  );
}

export default HomeAfterLogin;
