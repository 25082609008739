const LiveLang = {
    ar: {
        liveLecturesTitle: "محاضرات مباشرة لتعليم القرآن الكريم وأحكامه ..",
        liveLecturesSubtitle: "اطلع على المحاضرات المباشرة وشارك الآن ..",
        searchPlaceholder: "اكتب العنوان للبحث .. ",
        noCoursesMessage: "لا توجد محاضرات مباشرة حالياً",
        joinButton: "انضمام",
        participants: "عدد المشاركين",
        maxParticipants: "الحد الأقصى",
        creationTime: "وقت الإنشاء",
        ShowLivePublic:"دروس مباشرة عامة",
        ShowLivePrivate:"دروس مباشرة خاصه"
 },
 en:{
    liveLecturesTitle: "Live Lectures for Quran Education and its Rules..",
    liveLecturesSubtitle: "Explore live lectures and join now..",
    searchPlaceholder: "Enter the title to search.. ",
    noCoursesMessage: "No live lectures available right now",
    joinButton: "Join",
    participants: "Number of participants",
    maxParticipants: "Maximum participants",
    creationTime: "Creation time",
    ShowLivePublic: "Public Live Lessons",
    ShowLivePrivate: "Private Live Lessons"
 }

  };
  
  export default LiveLang;
  