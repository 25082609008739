import React, { useState, useEffect } from "react";
import noCoursesImage from "../assets/images/Search.png"; 
import { baseurl } from "../helper/Baseurl";
import axios from "axios";
import cover1 from "../assets/images/test2.png";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa"; 
import { useTranslation } from "../context/TranslationContext"; 
import { FaUser, FaCalendarAlt, FaMapMarkerAlt } from 'react-icons/fa';


export default function Myconferences() {

  const { translations , language} = useTranslation(); 
  const isArabic = language === "ar";

  const [loading, setLoading] = useState(true);
  const [conferences, setConferences] = useState([]);
const fetchImageUrl = async (fileName) => {
  try {
    if (!fileName) {
      return cover1;
    }
    const imageUrl = `${baseurl}uploads/file/download/${fileName}`;
    console.log("Fetched image URL:", imageUrl);
    return imageUrl;
  } catch (error) {
    console.error("Error fetching image:", error);
    return cover1;
  }
};
useEffect(() => {
  const fetchData = async () => {
    try {
      const response = await axios.get(baseurl + "my-events", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      const data = await Promise.all(
        response.data.CONFERENCE.map(async (conference) => {
          const event = conference.event;
          const imageUrl =
            event && event.coverImageUrl
              ? await fetchImageUrl(event.coverImageUrl)
              : cover1; 

          return {
            id: conference.id,
            title: event.title,
            description: event.description,
            startDate: event.eventStartDate,
            endDate: event.eventEndDate,
            applyStartDate: event.applyStartDate|| "2025-01-28 ",
            applyEndDate: event.applyEndDate|| "2025-01-28 ",
            abstractApplyEndDate: event.abstractApplyEndDate,
            papersReplayDate: event.papersReplayDate,
            enrollmentEndDate: event.enrollmentEndDate,
            contactMobile: event.contactMobile,
            contactWhatsApp: event.contactWhatsApp,
            contactWebsite: event.contactWebsite,
            contactEmail: event.contactEmail,
            conditions: event.conditions,
            organizer: event.organizer,
            address: event.address,
            createdBy: event.createdBy,
            createdDate: event.createdDate,
            lastModifiedBy: event.lastModifiedBy,
            lastModifiedDate: event.lastModifiedDate,
            imageSrc: imageUrl,
            subscriptionStatus: conference.subscriptionStatus || "PENDING", 
          
          };
        })
      );

      setConferences(data);
    } catch (error) {
      console.error("Error fetching conferences:", error);
    } finally {
      setLoading(false);
    }
  };

  fetchData();
}, []);

   const [currentPage, setCurrentPage] = useState(1); 
   const coursesPerPage = 8;
   const indexOfLastCourse = currentPage * coursesPerPage;
   const indexOfFirstCourse = indexOfLastCourse - coursesPerPage;
   const currentCourses = conferences.slice(
     indexOfFirstCourse,
     indexOfLastCourse
   );
   const totalPages = Math.ceil(conferences.length / coursesPerPage);
   const handlePageChange = (pageNumber) => {
     setCurrentPage(pageNumber);
   };

   const statusMapping = {
    PENDING: { text: translations.pending, color: "bg-yellow-500" },
    ACCEPTED: { text: translations.accepted, color: "bg-green-500" },
    WITHDRAWN: { text: translations.withdrawn, color: "bg-gray-500" },
    REJECTED: { text: translations.rejected, color: "bg-red-500" },
    CERTIFIED: { text: translations.certified, color: "bg-blue-500" },
    ARCHIVED: { text: translations.archived, color: "bg-gray-400" },
  };
  

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="w-10 h-10 border-4 border-gray-200 border-t-blue-500 rounded-full animate-spin"></div>
      </div>
    );
  }

  if (conferences.length === 0) {
    return (
      <div className="flex flex-col items-center justify-center h-screen text-center p-4">
        <img
          src={noCoursesImage}
          alt="No conferences available"
          className="w-60 h-60 object-cover"
        />
        <p className="text-lg text-gray-700 mt-4">
        {translations.noConferencesMessage}
        </p>
      </div>
    );
  }
return (
  <div className="p-4">
     <div className="flex flex-wrap -mx-4">
     {currentCourses.map((item) => (
        <div
          key={item.id}
          className="w-full md:w-1/2 lg:w-1/3 px-4 mb-8"
        >
        <div className="bg-white shadow-xl rounded-xl p-4 flex flex-col h-full border border-gray-200 hover:shadow-2xl transition-shadow duration-300 ease-in-out">
        <div className="flex items-center justify-center">
          <img
            src={item.imageSrc}
            alt={item.title}
            className="h-32 w-32 object-cover rounded-full mr-4 ml-10"
          />
        
          </div>

          <div className=" items-center">
          <h3 className="text-lg font-semibold text-gray-800 mb-4 text-center mt-3">
             {item.title}
          </h3>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">

          <p className="text-sm text-gray-600 flex items-center justify-center">
          <FaUser className="ml-3 text-gray-500" />  {item.organizer}
            </p>
            <p className="text-sm text-gray-600 flex items-center justify-center">
            <FaMapMarkerAlt className="ml-3 text-gray-500" /> {item.address}
            </p>
            <p className="text-sm text-gray-600 flex items-center justify-center">
            <FaCalendarAlt className="ml-3 text-gray-500" /> {item.startDate || "2025-01-28 "}
          </p>
          </div>
         
          </div>

          <div className="flex gap-4 mt-4">
          <p className="text-xs text-gray-500 w-1/3">
            <strong>{translations.phone}:</strong>  {item.contactMobile}
            </p>
            <p className="text-xs text-gray-500 w-1/3">
            <strong>{translations.whatsapp}:</strong>  {item.contactWhatsApp}
            </p>
            <p
              className={`text-xs font-bold w-1/3 text-white py-1 px-3 rounded-full text-center ${
                statusMapping[item.subscriptionStatus].color
              }`}
            >
              {statusMapping[item.subscriptionStatus].text}
            </p>
          </div>

        </div>
        </div>
      ))}
    </div>

    {/* Pagination */}
    <div className="mt-6">
      <ul className="flex justify-center items-center space-x-2">
        <li>
          <button
            className={`px-3 py-1 rounded-full ${
              currentPage > 1
                ? "text-custom-orange"
                : "text-gray-400 cursor-not-allowed"
            }`}
            onClick={() => currentPage > 1 && handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
              {isArabic ?    <FaArrowRight /> :    <FaArrowLeft />}
          </button>
        </li>
        {Array.from({ length: totalPages }, (_, index) => (
          <li key={index}>
            <button
              className={`px-3 py-1 rounded-full ${
                currentPage === index + 1
                  ? "bg-custom-orange text-white"
                  : "text-gray-700"
              }`}
              onClick={() => handlePageChange(index + 1)}
            >
              {index + 1}
            </button>
          </li>
        ))}
        <li>
          <button
            className={`px-3 py-1 rounded-full ${
              currentPage < totalPages
                ? "text-custom-orange"
                : "text-gray-400 cursor-not-allowed"
            }`}
            onClick={() =>
              currentPage < totalPages && handlePageChange(currentPage + 1)
            }
            disabled={currentPage === totalPages}
          >
              {isArabic ?      <FaArrowLeft />  :    <FaArrowRight />}
          </button>
        </li>
      </ul>
    </div>
  </div>
);
}
