import React, { useState, useEffect } from "react";

import WishlistBookButton from "../component/WishlistBookButton";
import WishlistButton from "../component/WishlistButton";
import { useTranslation } from "../context/TranslationContext"; 

function MyFav() {

  const [selectedSection, setSelectedSection] = useState("books");
  const { translations , language} = useTranslation(); 
  const isArabic = language === "ar"
  
  const showWishlistbooks = () => setSelectedSection("books");
  const showWishlistcourses = () => setSelectedSection("courses");

  return (
    <>
      <div
        className={`flex ${
          isArabic
            ? "flex-col md:flex-row"
            : "flex-col-reverse md:flex-row-reverse "
        } pt-16 w-full`}
      >
      

        <div
          className=" pt-10 flex-1"
          style={{
            fontFamily: "Tajwal, sans-serif",
            direction: isArabic ? "rtl" : "ltr",
            textAlign: isArabic ? "right" : "left",
          }}
        >
          <h2 className="text-xl font-bold mb-1">{translations.myWishlist}</h2>
          <h4 className="text-l font-bold text-gray-500">
            {translations.wishListDetails}
          </h4>

          <div className="flex flex-wrap justify-center md:justify-start mt-4 gap-2">
            
            <button
              className={`flex items-center px-4 py-2 bg-blue rounded-lg text-l font-bold text-gray-900 hover:bg-custom-orange hover:text-white focus:outline-none ${
                selectedSection === "books"
                  ? "bg-custom-orange text-white"
                  : ""
              }`}
              onClick={showWishlistbooks}
            >
              {translations.wishlistBook}
            </button>
            <button
              className={`flex items-center px-4 py-2 bg-blue rounded-lg text-l font-bold text-gray-900 hover:bg-custom-orange hover:text-white focus:outline-none ${
                selectedSection === "courses"
                  ? "bg-custom-orange text-white"
                  : ""
              }`}
              onClick={showWishlistcourses}
            >
              {translations.wishlistcourse}
            </button>
          </div>

          <div className="mt-4">
            {selectedSection === "books" && <WishlistBookButton />}
            {selectedSection === "courses" && <WishlistButton />}    
          </div>    
        </div>

       
      </div>
    </>
  );
}

export default MyFav;
