import React, { useState, useEffect } from "react";
import axios from "axios";
import { baseurl } from "../helper/Baseurl";
import { useTranslation } from "../context/TranslationContext";
import { FaSpinner, FaUsers, FaChalkboardTeacher, FaPhone, FaCity, FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { FaMapMarkerAlt , FaClock, FaInfoCircle } from "react-icons/fa";

const customIcon = new L.Icon({
  iconUrl: "https://cdn-icons-png.flaticon.com/512/684/684908.png", 
  iconSize: [32, 32],
});

export default function Centers() {
  const { translations, language } = useTranslation();
  const isArabic = language === "ar";
  const [centers, setCenters] = useState([]);
  const [loading, setLoading] = useState(true);

  const [searchCity, setSearchCity] = useState("");
  const [openCenters, setOpenCenters] = useState({});

  const toggleCenter = (id) => {
    setOpenCenters((prev) => ({
      ...prev,
      [id]: !prev[id], // تبديل الحالة فقط للبطاقة المستهدفة
    }));
  };


  const [searchAddress, setSearchAddress] = useState("");
  const [selectedCenter, setSelectedCenter] = useState(null);

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 8;

  useEffect(() => {
    const fetchCenters = async () => {
      try {
        const response = await axios.get(baseurl + "public/center");
        setCenters(response.data);
      } catch (err) {
        console.error("Error fetching centers:", err);
      } finally {
        setLoading(false);
      }
    };
    fetchCenters();
  }, []);

  // Pagination logic
  const filteredCenters = centers.filter(center =>
    center.city.includes(searchCity) && center.address.includes(searchAddress)
  );

  const totalPages = Math.ceil(filteredCenters.length / itemsPerPage);
  const paginatedCenters = filteredCenters.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const defaultPosition = [32.8872, 13.1913];

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <FaSpinner className="text-4xl animate-spin" />
      </div>
    );
  }

  return (
    <>
      <div className={`flex ${isArabic ? "flex-col md:flex-row" : "flex-col-reverse md:flex-row-reverse"} pt-24 w-full`}>
        <div className="container mx-auto px-4 py-6">
          <div
            className="p-4"
            style={{
              fontFamily: "Tajwal, sans-serif",
              direction: isArabic ? "rtl" : "ltr",
              textAlign: isArabic ? "right" : "left",
            }}
          >
            <h1 className="text-xl font-bold mb-1">{translations.pageTitle}</h1>
            <h4 className="text-l font-bold text-gray-500 mr-5">{translations.pageSubtitle}</h4>
            <hr className="my-2 border-gray-200 " />

            <div className="flex items-center  w-full">
              <input
                type="text"
                placeholder={translations.searchPlaceholder}
                className="p-2 border rounded-md w-full mr-4"
                value={searchAddress}
                onChange={(e) => setSearchAddress(e.target.value)}
              />
              <select
                className="p-2 border rounded-md w-auto mr-4"
              >
                <option value="فرز حسب">{translations.sort_by} </option>
                <option value="الأحدث">{translations.newest} </option>
                <option value="الأعلى تقييمًا"> {translations.top_rated}</option>
              </select>
            </div>

            <MapContainer center={defaultPosition} zoom={10}    
             style={{
                height: "500px",
                width: "100%",
                marginTop: "10px",
                position: "relative",
                zIndex: 0, // تأكد أن الـ z-index أقل من الـ Navbar
              }}
            >
              <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />

              {centers.map((center, index) => (
                <Marker key={index} position={[center.lat, center.lng]} icon={customIcon}>
             <Popup>
                <div className="p-2 text-gray-800">
                  <h2
                    className={`text-lg font-bold ${center.isActive ? 'text-green-600' : 'text-red-600'}`}
                  >
                    {center.name}
                  </h2>
                  <p className="text-sm flex items-center">
                    <FaCity className="text-gray-500 ml-1" /> {center.city}
                  </p>
                  <p className="text-sm flex items-center">
                    <FaPhone className="text-gray-500 ml-1" /> {center.phoneNumber}
                  </p>
                  <p className="text-sm flex items-center">
                    <FaUsers className="text-gray-500 ml-1" /> {center.numberOfStudents} طالب
                  </p>
                  <p className="text-sm flex items-center">
                    <FaChalkboardTeacher className="text-gray-500 ml-1" /> {center.numberOfTeachers} معلم
                  </p>
                </div>
              </Popup>

                </Marker>
              ))}
            </MapContainer>

   

      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 mt-5">
      {paginatedCenters.map((center) => (
        <div
          key={center.id}
          className="bg-white shadow-lg rounded-lg p-4 hover:shadow-xl transition duration-300 cursor-pointer"
          onClick={() => toggleCenter(center.id)}
        >
          <div className="font-bold text-lg text-center mb-3">{center.name}</div>
          <div className="text-sm text-gray-600 mb-2 flex items-center">
            {openCenters[center.id] ? center.description : `${center.description.slice(0, 40)}...`}
          </div>
          {openCenters[center.id] && (
            <>
              <div className="text-sm text-gray-600 mb-2 flex items-center">
                <FaMapMarkerAlt className="text-red-500 mr-2" /> {center.city} - {center.address}
              </div>
            
            </>
          )}
        </div>
      ))}
    </div>

            <div className="mt-4">
              <ul className="flex justify-center space-x-2 items-center">
                <li>
                  <button
                    className="px-3 py-1 rounded-full text-custom-orange"
                    onClick={() =>
                      currentPage > 1 && handlePageChange(currentPage - 1)
                    }
                    disabled={currentPage === 1}
                  >
                    {isArabic ? <FaArrowRight /> : <FaArrowLeft />}
                  </button>
                </li>
                {Array.from({ length: totalPages }, (_, index) => (
                  <li key={index}>
                    <button
                      className={`px-3 py-1 rounded-full ${
                        currentPage === index + 1
                          ? "bg-custom-orange text-white"
                          : "text-gray-700"
                      }`}
                      onClick={() => handlePageChange(index + 1)}
                    >
                      {index + 1}
                    </button>
                  </li>
                ))}
                <li>
                  <button
                    className="px-3 py-1 rounded-full text-custom-orange"
                    onClick={() =>
                      currentPage < totalPages &&
                      handlePageChange(currentPage + 1)
                    }
                    disabled={currentPage === totalPages}
                  >
                    {isArabic ? <FaArrowLeft /> : <FaArrowRight />}
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
