import React, { useState, useEffect } from "react";
import { useTranslation } from "../../context/TranslationContext";
import axios from "axios";
import { baseurl } from "../../helper/Baseurl";
import user from "../../assets/images/join.png";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa"; 
import noCoursesImage from "../../assets/images/Search.png"; 
import { FaSpinner } from 'react-icons/fa';
import { useNavigate } from "react-router-dom";

const ShowLivePrivate = () => {
  const { translations, language } = useTranslation();
  const navigate = useNavigate();
  const isArabic = language === "ar";
  const [liveRooms, setLiveRooms] = useState([]);
  const [liveRoomsfiltered, setLiveRoomsfiltered] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const ordersPerPage = 8;
  const indexOfLastOrder = currentPage * ordersPerPage;
  const indexOfFirstOrder = indexOfLastOrder - ordersPerPage;
  const currentOrders = liveRoomsfiltered.slice(indexOfFirstOrder, indexOfLastOrder);
  const totalPages = Math.ceil(liveRoomsfiltered.length / ordersPerPage);
  const [studentName, setStudentName] = useState("");

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await axios.get(baseurl + "my-profile", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        if (response.status === 200) {
          setStudentName(response.data.firstName); 
        }
      } catch (error) {
        console.error("Error fetching profile data:", error);
      }
    };
    fetchProfile();
  }, []);
  

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    const fetchRooms = async () => {
      try {
        const response = await axios.get(baseurl + "livekit/rooms");
        setLiveRooms(response.data);
        setLiveRoomsfiltered(response.data);
      } catch (err) {
        setError("Error loading rooms.");
      } finally {
        setLoading(false);
      }
    };

    fetchRooms();
  }, []);

  useEffect(() => {
    const searchQuery = searchTerm.toLowerCase();
    const filtered = liveRooms.filter((room) =>
      room.name.toLowerCase().includes(searchQuery)
    );
    setLiveRoomsfiltered(filtered); 
  }, [searchTerm, liveRooms]); 

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <FaSpinner className="text-4xl animate-spin text-custom-orange" />
      </div>
    );
  }
  

  if (error) {
    return <div>{error}</div>;
  }



  
  const handleJoinRoom = async (roomName, numParticipants) => {
    try {
      const response = await axios.post(baseurl + "livekit/student/join-room", {
        roomName: roomName,
        studentName: studentName,
      });
  
  
      navigate("/LiveClass", {
        state: {
          roomName: roomName,
          token: response.data.token,
          numParticipants: numParticipants,
          studentName:studentName
        },
      });
    } catch (error) {
      console.error("Error joining room:", error);
    }
  };
  
  
  
  
  return (
    <div className={`flex ${isArabic ? "flex-col md:flex-row" : "flex-col-reverse md:flex-row-reverse"} pt-24 w-full`}>
      <div className="container mx-auto px-4 py-6">

        <div
          className="p-4"
          style={{
            fontFamily: "Tajwal, sans-serif",
            direction: isArabic ? "rtl" : "ltr",
            textAlign: isArabic ? "right" : "left",
          }}
        >
        {currentOrders.length === 0 ? (
       <div className="flex flex-col items-center justify-center h-screen text-center p-4 mt-[-30%]">
       <img
         src={noCoursesImage}
         alt="No courses available"
         className="w-60 h-60 object-cover mb-10"
       />
        <p className="text-lg text-gray-700 mt-0"   style={{ fontFamily: "Tajwal, sans-serif" }}> {translations.noCoursesMessage}</p>

       </div>

        ):(
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 " >
            {
              currentOrders.map((room) => (
                <div key={room.sid} className="border p-4 rounded-lg shadow-md relative">
                  <div className="absolute -top-3 -left-2 bg-red-600 text-white px-3 py-1 rounded-full text-xs animate-pulse">
                    LIVE
                  </div>

                  <div className="flex justify-center mb-4">
                    <img
                      src={user}
                      alt="User"
                      className="w-24 h-24 rounded-3xl border-2 border-gray-300"
                    />
                  </div>

                  <h2 className="text-lg font-semibold text-center">{room.name}</h2>
                  <p className="text-sm text-gray-600 text-center">  {translations.participants} : {room.numParticipants}</p>
                  <p className="text-sm text-gray-600 text-center"> {translations.maxParticipants} : {room.maxParticipants}</p>
                  <p className="text-sm text-gray-500 text-center">   {translations.creationTime} : {new Date(room.creationTime * 1000).toLocaleString()}</p>

                  <div className="flex justify-center mt-4">
                    <button   onClick={() => handleJoinRoom(room.name, room.numParticipants)} className="bg-green-500 text-white py-2 px-4 rounded-full hover:bg-green-600 transition">
                    {translations.joinButton}
                    </button>
                  </div>
                </div>
              ))
             }
          </div>

        )}
      

          <div className="mt-4">
            <ul className="flex justify-center space-x-2 items-center">
              <li>
                <button
                  className="px-3 py-1 rounded-full text-custom-orange"
                  onClick={() => currentPage > 1 && handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                >
                  {!isArabic ? <FaArrowLeft /> : <FaArrowRight />}
                </button>
              </li>
              {Array.from({ length: totalPages }, (_, index) => (
                <li key={index}>
                  <button
                    className={`px-3 py-1 rounded-full ${
                      currentPage === index + 1 ? "bg-custom-orange text-white" : "text-gray-700"
                    }`}
                    onClick={() => handlePageChange(index + 1)}
                  >
                    {index + 1}
                  </button>
                </li>
              ))}
              <li>
                <button
                  className="px-3 py-1 rounded-full text-custom-orange"
                  onClick={() => currentPage < totalPages && handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalPages}
                >
                  {!isArabic ? <FaArrowRight /> : <FaArrowLeft />}
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShowLivePrivate;
