import React, { useState, useEffect } from "react";
import login from "../assets/images/login.png";
import logo from "../assets/images/logocopy.svg";
import { useNavigate } from 'react-router-dom';
import { baseurl } from '../helper/Baseurl';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaSpinner } from 'react-icons/fa'; 
import { useTranslation } from "../context/TranslationContext"; 
import { Paper } from '@mui/material';

const Login = () => {
  const { translations, language, changeLanguage } = useTranslation(); 
  const isRtl = language === "ar";

  const navigate = useNavigate();
  const [values, setValues] = useState({
    username: '',
    password: ''
  });
  const [errors, setErrors] = useState({
    username: '',
    password: ''
  });
  const [loading, setLoading] = useState(false); 

  const handleClick = async (event) => {
    event.preventDefault(); 
    if (!validate()) {
      return;
    }

    setLoading(true); 
    try {
      const response = await axios.post(baseurl + 'authenticate', values);
      if (response.status === 200 && response.data.id_token) {
        localStorage.setItem('token', response.data.id_token);
        navigate('/HomeAfterLogin');
        toast.success(translations.welcome);
      }
    } catch (error) {
      if (error.response.status === 401) {
        toast.warning(translations.passwordErrortoast);
      } else {
        toast.error(translations.inputError);
      }
    } finally {
      setLoading(false); 
    }
  };

  const validate = () => {
    let valid = true;
    let newErrors = { username: '', password: '' };

    if (!values.username) {
      newErrors.username = translations.emailError1;
      valid = false;
    } else if (!/\S+@\S+\.\S+/.test(values.username)) {
      newErrors.username = translations.emailError;
      valid = false;
    }

    if (!values.password) {
      newErrors.password = translations.passwordError;
      valid = false;
    } else if (values.password.length < 4) {
      newErrors.password = translations.passwordLengthError;
      valid = false;
    }
    setErrors(newErrors);
    return valid;
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: ''
    }));
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleClick(event);
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [values]);

  const handleRegisterRedirect = () => {
    navigate('/LoginRegister');
  };

  return (
 
      <div className="w-full h-screen flex my-10 items-center justify-center bg-white font-tajwal">

        <div className="w-3/3  md:w-2/3  p-6 justify-center items-center">
          <Paper
            elevation={5}
            sx={{
              borderRadius: '8px',
              backgroundColor: '#d0dee430',
              padding: 4,
              
            }}
          >
            <div className={`grid grid-cols-1 md:flex ${isRtl ? 'md:flex-row' : 'md:flex-row-reverse'} gap-4 mt-10`}>
              {/* Image Section */}
              <div className=" flex-col justify-center items-center w-full md:w-1/2">
                <div className="w-full flex justify-center items-center">
                <img src={logo} alt="Logo" className="mb-2 w-[20%] md:w-[20%]" />
                </div>
                <div className="w-full text-center mb-5">
                  <h2 className="text-2xl font-bold font-tajwal mb-2">
                    {translations.platformWelcome}
                  </h2>
                  <p className="text-gray-500 font-tajwal">{translations.continueWatching1}</p>
                </div>
                <div className={`w-full flex justify-center items-center`}>
                <img src={login} alt="Logo" className="mb-2 w-[40%] md:w-[50%]" />
                </div>
              </div>
    
              {/* Form Section */}
              <form className="w-full md:w-1/2" onSubmit={handleClick}>
                {/* Email input */}
                <div className={`flex flex-col pt-4  ${isRtl ? 'text-right' : 'text-left'}  md:mt-20`} >
                  <label className="text-lg font-tajwal">{translations.email}</label>
                  <input
                    id="username"
                    name="username"
                    type="email"
                    value={values.username}
                    onChange={handleChange}
                    className={`shadow-md appearance-none border rounded-lg w-full py-2 px-3 text-gray-700 mt-1 leading-tight focus:outline-none focus:ring-2 focus:ring-custom-orange ${isRtl ? 'text-right' : 'text-left'} `}
                    placeholder="your@email.com" 
                  />
                  {errors.username && (
                    <p className="text-red-500 text-xs mx-1 mt-1">{errors.username}</p>
                  )}
                </div>
    
                {/* Password input */}
                <div className={`flex flex-col pt-4  ${isRtl ? 'text-right' : 'text-left'} `}>
                  <label className="text-lg font-tajwal">{translations.password}</label>
                  <input
                    id="password"
                    name="password"
                    type="password"
                    value={values.password}
                    onChange={handleChange}
                    className={`shadow-md appearance-none border rounded-lg w-full py-2 px-3 text-gray-700 mt-1 leading-tight focus:outline-none focus:ring-2 focus:ring-custom-orange ${isRtl ? 'text-right' : 'text-left'} `}
                    placeholder="********"
                  />
                  {errors.password && (
                    <p className="text-red-500 text-xs mx-1 mt-1">{errors.password}</p>
                  )}
                </div>
    
                {/* Submit button */}
                <button
                  type="submit"
                  disabled={loading}
                  className={`bg-custom-orange mt-10 w-full text-lg font-tajwal text-white font-bold py-2 px-4 rounded-xl focus:outline-none focus:ring-2 focus:ring-custom-orange ${loading ? "opacity-50 cursor-not-allowed" : ""}`}
                >
                  {loading ? (
                    <div className="flex justify-center items-center">
                      <FaSpinner className="w-5 h-5 text-white animate-spin" />
                    </div>
                  ) : (
                    translations.register
                  )}
                </button>
    
                {/* Forgot password link */}
                <p className="text-custom-orange text-center mt-8 font-tajwal">
                  <a href="#">{translations.Forgotpass}</a>
                </p>
                <p className="mt-2 font-tajwal text-center">
                  {translations.loginPrompt1}{" "}
                  <a className="text-custom-orange font-tajwal" onClick={handleRegisterRedirect}>
                    {translations.loginnow}
                  </a>
                </p>
              </form>
            </div>
          </Paper>
        </div>
    
        <ToastContainer />
      </div>
   
    
  );
  
};

export default Login;
