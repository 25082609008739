import React, { useState } from 'react';
import AllContests from "../component/AllContests";
import TrainingCourses from "../component/TrainingCourses";
import SeminarsAll from "../component/SeminarsAll";
import ConferencesAll from "../component/ConferencesAll";
import { useTranslation } from "../context/TranslationContext"; 

export default function Allactivity() {
  const [selectedSection, setSelectedSection] = useState("allContests");
  const showMyContests = () => setSelectedSection("allContests");
  const showMyTrainingCourses = () => setSelectedSection("TrainingCourses");
  const showSeminars = () => setSelectedSection("allSeminars");
  const showConferences = () => setSelectedSection("allConferences");
   const { translations , language} = useTranslation(); 
      const isArabic = language === "ar";
          const [selectedOption, setSelectedOption] = useState(selectedSection);
          const handleSelectChange = (event) => {
            const value = event.target.value;
            setSelectedOption(value);
            switch (value) {
             
              case "allContests":
                showMyContests();
                break;
              case "TrainingCourses":
                showMyTrainingCourses();
                break;
              case "allConferences":
                showConferences();
                break;
              case "allSeminars":
                showSeminars();
                break;
              default:
                break;
            }
          };
        
return (
  <>
      <div className={`flex ${isArabic ? 'flex-col md:flex-row' : 'flex-col-reverse md:flex-row-reverse '} pt-24 w-full`}>
      <div
          className="flex-1"
          style={{
            fontFamily: "Tajwal, sans-serif",
            direction: isArabic ? "rtl" : "ltr",
            textAlign: isArabic ? "right" : "left",
          }}
        >      
    <h2 className="text-xl font-bold mb-1">{translations.activity}</h2>
          <h4 className="text-l font-bold text-gray-500 mb-4">
            {translations.activityDescription}
          </h4>
        <div
          className="pt-8"
          style={{
            fontFamily: "Tajwal, sans-serif",
            direction: "rtl",
            textAlign: "right",
          }}
        >
              <div className="mt-4">
              <div className="sm:hidden">
        <select
          className="w-full px-4 py-2 rounded-lg border border-gray-300 bg-white text-gray-900 focus:outline-none focus:ring-2 focus:ring-custom-orange"
          value={selectedOption}
          onChange={handleSelectChange}
        >
  
          <option value="allContests">  {translations.contestsButton}  </option>
          <option value="TrainingCourses">  {translations.trainingCoursesButton}   </option>
          <option value="allConferences">  {translations.conferencesButton}  </option>
          <option value="allSeminars"> {translations.seminarsButton}   </option>
        </select>
      </div>
        <div className="hidden sm:flex flex-wrap justify-center md:justify-start gap-2">
        <button
          className={`flex items-center px-4 py-2 rounded-lg text-l font-bold text-gray-900 bg-blue hover:bg-custom-orange hover:text-white focus:outline-none ${
            selectedSection === "allContests" ? "bg-custom-orange text-white" : ""
          }`}
          onClick={showMyContests}
        >
             {translations.contestsButton}
        </button>
        <button
          className={`flex items-center px-4 py-2 rounded-lg text-l font-bold text-gray-900 bg-blue hover:bg-custom-orange hover:text-white focus:outline-none ${
            selectedSection === "TrainingCourses" ? "bg-custom-orange text-white" : ""
          }`}
          onClick={showMyTrainingCourses}
        >
             {translations.trainingCoursesButton}
        </button>
        <button
          className={`flex items-center px-4 py-2 rounded-lg text-l font-bold text-gray-900 bg-blue hover:bg-custom-orange hover:text-white focus:outline-none ${
            selectedSection === "allConferences" ? "bg-custom-orange text-white" : ""
          }`}
          onClick={showConferences}
        >
             {translations.conferencesButton}
        </button>
        <button
          className={`flex items-center px-4 py-2 rounded-lg text-l font-bold text-gray-900 bg-blue hover:bg-custom-orange hover:text-white focus:outline-none ${
            selectedSection === "allSeminars" ? "bg-custom-orange text-white" : ""
          }`}
          onClick={showSeminars}
        >
            {translations.seminarsButton}
        </button>
          </div>
              </div>
     
          <hr className="my-2 border-gray-200 mb-10" />
          {selectedSection === "allContests" && <AllContests />}
          {selectedSection === "TrainingCourses" && <TrainingCourses />}
          {selectedSection === "allSeminars" && <SeminarsAll/>}
          {selectedSection === "allConferences" && <ConferencesAll/>}
        </div>
      </div>
    </div>
  </>
);
}
