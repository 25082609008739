import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { CiLogout, CiSettings } from "react-icons/ci";
import { IoMdNotifications } from "react-icons/io";
import { FaUser } from "react-icons/fa";
import Notifications from "./Notifications";
import { Link } from "react-router-dom";
import { GrUnorderedList } from "react-icons/gr";
import { CiViewList } from "react-icons/ci";
import { baseurl } from "../helper/Baseurl";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "../context/TranslationContext";
import { IoMdCart } from "react-icons/io";
import { FaHeart } from "react-icons/fa";
import user from "../assets/images/user.webp";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { IoLanguageOutline } from "react-icons/io5";
import { MdOutlineLocalActivity } from "react-icons/md";

const NavbarLogin = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { translations,  language, changeLanguage } = useTranslation(); 
  const [showLibraryMenu, setShowLibraryMenu] = useState(false);
  const [showSubLibraryMenu, setShowSubLibraryMenu] = useState(false);
  const [showNotifications, setShowNotifications] = useState(false);
  const [showUserMenu, setShowUserMenu] = useState(false);
  const [cartItemCount, setCartItemCount] = useState(0);
  const [unreadNotificationCount, setUnreadNotificationCount] = useState(0); 

  const [profileData, setProfileData] = useState({
      firstName: "",
      lastName: "",
      email: "",
      mobileNo: "",
      learnerType: "",
      studentId: "",
    });

    useEffect(() => {
      const fetchProfile = async () => {
        try {
          const response = await axios.get(baseurl + "my-profile", {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          });
          if (response.status === 200) {
            setProfileData(response.data);
          }
        } catch (error) {
          console.error("Error fetching profile data:", error);
        }
      };
      fetchProfile();
    }, []);

  useEffect(() => {
      const storedLanguage = sessionStorage.getItem('language') || 'ar'; 
      changeLanguage(storedLanguage); 
    }, []);
    
    const handleLanguageChange = () => {
      const newLanguage = language === 'ar' ? 'en' : 'ar';
      changeLanguage(newLanguage); 
    };
  const navigate = useNavigate(); 

  const closeMenus = () => {
    setShowUserMenu(false);
    setShowLibraryMenu(false);
    setShowNotifications(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      closeMenus();
    };

    if (showUserMenu || showLibraryMenu || showNotifications) {
      window.addEventListener("click", handleClickOutside);
    }

    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, [showUserMenu, showLibraryMenu, showNotifications]);

  const toggleUserMenu = (event) => {
    event.stopPropagation(); 
    setShowUserMenu(!showUserMenu);
    setShowLibraryMenu(false);
    setShowNotifications(false);
  };

  const toggleLibraryMenu = (event) => {
    event.stopPropagation(); 
    setShowLibraryMenu(!showLibraryMenu);
    setShowUserMenu(false);
    setShowNotifications(false);
  };

  const toggleNotifications = (event) => {
    event.stopPropagation(); 
    setShowNotifications(!showNotifications);
    setShowLibraryMenu(false);
    setShowUserMenu(false);

  };


  useEffect(() => {
    const fetchCartItemCount = async () => {
      try {
        const response = await axios.get(baseurl + "my-cart", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        setCartItemCount(
          response.data.books.length + response.data.courses.length
        );
      } catch (error) {
        console.error("Error fetching cart item count:", error);
      }
    };

    const fetchUnreadNotificationCount = async () => {
      try {
        const response = await axios.get(baseurl + "my-notifications", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        const unreadCount = response.data[0].filter(
          (notification) => !notification.isRead
        ).length;
        setUnreadNotificationCount(unreadCount); 
      } catch (error) {
        console.error("Error fetching unread notification count:", error);
      }
    };

    fetchCartItemCount();
    fetchUnreadNotificationCount();

    const handleCartUpdated = () => {
      fetchCartItemCount();
    };

    window.addEventListener("cartUpdated", handleCartUpdated);

    return () => {
      window.removeEventListener("cartUpdated", handleCartUpdated);
    };
  }, []);

  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/");
  };

  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);


  return (
<div
  className="flex items-center justify-between bg-white p-4 shadow-md top-0 z-10 w-full"
  style={{
    position: "fixed",
    direction: language === "ar" ? "rtl" : "ltr",
  }}
>
 
  <div className="flex items-center">
    <Link to="/HomeAfterLogin">
      <img src="/logo.png" alt="Logo" className="w-12 h-12 mr-3 " />
    </Link>
   


      <div className="md:hidden flex items-center mr-5">
      <button
              onClick={() => setIsOpen(!isOpen)}
              className="mobile-menu-button text-custom-orange bg-blues1 rounded-full p-3"
            >
          <svg
            className="w-5 h-5 sm:w-6 sm:h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 6h16M4 12h16M4 18h16"
            ></path>
          </svg>
        </button>
      </div>
      {isOpen && (
  <div
    ref={menuRef}
    className="absolute top-20 sm:top-16 md:top-20 left-0 w-full bg-white shadow-md flex flex-col space-y-2 px-4 py-2 text-xs sm:text-sm"
    
  >
     <Link
      to="/Shop"
      onClick={() => setIsOpen(false)}
      className="py-2 text-gray-700 hover:bg-gray-100 rounded flex justify-center items-center"
      style={{ fontFamily: "Tajwal, sans-serif" }}
    >
      {translations.store }
    </Link>

    <Link
      to="/centers"
      onClick={() => setIsOpen(false)}
      className="py-2 text-gray-700 hover:bg-gray-100 rounded flex justify-center items-center"
      style={{ fontFamily: "Tajwal, sans-serif" }}
    >
      {translations.quranCenters}
    </Link>

   

    <Link
      to="/MyActivity"
      onClick={() => setIsOpen(false)}
      className="py-2 text-gray-700 hover:bg-gray-100 rounded flex justify-center items-center"
      style={{ fontFamily: "Tajwal, sans-serif" }}
    >
      {translations.activities }
    </Link>
    <Link
      to="/Live"
      onClick={() => setIsOpen(false)}
      className="py-2 text-gray-700 hover:bg-gray-100 rounded flex justify-center items-center"
      style={{ fontFamily: "Tajwal, sans-serif" }}
    >
             {translations.live }

    </Link>
    
    <div
  className="py-2 text-gray-700 font-medium flex justify-center items-center cursor-pointer  hover:bg-gray-100 rounded"
  onClick={() => setShowSubLibraryMenu(!showSubLibraryMenu)}  style={{ fontFamily: "Tajwal, sans-serif" }}
>
  {translations.library }
  <span className="mx-2">
    {showSubLibraryMenu ? <FaChevronUp /> : <FaChevronDown />}
  </span>
</div>

    {showSubLibraryMenu && (
      <>
        <Link
          to="/MyBooks"
          onClick={() => {
            setIsOpen(false);
            setShowSubLibraryMenu(false);
          }}
            className="py-2 text-gray-700 hover:bg-gray-100 rounded flex justify-center items-center"
          style={{ fontFamily: "Tajwal, sans-serif" }}
        >
          {translations.digitalLibrary }
        </Link>

        <Link
          to="/Myborrow"
          onClick={() => {
            setIsOpen(false);
            setShowSubLibraryMenu(false);
          }}
          className="py-2 text-gray-700 hover:bg-gray-100 rounded flex justify-center items-center"
                    style={{ fontFamily: "Tajwal, sans-serif" }}
        >
          {translations.localLibrary }
        </Link>

        <Link
          to="/MyCourses"
          onClick={() => {
            setIsOpen(false);
            setShowSubLibraryMenu(false);
          }}
        className="py-2 text-gray-700 hover:bg-gray-100 rounded flex justify-center items-center"
          style={{ fontFamily: "Tajwal, sans-serif" }}
        >
          {translations.recordedLectures }
        </Link>
      </>
    )}
  </div>
)}



    <div className="hidden md:flex gap-4 mr-10">
      <Link
        to="/Shop"
        className="text-gray-700 hover:text-custom-orange hover:bg-blues1 font-medium p-2 rounded "
        style={{ fontFamily: "Tajwal, sans-serif" }}
      >
        {translations.store }
      </Link>
      <Link
        to="/Allactivity"
        className="text-gray-700 hover:text-custom-orange hover:bg-blues1 font-medium p-2 rounded"
        style={{ fontFamily: "Tajwal, sans-serif" }}
      >
        {translations.activities }
      </Link>
      
       <div className="p-2 rounded-md  transition duration-200 hover:text-custom-orange"
    
    onClick={(e) => toggleLibraryMenu(e)}
   >

     <span className="text-gray-700 hover:text-custom-orange hover:bg-blues1 font-medium p-2 rounded"  style={{ fontFamily: "Tajwal, sans-serif" }}>
       {translations.library } 
     </span>
  
   </div>

   <div className="relative group">
 
  
  {showLibraryMenu && (
    <div
    className={`  absolute mt-10 ml-15 w-48 bg-white border border-gray-200 rounded-md shadow-lg z-10 ${        language === "ar" ? "left-0" : "right-0"
      }`}
      style={{ direction: language === "ar" ? "rtl" : "ltr" }}
    >
      <div className="px-4 py-2 flex items-center">
        <span
          className="text-gray-800 font-semibold"
          style={{ fontFamily: "Tajwal, sans-serif" }}
        >
          {translations.library}
        </span>
      </div>

      <hr className="my-2 border-gray-200" />

      <div className="px-4 py-2 flex flex-col space-y-2">
        <Link
          to="/MyBooks"
  
          className={`fw-full  px-4 py-2 text-gray-700 hover:bg-gray-100 flex items-center `}
          style={{ fontFamily: "Tajwal, sans-serif" }}
        >
          {translations.digitalLibrary}
        </Link>

        <Link
          to="/Myborrow"
     
          className={`w-full  px-4 py-2 text-gray-700 hover:bg-gray-100 flex items-center  `}
          style={{ fontFamily: "Tajwal, sans-serif" }}
        >
          {translations.localLibrary}
        </Link>

        <Link
          to="/MyCourses"
 
          className={`w-full px-4 py-2 text-gray-700 hover:bg-gray-100 flex items-center`}
          style={{ fontFamily: "Tajwal, sans-serif" }}
        >
          {translations.recordedLectures}
        </Link>

      
      </div>

      <hr className="my-2 border-gray-200" />
    </div>
  )}
</div>

      <Link
        to="/centers"
        className="text-gray-700 hover:text-custom-orange hover:bg-blues1 font-medium p-2 rounded"
        style={{ fontFamily: "Tajwal, sans-serif" }}
      >
        {translations.quranCenters }
      </Link>
      
      <Link
        to="/Live"
        className="text-gray-700 hover:text-custom-orange hover:bg-blues1 font-medium p-2 rounded"
        style={{ fontFamily: "Tajwal, sans-serif" }}
      >
        {translations.live }
      </Link>

    </div>
  </div>

  <div className="flex items-center gap-2">
    <Link to="/MyFav" className="relative group">
      <div className="p-2 rounded-md group-hover:bg-blues1 transition duration-200 hover:text-custom-orange">
        <FaHeart className="text-gray-700 text-xl group-hover:text-custom-orange" />
      </div>
    </Link>
    <button onClick={(e) => toggleNotifications(e)} className="relative group">
  <div className="p-2 rounded-md group hover:bg-blues1 transition duration-200 hover:text-custom-orange relative">
    <IoMdNotifications className="text-gray-700 text-2xl group-hover:text-custom-orange" />
    
    {unreadNotificationCount > 0 && (
      <span className="absolute top-2 right-3 transform translate-x-1/2 -translate-y-1/2 bg-red-500 text-white  text-xs rounded-full w-4 h-4 flex items-center justify-center">
        {unreadNotificationCount}
      </span>
    )}
  </div>

  {showNotifications && <Notifications />}
</button>

    <Link to="/ShoppingCart" className="relative group">
      <div className="p-2 rounded-md group-hover:bg-blues1 transition duration-200 hover:text-custom-orange">
        <IoMdCart className="text-gray-700 text-2xl group-hover:text-custom-orange" />
      </div>
      {cartItemCount > 0 && (
      <span className="absolute top-2 right-3 transform translate-x-1/2 -translate-y-1/2 bg-red-500 text-white text-xs rounded-full w-4 h-4 flex items-center justify-center">
          {cartItemCount}
        </span>
      )}
    </Link>

    <div className="relative group">
      <div className="p-2 rounded-md group-hover:bg-blues1 transition duration-200 hover:text-custom-orange">
        <FaUser
          className="text-gray-700 text-xl cursor-pointer group-hover:text-custom-orange"
          onClick={(e) => toggleUserMenu(e)}
        />
      </div>
      {showUserMenu && (
        <div
          className={`absolute mt-2 w-64 bg-white border border-gray-200 rounded-md shadow-lg z-10 ${
            language === "ar" ? "left-0" : "right-0"
          }`}
          style={{ direction: language === "ar" ? "rtl" : "ltr" }}
        >
          <div className="px-4 py-2 flex items-center space-x-4">
            <img
              src={user}
              alt="User"
              className="w-12 h-12 rounded-full border-2 border-gray-200 mr-5 ml-3"
            />
            <div className="flex flex-col text-center">
              <span
                className="text-gray-800 font-semibold"
                style={{ fontFamily: "Tajwal, sans-serif" }}
              >
                {profileData.firstName} {profileData.lastName}
              </span>
              <span
  className="text-gray-500 text-sm"
  style={{ fontFamily: "Tajwal, sans-serif" }}
>
  {profileData.learnerType === "INTERNAL_STUDENT"
    ? translations.internalStudent
    : translations.externalStudent}
</span>
            </div>
          </div>

          <hr className=" border-gray-200" />

          <div className="flex flex-col space-y-2 text-center">
            <Link
              to="/settings"
              className="w-full text-left px-4 py-2 text-gray-700 hover:bg-gray-100 flex items-center justify-between"
              style={{ fontFamily: "Tajwal, sans-serif" }}
            >
              <span>{translations.settings}</span>
              <CiSettings />
            </Link>
            <Link
              to="/OrderHistory"
              className="w-full text-left px-4 py-2 text-gray-700 hover:bg-gray-100 flex items-center justify-between"
              style={{ fontFamily: "Tajwal, sans-serif" }}
            >
              <span>{translations.order_history}</span>
              <GrUnorderedList  />
            </Link>
            <Link
              to="/borrowsHistory"
              className="w-full text-left px-4 py-2 text-gray-700 hover:bg-gray-100 flex items-center justify-between"
              style={{ fontFamily: "Tajwal, sans-serif" }}
            >
              <span>{translations.borrows_history}</span>
              <CiViewList />
            </Link>
            <Link
              to="/MyActivity"
              className="w-full text-left px-4 py-2 text-gray-700 hover:bg-gray-100 flex items-center justify-between"
              style={{ fontFamily: "Tajwal, sans-serif" }}
            >
              <span>{translations.myactivity}</span>
              <MdOutlineLocalActivity />
            </Link>
          </div>

          <hr className=" border-gray-200" />

          <div className=" flex flex-col space-y-2">
            <button
              className="w-full text-left text-gray-700 hover:bg-gray-100 flex items-center justify-between"
              style={{ fontFamily: "Tajwal, sans-serif" }}
            >
              <span>
                {language === "ar"
                  ? translations.english
                  : translations.arabic}
              </span>
            </button>
            <button
              className="w-full text-left px-4 py-2  text-gray-700 hover:bg-gray-100 flex items-center justify-between"
              style={{ fontFamily: "Tajwal, sans-serif" }}
              onClick={handleLanguageChange}
            >
              <span>{translations.Language}</span>
              <IoLanguageOutline />
            </button>
            <button
              className="w-full text-left px-4 py-2  text-gray-700 hover:bg-gray-100 flex items-center justify-between"
              style={{ fontFamily: "Tajwal, sans-serif" }}
              onClick={handleLogout}
            >
              <span>{translations.logout}</span>
              <CiLogout />
            </button>
          </div>
        </div>
      )}
    </div>
  </div>


   
</div>

  
  );
};

export default NavbarLogin;
