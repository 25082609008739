const Seminarslang = {
    en: {
      seminarsAndConferences: "Seminars & Conferences",
      continueWatching: "Continue watching seminars and conferences",
      viewAllSeminars: "View All Seminars",
      viewAllConferences: "View All Conferences",
      noSeminarsAdded: "No seminars have been added at the moment.",
      seminarStartDate: "Start Date:",
      organizer: "Organizer:",
      seminarDetails: " Details",
      registerForSeminar: "Register for Seminar",
      noConferencesAdded: "No conferences have been added at the moment.",
      speaker: "Speaker:",
      conferenceDetails: " Details",
      registerForConference: "Register for Conference",

    },
    ar: {
      seminarsAndConferences: "الندوات والمؤتمرات",
      continueWatching: "مواصلة مشاهدة الندوات والمؤتمرات",
      viewAllSeminars: "عرض جميع الندوات",
      viewAllConferences: "عرض جميع المؤتمرات",
      noSeminarsAdded: "لا يوجد ندوات تمت اضافتها في الوقت الحالي..",
      seminarStartDate: "تاريخ بدء:",
      organizer: "المنظم:",
      seminarDetails: "تفاصيل ",
      registerForSeminar: "تسجيل في الندوة",
      noConferencesAdded: "لا يوجد مؤتمرات تمت اضافتها في الوقت الحالى ..",
      speaker: "المتحدث:",
      conferenceDetails: "تفاصيل ",
      registerForConference: "  تسجيل في المؤتمر",

    },
  };
  
  export default Seminarslang;
  