import React, { useState, useEffect } from "react";

import MyBookButton from "../component/MyBookButton";
import ArchiveButton from "../component/ArchiveBookButton";
import { useTranslation } from "../context/TranslationContext"; 

function MyFav() {
  const [selectedSection, setSelectedSection] = useState("myBooks");
  const { translations , language} = useTranslation(); 
  const isArabic = language === "ar";
  const showMyBooks = () => setSelectedSection("myBooks");
  const showArchive = () => setSelectedSection("archive");

  return (
    <>
      <div
        className={`flex ${
          isArabic
            ? "flex-col md:flex-row"
            : "flex-col-reverse md:flex-row-reverse "
        } pt-16 w-full`}
      >
      

        <div
          className=" pt-10 flex-1"
          style={{
            fontFamily: "Tajwal, sans-serif",
            direction: isArabic ? "rtl" : "ltr",
            textAlign: isArabic ? "right" : "left",
          }}
        >
          <h2 className="text-xl font-bold mb-1">{translations.libraryTitle}</h2>
          <h4 className="text-l font-bold text-gray-500">
            {translations.continueReading}
          </h4>

          <div className="flex flex-wrap justify-center md:justify-start mt-4 gap-2">
            <button
              className={`flex items-center px-4 py-2 bg-blue rounded-lg text-l font-bold text-gray-900 hover:bg-custom-orange hover:text-blue focus:outline-none ${
                selectedSection === "myBooks"
                  ? "bg-custom-orange text-white"
                  : ""
              }`}
              onClick={showMyBooks}
            >
              {translations.myBooks}
            </button>
            <button
              className={`flex items-center px-4 py-2 bg-blue rounded-lg text-l font-bold text-gray-900 hover:bg-custom-orange hover:text-blue focus:outline-none ${
                selectedSection === "archive"
                  ? "bg-custom-orange text-white"
                  : ""
              }`}
              onClick={showArchive}
            >
              {translations.archive}
            </button>
          </div>

          <div className="mt-4">
            {selectedSection === "myBooks" && <MyBookButton />}
            {selectedSection === "archive" && <ArchiveButton />}
          </div>
        </div>

       
      </div>
    </>
  );
}

export default MyFav;
