import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { baseurl } from "../helper/Baseurl";
import { useTranslation } from "../context/TranslationContext"; 

const Notifications = ({ currentLanguage }) => {
  const { translations , language} = useTranslation(); 
  const isArabic = language === "ar";

  const navigate = useNavigate();
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    axios
      .get(baseurl + "my-notifications", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {

        const unreadNotifications = response.data[0]
          .filter((notification) => notification.isRead === false)
          .sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate)) 
          .slice(0, 4); 
  
        setNotifications(unreadNotifications);
      })
      .catch((error) => {
        console.error("Error fetching notifications:", error);
      });
  }, []);
  

  return (
    <div
      className={`bg-white border border-gray-200 rounded-md shadow-lg  p-4 w-56 absolute  ${isArabic ? 'left-0' : 'right-8 '} mt-2 z-50`}
    >
      <h3
        className="text-lg font-bold mb-4 text-right"
        style={{ fontFamily: "Tajwal, sans-serif" }}
      >
        {translations.notifications.title}
      </h3>
      {notifications.length === 0 ? (
        <p
          className="text-sm mb-5"
          style={{ fontFamily: "Tajwal, sans-serif" }}
        >
               {translations.notifications.noNotifications}

        </p>
      ) : (
      
        notifications.map((notification) => (
         
          <>
          <div
             key={notification.id}
             className="flex-col items-start mb-4 text-right bg-slate-100 p-2 rounded-md "
           >
             <div className=" flex flex-row justify-between mb-1 " dir="rtl">
               <p
                 className="text-lg font-bold"
                 style={{ fontFamily: "Tajwal, sans-serif" }}
               >
                 {notification.title}
               </p>
            
               <img
                 src="https://img.icons8.com/ios/50/000000/appointment-reminders--v2.png"
                 alt="Notification Bell"
                 className="h-5 w-5 rounded-full ml-5 mt-1"
               />
            
              
             </div>
             <div className="flex-col items-center ">
             <p
   className="text-sm text-custom-orange line-clamp-2 overflow-hidden"
   style={{ fontFamily: "Tajwal, sans-serif", display: "-webkit-box", WebkitLineClamp: 1, WebkitBoxOrient: "vertical" }}
 >
   {notification.details}
 </p>
                 <p
                   className="text-xs text-gray-500"
                   style={{ fontFamily: "Tajwal, sans-serif" }}
                 >
                   {new Date(notification.createdDate).toLocaleTimeString()}
                 </p>
               </div>
           </div>
           <hr className="border-t border-gray-200 my-2 mx-2" />
 
         </>
        ))
      )}
      <button
        className="text-sm text-blue-500 hover:underline mt-2"
        onClick={() => navigate("/AllNotify")}
        style={{ fontFamily: "Tajwal, sans-serif" }}
      >
        {translations.notifications.viewAll}
      </button>
    </div>
  );
};

export default Notifications;
