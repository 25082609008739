import React, { useState, useEffect } from "react";
import axios from "axios";
import { baseurl } from "../helper/Baseurl";
import { FaSpinner } from 'react-icons/fa'; 
import noNotificationImage from "../assets/images/New.png"; 
import { useTranslation } from "../context/TranslationContext"; 

export default function AllNotify() {
    const { translations , language} = useTranslation(); 
    const isArabic = language === "ar";

  const [notifications, setNotifications] = useState([]);

  const [loading, setLoading] = useState(true); 

  useEffect(() => {
    axios
      .get(baseurl + "my-notifications", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        const unreadNotifications = response.data[0].filter(
          (notification) => !notification.isRead
        );
  
        const sortedNotifications = unreadNotifications.sort(
          (a, b) => new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime()
        );
        
        setNotifications(sortedNotifications);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching notifications:", error);
        setLoading(false);
      });
  }, []);
  
  

  return (
    <>
      <div className={`flex ${isArabic ? 'flex-col md:flex-row' : 'flex-col-reverse md:flex-row-reverse '} pt-16 w-full`}>
     
          <div  className="pt-8 flex-1"
          style={{
            fontFamily: "Tajwal, sans-serif",
            direction: isArabic ? "rtl" : "ltr",
            textAlign: isArabic ? "right" : "left",
          }}>
            <h3
              className="text-lg font-bold mb-4 "
              style={{ fontFamily: "Tajwal, sans-serif" }}
            >
               {translations.notifications.title}
            </h3>

            {loading ? ( 
              <div className="flex items-center justify-center h-screen">
                <FaSpinner className="text-4xl animate-spin" />
              </div>
            ) : notifications.length === 0 ? (
              <div className="flex flex-col justify-center items-center h-[70vh]">
                <img
                  src={noNotificationImage}
                  alt="No Notifications"
                  className="w-52 h-52 mb-4"
                />
                <p
                  className="text-sm mb-5"
                  style={{ fontFamily: "Tajwal, sans-serif" }}
                >
                 {translations.notifications.noNotifications}
                </p>
              </div>
            ) : (
              notifications.map((notification) => (
                <div
                  key={notification.id}
                  className={`flex items-start mb-4 text-right border rounded-sm shadow-md ml-8 ${
                    !notification.isRead ? "bg-blue" : ""
                  } p-2 rounded-md`} 
                >
              <div className="p-2 rounded-md group transition duration-200 hover:text-custom-orange relative">
              {!notification.isRead && (
                <div className="absolute top-3 right-3 transform translate-x-1/2 -translate-y-1/2 bg-blues1 text-white text-xs rounded-full w-4 h-4 flex items-center justify-center">
                  <img
                    src="https://img.icons8.com/ios/50/000000/new.png"
                    alt="New Notification"
                    className="h-3 w-3"
                  />
                </div>
              )}

              <div className="flex-shrink-0">
                <img
                  src="https://img.icons8.com/ios/50/000000/appointment-reminders--v2.png"
                  alt="Notification Bell"
                  className="h-6 w-6 rounded-full ml-5 mt-1"
                />
              </div>
            </div>

                              <div className="ml-3 flex-1" dir="rtl">
                                <p
                                  className="text-lg font-bold"
                                  style={{ fontFamily: "Tajwal, sans-serif" }}
                                >
                                  {notification.title}
                                </p>
                                <div className="flex items-center justify-between">
                                <p
              className="text-sm text-custom-orange line-clamp-2 overflow-hidden mx-2"
              style={{ fontFamily: "Tajwal, sans-serif", display: "-webkit-box", WebkitLineClamp: 1, WebkitBoxOrient: "vertical" }}
            >
                                    {notification.details}
                                  </p>
                                  <p
              className="text-xs text-gray-500 mx-2"
              style={{ fontFamily: "Tajwal, sans-serif" }}
            >
              {new Date(notification.createdDate).toLocaleTimeString([], {
                hour: '2-digit',
                minute: '2-digit'
              })}
            </p>
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        
      </div>
    </>
  );
}
