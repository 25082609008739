import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom"; 
import axios from "axios";
import { FaShoppingCart } from "react-icons/fa";
import { FaRegHeart } from "react-icons/fa6";
import { baseurl } from "../helper/Baseurl";
import { ToastContainer, toast } from "react-toastify";
import user from "../assets/images/user.png";
import { useTranslation } from "../context/TranslationContext"; 

import "react-toastify/dist/ReactToastify.css";

const BookDetails = () => {
    const { translations , language} = useTranslation(); 
    const isArabic = language === "ar";

  const { bookId } = useParams();
  const [bookData, setBookData] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [books, setBooks] = useState([]);
  const [likedBooks, setLikedBooks] = useState({});
  const navigate = useNavigate();

  const showpicbooks = (fileName) => {
    try {
      const imageUrl = `${baseurl}uploads/file/download/${fileName}`;
      return imageUrl;
    } catch (error) {
      console.error("Error fetching image:", error);
      return null;
    }
  };

  const handleAddToCart = async () => {
    try {
      const response = await axios.post(
        baseurl + "add-to-cart",
        {
          type: "BOOK",
          id: bookId,
          quantity: quantity,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status === 201) {
        window.dispatchEvent(new Event("cartUpdated"));
        toast.success(translations.add_to_cart_success);
      }
    } catch (error) {
      console.error("Error adding to cart:", error);
      toast.warning(translations.add_to_cart_error);
    }
  };

  const handleLikeClick = async (id) => {
    try {
      const response = await axios.post(
        `${baseurl}toggle-favorite`,
        {
          type: "BOOK",
          id: id,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.isFavorite !== undefined) {
        setLikedBooks((prev) => ({
          ...prev,
          [id]: response.data.isFavorite,
        }));
      }
    } catch (error) {
      console.error("Error toggling favorite:", error);
    }
  };


  useEffect(() => {
    const fetchBookData = async () => {
      try {
        const response = await axios.get(`${baseurl}book/${bookId}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        });


        const imageUrl = await showpicbooks(response.data.coverImageUrl);

        setBookData({ ...response.data, coverImageUrl: imageUrl });
      } catch (error) {
        console.error("Error fetching book data:", error);
      }
    };

    const fetchBooks = async () => {
      try {
        const response = await axios.get(baseurl + "all-books", {
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        const booksData = response.data.map((book) => {
          const imageUrl = showpicbooks(book.coverImageUrl);
          return { ...book, coverImageUrl: imageUrl };
        });
        setBooks(booksData);
      } catch (error) {
        console.error("Error fetching books:", error);
      }
    };

    fetchBookData();
    fetchBooks();
  }, [bookId]);


  useEffect(() => {
    if (bookData) {
      setLikedBooks((prev) => ({
        ...prev,
        [bookId]: bookData.isFavorite,
      }));
    }
  }, [bookData, bookId]);

  if (!bookData) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="w-10 h-10 border-4 border-gray-200 border-t-blue-500 rounded-full animate-spin"></div>
      </div>
    );
  }


const relatedBooks = books
  .filter((book) => book.id.toString() !== bookId)
  .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

  const openBookDetails = (bookId) => {
    navigate(`/BookDetails/${bookId}`);
  };
  return (
    <>
      <div className={`flex flex-col pt-16 w-full ${isArabic ? "rtl" : "ltr"}`}>
        <div
          className={`grid grid-cols-1 md:grid-cols-4 gap-6 p-4 mt-14`}
          dir={isArabic ? "rtl" : "ltr"}
        >
          {/* القسم الأيسر */}
          <div className="col-span-2 flex flex-col items-center bg-slate-100 rounded-md shadow-xl p-4">
            <div className="flex flex-col items-center w-full">
            <div className="flex items-center w-full flex-col sm:flex-col md:flex-col lg:flex-row">

                <img
                  src={bookData.coverImageUrl}
                  alt="Book"
                  className={` rounded-lg mb-4 w-full md:w-auto  md:h-72`}
                />

                <div className={`px-4 w-full ${isArabic ? "text-right" : "text-left"}`}>
                  <h1 className="text-2xl font-bold font-tajwal mb-4">
                    {bookData.title}
                  </h1>
                  <p className="font-tajwal text-gray-700">{bookData.description}</p>
                  <div className="flex justify-between items-center mt-4 w-full">
                <div className="flex items-center space-x-2 rtl:space-x-reverse">
                  <div className="bg-white border border-gray-300 text-custom-orange rounded p-2 flex items-center justify-center">
                    <span className="text-yellow-500 text-sm">⭐</span>
                    <span className="ml-1 text-xs sm:text-sm">
                    {parseFloat(bookData.overallRating).toFixed(1)}
                    </span>
                  </div>
                  <button
                    onClick={handleAddToCart}
                    className="bg-white border border-gray-300 text-custom-orange rounded p-2 flex items-center justify-center"
                  >
                    <FaShoppingCart size={19} />
                  </button>
                  <button
                    onClick={() => handleLikeClick(bookId)}
                    className={`${
                      likedBooks[bookId] ? "text-red-500" : "text-custom-orange"
                    } bg-white border border-gray-300 rounded p-2 flex items-center justify-center`}
                  >
                    <FaRegHeart size={19} />
                  </button>
                </div>
                <div
                  className={`flex flex-col ${
                    isArabic ? "text-right" : "text-left"
                  }`}
                >
                  <span className="text-sm text-red_aa font-bold font-tajwal">
                    {bookData.studentsPrice} {translations.price}
                  </span>
                  <span className="text-sm text-gray-500 font-tajwal line-through">
                    {bookData.price} {translations.price}
                  </span>
                  <span className="ml-2 bg-red_aa text-white px-2 py-1 text-xs rounded font-tajwal">
                    {translations.discount}
                    {Math.round(
                      ((bookData.price - bookData.studentsPrice) / bookData.price) *
                        100
                    )}
                    %
                  </span>
                </div>
              </div>
                </div>
              </div>
              
            </div>
          </div>
  
          {/* القسم الأيمن */}
          <div className="col-span-2">
            <h2
              className={`text-xl font-bold mb-4 font-tajwal text-custom-orange ${
                isArabic ? "text-right" : "text-left"
              }`}
            >
              {translations.product_details}
            </h2>
            <table className="w-full text-right mb-4">
              <tbody>
                {[
                  [translations.book_title, bookData.title],
                  [translations.author, bookData.author],
                  ["ISBN", bookData.isbn],
                  [translations.publisher_name, bookData.publisher],
                  [translations.publish_date, bookData.publicationDate],
                ].map(([label, value], index) => (
                  <tr
                    key={index}
                    className={`border-t ${
                      index === 4 ? "border-b" : ""
                    } bg-white`}
                  >
                    <td className="p-4 font-tajwal font-bold">{label}</td>
                    <td className="p-4 font-tajwal">{value}</td>
                  </tr>
                ))}
              </tbody>
            </table>
  
        
          </div>
        </div>
        
        <div className="p-4 bg-white"     dir={isArabic ? "rtl" : "ltr"}>
              <div className="flex items-center justify-between mb-4">
                <span className="text-gray-700 font-semibold font-tajwal">
                  {translations.Buttons.reviews} ({bookData.comments.length})
                </span>
                <div className="flex items-center">
                  <span className="text-yellow-500">⭐</span>
                  <span className="ml-1 text-gray-700">
                    {bookData.comments.length > 0
                      ? (
                          bookData.comments.reduce(
                            (acc, comment) => acc + comment.rating,
                            0
                          ) / bookData.comments.length
                        ).toFixed(1)
                      : "0"}
                  </span>
                </div>
              </div>
  
              <div className="max-h-90 overflow-y-auto">
                {bookData.comments.length > 0 ? (
                  bookData.comments.map((comment) => (
                    <div
                      key={comment.id}
                      className="flex items-start mb-4 p-4 border-b"
                    >
                      <img
                        src={user}
                        alt="User"
                        className={`w-12 h-12 rounded-full ${
                          isArabic ? "ml-4" : "mr-4"
                        }`}
                      />
                      <div>
                        <div
                          className={`flex items-center mb-2 ${
                            isArabic ? "" : "flex-row-reverse"
                          }`}
                        >
                          <span className={`font-tajwal `}>
                            {comment.learner.firstName} {comment.learner.lastName}
                          </span>
                          {/* <div
                            className={`flex items-center ${
                              isArabic ? "mr-2" : "ml-2"
                            }`}
                          >
                            <span className="text-yellow-500">⭐</span>
                            <span className="ml-1">{comment.rating}</span>
                          </div> */}
                        </div>
                        <p
                          className={`text-gray-700 font-tajwal text-${
                            isArabic ? "right" : "left"
                          }`}
                        >
                          {comment.details}
                        </p>
                      </div>
                    </div>
                  ))
                ) : (
                  <p className="text-gray-700 font-tajwal">{translations.Reviews.noReviews}</p>
                )}
              </div>
         </div>
      </div>
  
      <ToastContainer />
    </>
  );
  
  
};

export default BookDetails;













{/* <div className="px-6 rounded bg-white shadow-lg py-4 my-10 ">
  <div className="mb-4">
    <div className="flex text-custom-orange">
      <div className="flex flex-col items-center flex-grow">
        <span className="font-bold font-tajwal text-sm sm:text-base">{translations.instructorbook}</span>
        <span className="mt-1 text-xs sm:text-sm">{bookData.author}</span>
      </div>
      <span className="border-l border-gray-300 mx-2 "></span>
      <div className="flex flex-col items-center flex-grow">
        <span className="font-bold font-tajwal text-sm sm:text-base">{translations.publisher}</span>
        <span className="mt-1 text-xs sm:text-sm">{bookData.publisher}</span>
      </div>
      <span className="border-l border-gray-300 mx-2 "></span>
      <div className="flex flex-col items-center flex-grow">
        <span className="font-bold font-tajwal text-sm sm:text-base">{translations.publish_date}</span>
        <span className="mt-1 text-xs sm:text-sm">{bookData.publish_date}</span>
      </div>
      <span className="border-l border-gray-300 mx-2 "></span>
      <div className="flex flex-col items-center flex-grow">
        <span className="font-tajwal text-sm sm:text-base">تقييم</span>
        <div className="flex items-center mt-1">
          <span className="text-yellow-500">⭐</span>
          <span className="mr-1 text-xs sm:text-sm">{bookData.overallRating}</span>
        </div>
      </div>
    </div>
  </div>
</div> */}