import React, { useRef, useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from 'react-router-dom'; 
import { baseurl } from '../helper/Baseurl';
import noCoursesImage from "../assets/images/Search.png"; 
import { Tab, Tabs } from "@mui/material";
import { MdArrowForwardIos, MdArrowBackIosNew } from "react-icons/md";
import ContinueWatchingSection from "../component/ContinueWatchingSection";
import { useTranslation } from "../context/TranslationContext"; 

const ContinueReadingSection = () => {
  const [value, setValue] = useState(0); 
  const [mybooks, setMyBooks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1); 
  const [totalPages, setTotalPages] = useState(1); 
  const navigate = useNavigate();
  const scrollContainerRef = useRef(null);

  const booksPerPage = 4; 

  const openBook = (bookId) => {
    navigate(`/ReadBooks/${bookId}`);
  };

  const showMyBooks = async () => {
    try {
      const response = await axios.get(
        baseurl + "my-books",
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      if (response.data) {
        const booksData = response.data.filter(book => book !== null);
        return booksData;
      }
    } catch (error) {
      console.error('Error fetching my books:', error);
      return [];
    }
  };

  const showpicbooks = async (fileName) => {
    try {
      const imageUrl = `${baseurl}uploads/file/download/${fileName}`;
      return imageUrl;
    } catch (error) {
      console.error('Error fetching image:', error);
      return null;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const booksData = await showMyBooks();
      if (booksData.length === 0) {
        setMyBooks([]);
        setLoading(false);
        return;
      }

      const updatedBooks = await Promise.all(booksData.map(async (book) => {
        const imageUrl = await showpicbooks(book.coverImageUrl);
        return { ...book, coverImageUrl: imageUrl };
      }));

      const filteredBooks = updatedBooks.filter(book => book.progressPercentage < 100);

      setTotalPages(Math.ceil(filteredBooks.length / booksPerPage));

      const startIndex = (currentPage - 1) * booksPerPage;
      const currentPageBooks = filteredBooks.slice(startIndex, startIndex + booksPerPage);

      setMyBooks(currentPageBooks);
      setLoading(false);
    };
    fetchData();
  }, [currentPage]);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const language = sessionStorage.getItem("language") || "ar"; 
  const isRTL = language === "ar";

    const { translations } = useTranslation(); 
    
  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="w-10 h-10 border-4 border-gray-200 border-t-blue-500 rounded-full animate-spin"></div>
      </div>
    );
  }

  return (
    <div className="p-4 mt-12" style={{ direction: isRTL ? "rtl" : "ltr" }}>

      {value === 0 && (
        <>
          <div className="flex justify-between items-center mb-4">
            <h2
              className="text-xl font-bold"
              style={{
                fontFamily: "Tajwal, sans-serif",
                textAlign: isRTL ? "right" : "left",
              }}
            >
              {language === "ar" ? "الاستمرار في قراءة كتبك و مشاهده دوراتك" : "Continue Reading"}
            </h2>
          </div>

          <h4
            className="text-l font-bold text-gray-400 mb-4"
            style={{
              fontFamily: "Tajwal, sans-serif",
              textAlign: isRTL ? "right" : "left",
            }}
          >
            {language === "ar" ? "استمر في قراءة كتبك و مشاهده دوراتك الذي بدأت بها بالفعل.." : "Continue reading the books you've already started"}
          </h4>

          <Tabs
            value={value}
            onChange={handleTabChange}
            textColor="primary"
            indicatorColor="primary"
            aria-label="tabs example"
            className="mb-4"
          >
            <Tab
              label={translations.libraryTitle}
              style={{
                fontFamily: "Tajwal, sans-serif",
                fontSize: "18px", 
                fontWeight: "bold", 
              }}
            />
            <Tab
              label={translations.MyCourses}
              style={{
                fontFamily: "Tajwal, sans-serif",
                fontSize: "18px", 
                fontWeight: "bold", 
              }}
            />
          </Tabs>

          <div
            className="flex  space-x-4 flex-wrap  "
            ref={scrollContainerRef}
            style={{ scrollBehavior: "smooth", overflowX: "hidden" }}
          >
            {mybooks.length === 0 ? (
              <div className="flex flex-col items-center justify-center w-full text-center p-4 mt-0">
                <img
                  src={noCoursesImage}
                  alt="No books available"
                  className="w-48 h-48 object-cover"
                />
                <p
                  className="text-lg text-gray-700"
                  style={{
                    fontFamily: "Tajwal, sans-serif",
                  }}
                >
                  {language === "ar" ? "لا يوجد كتب قمت بشراءها .. قم بالاشراء الان" : "No books purchased yet.. Buy now"}
                </p>
              </div>
            ) : (
              mybooks.map((book, index) => (
                <div
                  key={index}
                  className="bg-white shadow-lg rounded-lg p-2 w-72 flex-shrink-0 flex items-center mb-4 ml-5"
                  style={{ direction: isRTL ? "rtl" : "ltr" }}
                  onClick={() => openBook(book.id)}
                >
                  <img
                    src={book.coverImageUrl}
                    alt={book.title}
                    className="object-cover rounded w-20 h-30 ml-2 mr-4"
                    style={{ fontFamily: "Tajwal, sans-serif" }}
                  />
                  <div className="flex-1">
                    <h3
                      className="text-md font-bold mb-2"
                      style={{ fontFamily: "Tajwal, sans-serif" }}
                    >
                      {book.title}
                    </h3>
                    <p
                      className="text-gray-600 text-xs mb-2"
                      style={{
                        fontFamily: "Tajwal, sans-serif",
                        textAlign: language === "ar" ? "right" : "left",
                        lineHeight: "1.5",
                        marginBottom: "8px",
                        wordWrap: "break-word",
                        whiteSpace: "normal",
                        overflow: "hidden",
                        display: "-webkit-box",
                        WebkitBoxOrient: "vertical",
                        WebkitLineClamp: 1,
                      }}
                    >
                      {book.description}
                    </p>
                    <div className="mt-0 relative">
                      <div
                        className="absolute left-0 text-xs text-gray-700"
                        style={{ fontFamily: "Tajwal, sans-serif" }}
                      >
                        {book.progressStep || 0}%
                      </div>
                      <div
                        className="absolute right-0 text-xs text-gray-700"
                        style={{ fontFamily: "Tajwal, sans-serif" }}
                      >
                        {language === "ar" ? "تقدم القراءة" : "Reading Progress"}
                      </div>
                    </div>
                    <div className="w-full bg-gray-200 rounded-full h-2">
                      <div
                        className="bg-custom-orange h-2 rounded-full mt-6"
                        style={{ width: `${book.progressStep || 0}%` }}
                      ></div>
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>

          {/* Pagination controls */}
          <div className="flex justify-between mt-4">
            <button
              className="bg-gray-200 text-custom-orange px-4 py-4 rounded-full"
              onClick={() => setCurrentPage(currentPage - 1)}
              disabled={currentPage === 1}
            >
                      

             {isRTL ?          <MdArrowForwardIos /> :    <MdArrowBackIosNew />}
           
            </button>
            <span className="text-lg" style={{ fontFamily: "Tajwal, sans-serif" }}>
              {language === "ar" ? `الصفحة ${currentPage} من ${totalPages}` : `Page ${currentPage} of ${totalPages}`}
            </span>
            <button
              className="bg-gray-200 text-custom-orange px-4 py-4 rounded-full"
              onClick={() => setCurrentPage(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
                 {isRTL ?         <MdArrowBackIosNew />    :    <MdArrowForwardIos />}
               
            </button>
          </div>
        </>
      )}

      {value === 1 && (
      <div>
              <div className="flex justify-between items-center mb-4">
        <h2
          className="text-xl font-bold"
          style={{
            fontFamily: "Tajwal, sans-serif",
            textAlign: isRTL ? "right" : "left",
          }}
        >
          {language === "ar" ? "الاستمرار في قراءة كتبك و مشاهده دوراتك" : "Continue Reading"}
        </h2>
      </div>

      <h4
        className="text-l font-bold text-gray-400 mb-4"
        style={{
          fontFamily: "Tajwal, sans-serif",
          textAlign: isRTL ? "right" : "left",
        }}
      >
        {language === "ar" ? "استمر في قراءة كتبك و مشاهده دوراتك الذي بدأت بها بالفعل.." : "Continue reading the books you've already started"}
      </h4>
   <Tabs
          value={value}
          onChange={handleTabChange}
          textColor="primary"
          indicatorColor="primary"
          aria-label="tabs example"
          className="mb-4"
        >
          <Tab
              label={translations.libraryTitle}
              style={{
                fontFamily: "Tajwal, sans-serif",
                fontSize: "18px", 
                fontWeight: "bold", 
              }}
            />
            <Tab
              label={translations.MyCourses}
              style={{
                fontFamily: "Tajwal, sans-serif",
                fontSize: "18px", 
                fontWeight: "bold", 
              }}
            />
        </Tabs>
  
        <ContinueWatchingSection/> 
      </div>
      )}
    </div>
  );
};

export default ContinueReadingSection;
