import React, { useState, useEffect } from "react";
import { FaCheck } from "react-icons/fa";
import { IoIosArrowForward } from "react-icons/io";
import user from "../assets/images/user.png";
import noCoursesImage from "../assets/images/Search.png";
import ShareModels from "../models/ShareModels"; 
import { baseurl } from "../helper/Baseurl";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useMediaQuery } from "react-responsive"; 
import { useTranslation } from "../context/TranslationContext"; 
import { IoIosArrowBack } from "react-icons/io";
import ber from '../assets/images/ber.png'
const Showcourse = () => {

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [course, setCourse] = useState(null);
  const { courseId } = useParams();
  const [activeButton, setActiveButton] = useState("نظرة عامة");
  const [loading, setLoading] = useState(true);
  const [sortOption, setSortOption] = useState("الأحدث");
  const [note, setNote] = useState("");
  const [stars, setStars] = useState(3); 
  const [error, setError] = useState("");
  const [hoveredStar, setHoveredStar] = useState(0);
  const { translations , language} = useTranslation(); 
  const isArabic = language === "ar";
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: 1024 });
  const [activeVideoIndex, setActiveVideoIndex] = useState(null);
  const [videoUrl, setVideoUrl] = useState("");
  const [selectedVideoIndex, setSelectedVideoIndex] = useState(null); 

  const fetchCourse = async () => {
    try {
      const response = await axios.get(`${baseurl}course/${courseId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      setCourse(response.data);
    } catch (error) {
      console.error("Error fetching course data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCourse();
    
  }, [courseId]);



  
  const handleVideoClick = (index) => {
    setSelectedVideoIndex(index);
    setActiveVideoIndex(index);
    setVideoUrl(`${baseurl}uploads/file/download/${course.videos[index].fileUrl}`);

  };
  


  if (!course) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="w-10 h-10 border-4 border-gray-200 border-t-blue-500 rounded-full animate-spin"></div>
      </div>
    );
  }

  const backpage = () => {
    navigate("/HomeAfterLogin");
  };      

  const handleSave = async () => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        setError("User not authenticated");
        return;
      }

      const response = await axios.post(
        baseurl + "add-comment",
        {
          entityType: "COURSE",
          id: courseId,
          comment: note,
          stars: stars,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      setNote("");
      setStars(3);

      toast.success("تم تسجيل تقييمك بنجاح ");
    } catch (error) {
      toast.error("حدث مشكلة اثناء تسجيل تقيمك ");
      console.error(error);
    }
  };

 const sortedComments = course?.comments ? [...course.comments] : [];

 if (sortedComments.length > 0) {
   if (sortOption === "الأحدث") {
     sortedComments.sort(
       (a, b) => new Date(b.createdDate) - new Date(a.createdDate)
     );
   } else if (sortOption === "الأعلى تقييمًا") {
     sortedComments.sort((a, b) => b.rating - a.rating);
   }
 } else {
   console.log("No comments available for sorting.");
 }
  const handleTogglePopup = () => {
    setIsModalOpen(!isModalOpen);
  };


  const handleMarkAsRead = async () => {
    try {
      const response = await axios.post(
        baseurl + "update-progress",
        {
          id: Number(courseId), 
          type: "COURSE",
          progressStep: Number(100), 
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.status === 201) {
        toast.success("تم وضع علامة على أنها تمت قراءتها!");
      }
    } catch (error) {
      console.error("تفاصيل الخطأ:", error.response.data);
      toast.warning('حدث خطأ أثناء وضع علامة على الدورة كـ "تمت قراءته".');
    }
  };
  const formatDuration = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes} دقيقة${secs > 0 ? ` و ${secs} ثانية` : ""}`;
  };
  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName);
    if (buttonName === "مراجعات") {
      fetchCourse(); 
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="w-10 h-10 border-4 border-gray-200 border-t-blue-500 rounded-full animate-spin"></div>
      </div>
    );
  }
  if (!course) {
    return (
      <div className="flex flex-col items-center justify-center h-screen text-center p-4 mt-[-10%]">
        <img
          src={noCoursesImage}
          alt="No courses available"
          className="w-60 h-60 object-cover mb-10"
        />
        <p
          className="text-lg text-gray-700 mt-0"
          style={{ fontFamily: "Tajwal, sans-serif" }}
        >
          لا يوجد بيانات لعرضها الان...
        </p>
      </div>
    );
  }
  return (
    <div className={`bg-white font-tajwal  ${isArabic ? "rtl" : "ltr"}`} dir={isArabic ? "rtl" : "ltr"}>
      {/* Navbar */}
      <nav className="bg-white shadow-md p-4 flex justify-between items-center fixed top-0 left-0 right-0 z-10">
        <div className={`flex items-center  ${isArabic ? "ml-auto" : "mr-auto"}`}>
          <button
            className="text-gray-700 hover:text-gray-900"
            onClick={backpage}
          >
            {isArabic ?  <IoIosArrowForward className="w-6 h-6" />  : <IoIosArrowBack className="w-6 h-6" /> }
          </button>
          <span className="mr-2 text-gray-700" onClick={backpage}>
          </span>
        </div>

        {/* Left side: Progress Bar */}
        <div className="flex items-center">
          <span className="text-gray-700 ml-2">{translations.Navbar.readingProgress}  </span>
          <div className="w-48 bg-gray-200 rounded-full h-2 relative">
            <div
              className="bg-custom-orange h-full rounded-full"
              style={{ width: `${course.progressStep ||0}%` }}
            ></div>
          </div>
          <span className="ml-2 text-gray-700">
            {course.progressStep ||0}%
          </span>
        </div>
      </nav>

    {/* Main Content */}
      <main className="p-4 pt-20">
        <div className="flex flex-grow flex-col lg:flex-row">
          {/* Video Player */}
          <div dir={isArabic ? "rtl" : "ltr"} className="w-full lg:w-3/4 p-4">

          <div className="relative">
          {videoUrl ? (
            <video key={videoUrl} controls autoPlay className="w-full rounded-lg">
              <source src={videoUrl} type="video/mp4" />
              {translations.error}
            </video>
          ) : (

            
            <div className="w-full h-96 bg-gray-200 flex items-center justify-center text-gray-600">
            {translations.error}
          </div>
          )}
        </div>


            {/* Action Buttons */}
            <div className="mt-4 flex flex-col md:flex-row justify-between items-center">
            <div className="flex flex-col md:flex-row space-y-4 md:space-x-4 md:space-y-0">


              {/* List of buttons for larger screens */}
              <div className="hidden md:flex ">
                <button
                  className={`mx-1 px-4 py-2 rounded-3xl text-lg text-gray-900 focus:outline-none ${
                    activeButton === "نظرة عامة"
                      ? "bg-custom-orange text-white"
                      : "bg-blue hover:bg-custom-orange hover:text-white"
                  }`}
                  onClick={() => setActiveButton("نظرة عامة")}
                >
                {translations.Buttons.overview} 
                </button>
                <button
                  className={`mx-1 px-4 py-2 rounded-3xl text-lg text-gray-900 focus:outline-none ${
                    activeButton === "مراجعات"
                      ? "bg-custom-orange text-white"
                      : "bg-blue hover:bg-custom-orange hover:text-white"
                  }`}
                  onClick={() => handleButtonClick("مراجعات")}
                >
              {translations.Buttons.reviews} 
                </button>
                <button
                  className={`mx-1 px-4 py-2 rounded-3xl text-lg text-gray-900 focus:outline-none ${
                    activeButton === "ملاحظات"
                      ? "bg-custom-orange text-white"
                      : "bg-blue hover:bg-custom-orange hover:text-white"
                  }`}
                  onClick={() => setActiveButton("ملاحظات")}
                >
                  {translations.Buttons.notes}
                </button>
              </div>

              {/* Dropdown menu for mobile */}
              <div className="md:hidden">
                <select
                  className="w-full px-4 py-2 bg-white border rounded-3xl text-lg"
                  value={activeButton}
                  onChange={(e) => setActiveButton(e.target.value)}
                >
                  <option value="نظرة عامة">{translations.Buttons.overview}       </option>
                  <option value="مراجعات">   {translations.Buttons.reviews} </option>
                  <option value="ملاحظات">      {translations.Buttons.notes}      </option>
                </select>
              </div>


              <div className="flex items-center space-x-4 ">
                {/* Placeholder for share button */}
                <button
                  onClick={handleMarkAsRead}
                  className="bg-white text-gray-400 px-4 py-2 rounded flex items-center"
                >
                  <FaCheck className="w-5 h-5 ml-2 text-gray-400" />
                  {translations.Buttons.markAsRead}
                </button>
            </div>
            </div>


          
            </div>

            {/* Overview Box */}
            {activeButton === "نظرة عامة" && (
             <div dir={isArabic === "ar" ? "rtl" : "ltr"} className="mt-4 p-4 bg-white rounded-lg shadow-xl">
             <h4 className={`text-2xl font-semibold mb-2 ${isArabic === "ar" ? "text-right" : "text-left"}`}>
               {course.title}
             </h4>
             <p className={`text-gray-700 mb-4 ${isArabic === "ar" ? "text-right" : "text-left"}`}>
               {course.description}
             </p>
             <hr className="border-gray-200 mb-4" />
             <div className="flex flex-wrap gap-2 mb-4">
               {course.keywords.split(",").map((keyword, index) => (
                 <div
                   key={index}
                   className="px-3 py-1 font-semibold bg-blues text-gray-700 rounded-lg text-sm"
                   style={{ textAlign: isArabic === "ar" ? "right" : "left" }}
                 >
                   {keyword.trim()}
                 </div>
               ))}
             </div>
           </div>
           
            )}

            {activeButton === "مراجعات" && (
              <div className="mt-4 p-4 bg-white rounded-lg shadow-xl">
                <div>
                  {/* Header for Reviews */}
                  <div className="flex items-center justify-between mb-4">
                    <div className="flex items-center">
                      <span className="text-gray-700 font-semibold">
                      {translations.Buttons.reviews} ({course.comments.length})
                      </span>
                      <div className="flex items-center ml-4">
                        <span className="text-yellow-500">⭐</span>
                        <span className="ml-1 text-gray-700">
                          {course.comments.length > 0
                            ? (
                                course.comments.reduce((acc, comment) => acc + comment.rating, 0) /
                                course.comments.length
                              ).toFixed(1)
                            : 0}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="max-h-60 overflow-y-auto">
                    {loading ? (
                      <div className="flex justify-center items-center ">
                        <div className="w-10 h-10 border-4 border-gray-200 border-t-blue-500 rounded-full animate-spin"></div>
                      </div>
                    ) : sortedComments.length === 0 ? (
                      <p className="text-gray-700">  {translations.Reviews.noReviews}</p>
                    ) : (
                      sortedComments.map((comment) => (
                        <div key={comment.id} className="flex items-start mb-4 p-4 border-b">
                          <img
                            src={user}
                            alt="User"
                            className="w-12 h-12 rounded-full mr-4"
                          />
                          <div className="flex-1">
                            <div className="flex items-center mb-2">
                              <span className="font-semibold mr-2">
                                {comment.learner.firstName} {comment.learner.lastName}
                              </span>
                              <div className="flex items-center">
                                <span className="text-yellow-500">⭐</span>
                                <span className="ml-1 text-gray-700">
                                  {comment.rating}
                                </span>
                              </div>
                            </div>
                            <p className="text-gray-700 text-right">{comment.details}</p>
                          </div>
                        </div>
                      ))
                    )}
                  </div>
                </div>
              </div>
            )}

            {activeButton === "ملاحظات" && (
              <>
                <div className="flex items-center mb-4 mt-5 ">
                  <h3 className="text-lg font-semibold mr-4"> {translations.Notes.title} </h3>
                  <div className="flex items-center">
                    {[1, 2, 3, 4, 5].map((star) => (
                      <svg
                        key={star}
                        onClick={() => setStars(star)}
                        onMouseEnter={() => setHoveredStar(star)}
                        onMouseLeave={() => setHoveredStar(0)}
                        xmlns="http://www.w3.org/2000/svg"
                        className={`w-6 h-6 cursor-pointer ${
                          star <= (hoveredStar || stars) ? "text-yellow-500" : "text-gray-300"
                        }`}
                        fill="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fillRule="evenodd"
                          d="M12 17.27l5.18 3.09-1.36-5.91L21 9.24l-6.06-.52L12 3 9.06 8.72 3 9.24l4.18 4.24-1.36 5.91L12 17.27z"
                          clipRule="evenodd"
                        />
                      </svg>
                    ))}
                  </div>
                </div>
                <textarea
                  value={note}
                  onChange={(e) => setNote(e.target.value)}
                  rows="4"
                  className="w-full p-2 border rounded-md mb-4 border-custom-orange bg-gray-100"
                  placeholder=   {translations.Notes.placeholder}
                />
                <div className="flex justify-end">
                  <button
                    onClick={handleSave}
                    className="px-4 py-2 bg-custom-orange text-white rounded-md"
                  >
                {translations.Notes.save}
                  </button>
                </div>
                {error && <p className="text-red-500 mt-2">{error}</p>}
              </>
            )}
          </div>

 
         {/* Sidebar */}
        <div
          className={`${isMobile ? "w-full mt-4" : "w-1/4 "}  border rounded-2xl p-4 max-h-screen overflow-y-auto shadow-lg`}
          dir={isArabic ? "rtl" : "ltr"}
        >
          <h2 className="text-lg font-bold mb-4">{translations.course_content}</h2>
          {course.videos.length === 0 ? (
            <div className="flex flex-col items-center justify-center text-center">
              <img
                src={ber} 
                alt="No videos"
                className="w-80 h-80 object-cover mb-4 mt-32"
              />
              <p className="text-gray-500">{translations.PDF.noVideos}</p>
            </div>
          ) : (
            <ul>
              {course.videos.map((video, index) => {
                const videoUrl = `${baseurl}uploads/file/download/${video.fileUrl}`;
                const isActive = activeVideoIndex === index;

                return (
                  <li
                    key={video.id}
                    onClick={() => handleVideoClick(index)}
                    className={`mb-4 p-4 border rounded-md flex items-center flex-row-reverse ${isActive ? "bg-gray-100" : ""}`}
                  >
                    <video
                      src={videoUrl}
                      className="w-24 h-16 border rounded-md ml-4"
                      muted
                    />
                    <div className="flex flex-col flex-1">
                      <span className="font-semibold text-right">
                        {index + 1}. {video.title}
                      </span>
                      <span className="text-gray-700 text-sm text-right mb-2">
                        {video.details}
                      </span>
                      <span className="text-gray-500 text-xs text-right">
                        {formatDuration(video.durationInSeconds)}
                      </span>
                    </div>
                  </li>
                );
              })}
            </ul>
          )}
        </div>

        </div>
      </main>

      <ToastContainer position="bottom-left" />
      {isModalOpen && <ShareModels handleTogglePopup={handleTogglePopup} />}
    </div>
  );
};

export default Showcourse;
