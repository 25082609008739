

const LoginRegisterlang = {
    en: {
      platformWelcome: '👋 Welcome to the educational platform',
      continueWatching1: 'Continue watching the courses you have already started',
      lastName: 'Last Name',
      firstName: 'First Name',
      accountType: 'Account Type',
      birthYear: 'Date of Birth',
      mobileNo: 'Phone Number',
      studentId: 'Student ID',
      password: 'Password',
      email: 'Email Address',
      otp: 'OTP (One Time Password)',
      verify: 'Verify',
      resend: 'Resend',
      register: 'Register',
      loginPrompt: 'Already have an account? Log in now',
      loginPrompt1: 'Do you have an account?',
      loginnow: 'Log in now',
      studentIdplaceholder: 'Please enter your student ID',
      
      internalStudent: 'Student',
      externalStudent: 'Public',
      public: 'Public',
      instructor: 'Instructor',
      placeholderEmail: 'email@example.com',
      placeholderPhone: 'Please enter your phone number',
      placeholderPassword: '********',
      otpLabel: 'OTP',
      placeholderotpLabel: 'Enter the OTP',
      resendButton: 'Resend',
      submitButton: 'Submit',
      verifyButton: 'Verify',
      emailError1: 'Please enter an email address',
      emailError: 'Please enter a valid email address',
      firstNameError: 'Please enter your first name',
      firstNamevalid: 'The first name must contain letters only',
      lastNamevalid: 'The last name must contain letters only',
      lastNameError: 'Please enter your last name',
      birthYearError: 'Please enter your date of birth',
      learnerTypeError: 'Please select account type',
      passwordError: 'Please enter your password',
      passwordLengthError: 'Password must be at least 6 characters long',
      mobileNoError: 'Please enter a valid phone number',
      otpError: 'Please enter a valid OTP (6 digits)',
      otpenter: 'Enter the OTP',
      Forgotpass: 'Forgot your password?'
      
    },
    ar: {
      platformWelcome: '👋 مرحبًا بك في المنصة التعليمية  ',
      continueWatching1: 'استمر في مشاهدة الدورات التدريبية التي بدأت مشاهدتها بالفعل',
      lastName: 'اللقب',
      firstName: 'الاسم',
      accountType: 'نوع الحساب',
      birthYear: 'تاريخ الميلاد',
      mobileNo: 'رقم الهاتف',
      studentId: 'رقم القيد',
      password: 'كلمة المرور',
      email: 'عنوان البريد الإلكتروني',
      otp: 'رمز التحقق',
      verify: 'تحقق',
      resend: 'إعادة إرسال',
      register: 'تسجيل',
      loginPrompt: 'لديك حساب؟ قم بتسجيل الدخول الآن',
      loginPrompt1: 'هل لديك حساب؟',
      loginnow: 'قم بتسجيل الآن',
      studentIdplaceholder: 'الرجاء ادخال رقم القيد',

      internalStudent: 'طالب ',
      externalStudent: 'عام ',
      public: 'عام',
      instructor: 'مدرب',
      placeholderEmail: 'email@example.com',
      placeholderPhone: 'الرجاء إدخال رقم هاتف  ',
      placeholderPassword: '********',
      otpLabel: 'رمز التحقق',
      placeholderotpLabel: 'قم بادخال رمز التحقق',
      resendButton: 'إعادة إرسال',
      submitButton: 'تسجيل',
      verifyButton: 'تحقق',
      emailError1: 'الرجاء إدخال بريد إلكتروني ',
      emailError: 'الرجاء إدخال بريد إلكتروني صالح',
      firstNameError: 'الرجاء إدخال الاسم',
      firstNamevalid: 'الاسم يجب ان يتكون من حروف فقط',
      lastNamevalid: 'الاسم يجب ان يتكون من حروف فقط',
      lastNameError: 'الرجاء إدخال اللقب',
      birthYearError: 'الرجاء إدخال تاريخ الميلاد',
      learnerTypeError: 'الرجاء اختيار نوع الحساب',
      passwordError: 'الرجاء إدخال كلمة المرور',
      passwordLengthError: 'يجب أن تكون كلمة المرور على الأقل 6 أحرف',
      mobileNoError: 'الرجاء إدخال رقم هاتف صالح',
      otpError: 'الرجاء إدخال رمز التحقق صالح (6 أرقام)',
      otpenter: 'ادخل رمز التحقق',
      Forgotpass:'هل نسيت كلمة المرور ؟'
    },
  };
  export default LoginRegisterlang